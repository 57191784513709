//copy this to create a new all world template.  database eventually.

var centralProgramsMixin  = {
    methods: {
      selectProgramsInfo: function (regionName) {
        var worldsArrays={
            CAW:CAW_Programs_Info,
            WAW:WAW_Programs_Info,
            NAW:NAW_Programs_Info,
            NCTEJW:NCTEJW_Programs_Info,

          }
          return worldsArrays[regionName]
      }
    }
  };
  export default centralProgramsMixin
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/



var CAW_Programs_Info={ 
  rightImageSrc:"career-discovery",

    agriculture:[
      {
        employername:'Tuskegee University ',
        employersite:"",
        multilink:true,
        employerLogo:"tuskegee.jpg",
        employerp1:"Tuskegee University is an independent and state-related institution of higher education. Its programs serve a student body that is coeducational as well as racially, ethnically, and religiously diverse. The courses currently offered at TU have over 50 degrees including 39 Bachelor's, 13 Master's, 2 Doctors of Philosophy: one in Materials Science and Engineering, and one in Integrative BioSciences, and the Doctor of Veterinary Medicine. Learn more about the different programs offered by TCU below.",
        employerp2:"",
        employerp3:"",

        

        links:[
          {title:"ANIMAL & VETERINARY SCIENCES" ,url:"https://info.tuskegee.edu/video-animal-and-veterinary-sciences"},
          {title:"Agribusiness",url:"https://info.tuskegee.edu/video-agribusiness"},
          {title:"Food & Nutritional Sciences" ,url:"https://info.tuskegee.edu/video-food-and-nutritional-sciences"},
          {title:"Environmental, Natural Resources & Plant Sciences" ,url:"https://info.tuskegee.edu/video-environmental-natural-resource-and-plant-sciences"},
        ],
        images:[],
        videos:[]
        }, 
      {
        employername:'Forestry Works ',
        multilink:false,
        employersite:"https://www.forestryworks.com/careers",
        employerLogo:"forestry-logo.jpg",
        employerp1:"Forestry Works mission is to educate students, parents, and teachers about careers and job opportunities in the forest industry, and provide interested students with resources to help them along their career path.  It is our mission to provide the next generation with the resources, skills and guidance needed to join the forest and wood products manufacturing workforce." ,
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
                videoType:'youtube',link:"https://youtu.be/Zc6k9kD2E-k",videoId:'Zc6k9kD2E-k'
              }]
        },   
         
            {
              employername:'Alabama Nursery & Landscape Association',
              employersite:"https://www.alabamagreenindustryjobs.org",
              multilink:false,
              employerLogo:"alnla-logo.jpg",
              employerp1:"Alabama’s largest farm organization comprised of and representing agricultural and associate members in all 67 Alabama counties. The Alabama Farmers Federation strives to protect and improve the ability of farmers engaged in production agriculture to provide a reliable supply of food through responsible stewardship of Alabama’s resources.",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[{
                      videoType:'youtube',link:"https://youtu.be/MBYHvraw-Mg",videoId:'MBYHvraw-Mg'
                    }]
            }  
  ],
    healthcare:[
      {
        employername:'Trenholm State Community College',
        multilink:false,
        employersite:"https://www.trenholmstate.edu/",
        employerLogo:"trenholm-state.jpg",
        employerp1:"You've come to the right place if you are interested in any of Trenholm State Community College's health education programs. According to the U.S. Bureau of Labor Statistics, occupations related to health care are projected to have the fastest job growth between 2010 and 2020.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/AKRfabWhK_M",videoId:'AKRfabWhK_M'
        },
        {
          videoType:'youtube',link:"https://youtu.be/K2a2Jc3_Dp0",videoId:'K2a2Jc3_Dp0'
        }],
      },
      {
        employername:'Central Alabama Community College',
        multilink:false,
        employersite:"https://www.cacc.edu/admissions/programs/associate-in-applied-science/",
        employerLogo:"central-community.jpg",
        employerp1:"Central Alabama Community College offers a varity of health science career paths and flexible scheduling that provides a world of opportunity for state-of-the-art education and training for a wide range of ages, backgrounds, and life goals, which prepare you for a rewarding future.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/kcberrinvEY",videoId:'kcberrinvEY'
        }],
      },
      {
        employername:'Southern Union State Community College',
        employersite:"https://www.suscc.edu/programs/health-science-overview.cms",
        multilink:false,
        employerLogo:"southern-union.jpg",
        employerp1:"Welcome to the Health Sciences Division of Southern Union State Community College.The Health Sciences Division offers Emergency Medical Services, Nursing, Surgical Technology, Therapeutic Massage, Radiography, Computed Tomography (CT), Magnetic Resonance Imaging (MRI), Medical Assistant Technology and Health and Physical Education courses.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
            videoType:'youtube',link:" https://youtu.be/u1aOjFzBzq4",videoId:'u1aOjFzBzq4'
          }
        ],
      },
      {
        employername:'Wallace Community College – Selma',
        employersite:"https://www.wccs.edu/",
        multilink:false,
        employerLogo:"wallace-college.jpg",
        employerp1:"Health Science encompasses a broad range of occupations and specializations. With an ever-growing and aging population, the need for skilled professionals is steadily increasing in almost every area of the field. According to the Bureau of Labor Statistics, job growth for most health science occupations is expected to double, triple, or even quadruple between 2012 and 2022. Wallace Community College-Selma offers the following health career programs: associate degree nursing and licensed practical nursing. ",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/kKL_3N4fOk4",videoId:'kKL_3N4fOk4'
        }],
      }, 
      {
        employername:'Chattahoochee Valley Community College',
        employersite:"https://www.cv.edu/health-sciences/",
        employerLogo:"chatt-valley.jpg",
        multilink:false,
        employerp1:"Chattahoochee Valley Community College promotes student success and is committed to enriching our community by offering accessible quality and engaging educational opportunities through academic transfer career and technical education workforce development and adult education.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/wppX5hyOwV0",videoId:'wppX5hyOwV0'
        },
        {
          videoType:'vimeo',videoUrl:"https://vimeo.com/652218206/9612c19024",videoId:'652218206'
        }],
      }     
  ],
    manufacturing:[
      /*{
        employername:'MGM Chamber',
        employersite:"https://www.montgomerychamber.com/",
        employerLogo:"mont-chamber.jpg",
        employerp1:"The Montgomery Area Chamber of Commerce catalyzes business and community leadership to improve the economic prosperity & quality of place of Montgomery and the river region. The Chamber is a fully-integrated economic development organization, dedicated to both job creation and job preservation. As a member-based business organization, we work hard to grow markets for our members, grow the River Region’s economy, and increase the quality of life for all.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{              videoType:'youtube',videoUrl:"https://youtu.be/4tknKfIy3fg",videoId:'4tknKfIy3fg'
      }
  
    
  ],
  },*/
      {
        employername:'Trenholm State Community College',
        employersite:"https://www.trenholmstate.edu/programs/technical/engineering-technologiesbr-automotive-advanced-manufacturing/",
        multilink:false,
        employerLogo:"trenholm-state.jpg",
        employerp1:"Trenholm State Community College is a multi-campus institution serving the River Region of Alabama with Associate's Degrees, as well as a wide range of certificate and workforce training options. Trenholm's advantages include small class sizes, hands-on learning, dedicated faculty, day and night classes, online options, affordable tuition, generous financial aid, a diverse community and vibrant student life.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/NKysyVN2ajk",videoId:'NKysyVN2ajk'
        },
        {
          videoType:'youtube',link:"https://youtu.be/72RqeC4Z7e8",videoId:'72RqeC4Z7e8'
        },
        {
          videoType:'youtube',link:"https://youtu.be/WnGRyNVoQKU",videoId:'WnGRyNVoQKU'
        },
      ]
      },
      {
        employername:'Central Alabama Community College',
        employersite:"https://www.cacc.edu/admissions/programs/",
        multilink:false,
        employerLogo:"central-community.jpg",
        employerp1:"Students are trained to be qualified for jobs as multi-craft technicians, to meet the needs of area industry in industrial automation and troubleshooting, including hydraulics and pneumatics, programmable logic controllers, robotics, and sensor technology, which help keep Alabama's industry competitive in the world market and provide students with highly paid and dependable jobs.",
        employerp2:"The Machine Shop program provides students with opportunities to explore and learn a highly skilled and valuable trade. Acquiring the knowledge and skills for precision machining opens a wealth of opportunities throughout the world in such areas as production machinists, tool and die makers, computer numeric control (CNC) operators and programmers, as well as quality inspectors.",
        employerp3:"The Industrial Electronics Technician program at Central Alabama Community College provides students with the knowledge and skills in machinery, automotive, assembly plants, and computer manufacturers. This program prepares students to apply technical knowledge and skills to assemble, install, operate, maintain, and repair electrical/electronic equipment used in industry and manufacturing. Instruction includes installing, maintaining, and testing various types of equipment.",
        images:[],
        videos:[
          {
            videoType:'youtube',link:"https://youtu.be/nwYZO0nWrIg",videoId:'nwYZO0nWrIg'
          },
          {
            videoType:'youtube',link:"https://youtu.be/WtG_MqfkE3g",videoId:'WtG_MqfkE3g'
          },
          {
            videoType:'youtube',link:"https://youtu.be/5GZKRG5YTk8",videoId:'5GZKRG5YTk8'
          },
          {
            videoType:'youtube',link:"https://youtu.be/devpB7_7uC4",videoId:'devpB7_7uC4'
          },
          {
            videoType:'youtube',link:"https://youtu.be/7r2HIdgHR5k",videoId:'7r2HIdgHR5k'
          },
        ],
      },
      {
        employername:'Southern Union State Community College',
        employersite:"https://www.suscc.edu/programs/tech-overview.cms",
        multilink:false,
        employerLogo:"southern-union.jpg",
        employerp1:"At Southern Union State Community College, the programs offered in an Associate in Applied Science degree are: Engineering and Design, Industrial Electricity/Electronics Technology and Manufacturing Technology and five programs which lead to an Associate in Occupational Technologies Degree: Air Conditioning and Refrigeration, Automotive Service Technology, Cosmetology, Machine Shop Technology and Welding Technology. Additionally, the college offers Certificate and Short-Term Certificates within all of the previously listed technical programs for students.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
            videoType:'vimeo',videoUrl:"https://vimeo.com/272370966",videoId:'https://vimeo.com/272370966'
          },
          {
            videoType:'vimeo',videoUrl:"https://vimeo.com/254351660",videoId:'https://vimeo.com/254351660'
          },
          {
            videoType:'vimeo',videoUrl:"https://vimeo.com/277462425",videoId:'https://vimeo.com/277462425'
          },
          {
            videoType:'vimeo',videoUrl:"https://vimeo.com/254351677",videoId:'https://vimeo.com/254351677'
          },
          {
            videoType:'vimeo',videoUrl:"https://vimeo.com/327129285",videoId:'https://vimeo.com/327129285'
          },

        ],
      },
      {
        employername:'Wallace Community College – Selma',
        employersite:"https://www.wccs.edu/",
        employerLogo:"wallace-college.jpg",
        multilink:false,
        employerp1:"The Technical Division of Wallace Community College Selma is a comprehensive division responsive to individual, community and state needs. Training is available for credit in High Wage High demand Career Fields, AIDT certified Job training program known as Ready to Work (RTW), Career Coach Services to Local High School Students and College Students regarding how to become Career and College Ready, and Comprehensive Adult Education Training for preparation for the GED exam." ,
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://www.youtube.com/watch?v=7YXiwFOcxhA",videoId:'7YXiwFOcxhA'
        }]
      },
      {
        employername:'Chattahoochee Valley Community College',
        employersite:"https://www.cv.edu/programs/applied-technology/",
        multilink:false,
        employerLogo:"chatt-valley.jpg",
        employerp1:"Chattahoochee Valley Community College promotes student success and is committed to enriching our community by offering accessible quality and engaging educational opportunities through academic transfer career and technical education workforce development and adult education." ,
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'vimeo',videoUrl:"https://vimeo.com/652218043/01fdfde65c",videoId:'652218043'
        }]
      }
  ],
    public_service:[
      {
        employername:'Marion Military',
        multilink:false,
        employersite:"https://marionmilitary.edu/",
        employerLogo:"marion.jpg",
        employerp1:"Marion Military Institute, a two-year public institution of higher learning, educates and develops cadets as future leaders through an immersive experiential military environment which integrates intellectual, leadership, character and physical development in order to prepare them for success in four-year colleges, U.S. service academies, and in military and civilian careers.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
            videoType:'youtube',link:"https://youtu.be/XwGJ2hba97M",videoId:'XwGJ2hba97M'
          },
          {
            videoType:'youtube',link:"https://youtu.be/mjOtA0gCicI",videoId:'mjOtA0gCicI'
          },
          {
          videoType:'youtube',link:"https://youtu.be/kC7pplZQOzY",videoId:'kC7pplZQOzY'
        },
        {
          videoType:'youtube',link:"https://youtu.be/zE_KeXgYSK4",videoId:'zE_KeXgYSK4'
        },
        {
          videoType:'youtube',link:"https://youtu.be/QETrJXUGxBA",videoId:'QETrJXUGxBA'
        }
      ],
      },
      {
        employername:'Chattahoochee Valley Community College',
        employersite:"https://www.cv.edu/programs/public-safety/",
        multilink:false,
        employerLogo:"chatt-valley.jpg",
        employerp1:"Chattahoochee Valley Community College promotes student success and is committed to enriching our community by offering accessible quality and engaging educational opportunities through academic transfer career and technical education workforce development and adult education.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
          {
            
            videoType:'vimeo',videoUrl:"https://vimeo.com/652218344/767e581069",videoId:'767e581069'
            
          },
      ],
      },   
    ],
    transportation_distribution_and_logistics:[
      {
        employername:'Auburn University',
        employersite:"https://www.auburn.edu",
        employerLogo:"auburn-logo.jpg",
        multilink:false,
        employerp1:"Our students, alumni, and faculty are passionate about aviation and we're excited that you are considering Auburn Aviation and the Auburn Family.  Auburn Aviation is committed to operating by the principles of the Auburn Creed– honesty, service, hard work and respect – principles required by aviation professionals.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/H5lfqTSrWpE",videoId:'H5lfqTSrWpE'
        }],
      },   
      {
        employername:'Trenholm State Community College',
        employersite:"https://www.trenholmstate.edu/programs/technical/automotive-transportation/",
        employerLogo:"trenholm-state.jpg",
        multilink:false,
        employerp1:"Trenholm State Community College is a multi-campus institution serving the River Region of Alabama with Associate's Degrees, as well as a wide range of certificate and workforce training options. Trenholm's advantages include small class sizes, hands-on learning, dedicated faculty, day and night classes, online options, affordable tuition, generous financial aid, a diverse community and vibrant student life.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/M4sjt7mYJVA",videoId:'M4sjt7mYJVA'
        }],
      },
      /*{
        employername:'Four Star Freightliner',
        employersite:"https://www.fourstarfreightliner.com/",
        employerLogo:"four-star-logo.jpg",
        multilink:false,
        employerp1:"Four Star Freightliner grew from two former Dealerships located in central and southern Alabama. Jerry A. Kocan bought the assets and assumed the locations of the Freightliner Dealerships formerly known as Coblentz Truck Center (Montgomery, Alabama) and Freightliner Trucks of Dothan (Dothan, Alabama) from Daimler. Both Dealerships held a long history of success within their communities. These two locations were purchased in 1999 and brought together in January of 2000.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/nLEkMCDPYNs",videoId:'nLEkMCDPYNs'
        },
        {
          videoType:'youtube',link:"https://youtu.be/nPNH0DW85ko",videoId:'nPNH0DW85ko'
        }],
      },*/
      {
        employername:'Wallace Community College Selma',
        employersite:"https://www.wccs.edu/",
        employerLogo:"wallace-college.jpg",
        multilink:false,
        employerp1:"The College shares a vision of a learning-centered education that is responsive to the needs of our service area and is manifested by quality teaching, opportunities for learning, effective educational support services, and access to a quality education. Challenged by change and innovation, the College will move forward to create a future responsive to the diverse needs of students, community, and state.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/5zkJLb53Iys",videoId:'5zkJLb53Iys'
        },
      ],
      },
      {
        employername:'Central Alabama Community College',
        employersite:"https://www.cacc.edu/",
        employerLogo:"central-community.jpg",
        multilink:false,
        employerp1:"Wherever you’re headed in life, you will get a strong start at Central Alabama Community College. CACC’s diverse programs and flexible scheduling provide a world of opportunity for state-of-the-art education and training for a wide range of ages, backgrounds, and life goals, which prepare you for a rewarding future.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/PYSlXW7V_Xo",videoId:'PYSlXW7V_Xo'
        },
      ],
      },  
    ],
    communication_and_information_technology:[
      {
        employername:'Trenholm State Community College',
        employersite:"www.trenholmstate.edu/programs/academics/computer-information-systems/",
        employerLogo:"trenholm-state.jpg",
        multilink:false,
        employerp1:"The study of Computer Information Systems (CIS) at Trenholm State Community College focuses on the practical applications of computer technology through the study of a broad range of subjects. Professionals in this field may have knowledge and expertise in topics such as software design, databases, security analysts, business and more. When it comes to applying technology to business applications a CIS proffessional is required.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/LOPJ9ls31PY",videoId:'LOPJ9ls31PY'
        }],
      },  
        {
            employername:'Auburn University',
            employersite:"https://www.auburn.edu",
            employerLogo:"auburn-univ.jpg",
            multilink:false,
            employerp1:"Auburn University has established itself as an excellent comprehensive, public land-grant university. However, excellence is not enough. We aspire to become a world-renowned institution that excels in education, research, and service—and to become a model of higher education. We understand that higher education is on the precipice of change. We intend to lead and shape that change. Our goals describe Auburn University's highest priorities for the next five years. The accomplishment of these goals will best position our institution to achieve its 20-year vision.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',link:"https://youtu.be/0zMX62-oVoQ",videoId:'0zMX62-oVoQ'
            }],
          },    
          {
            employername:'Central Alabama Community College',
            employersite:"https://www.cacc.edu/admissions/programs/associate-in-applied-science/",
            employerLogo:"central-community.jpg",
            multilink:false,
            employerp1:"The Computer Information Science program at Central Alabama Community College is designed to prepare students to be successful or enhance their careers in select, high-demand, information technology fields. Special emphasis is placed on the knowledge and skills needed in the small business computer environment. In addition, this program provides a solid foundation for those considering transferring to a senior institution.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',link:"https://youtu.be/3kiyP_XYCgE",videoId:'3kiyP_XYCgE'
            }],
          }, 
          {
            employername:'Chattahoochee Valley Community College',
            employersite:"https://www.cv.edu/programs/computer-info-technology/",
            multilink:false,
            employerLogo:"chatt-valley.jpg",
            employerp1:"Chattahoochee Valley Community College promotes student success and is committed to enriching our community by offering accessible quality and engaging educational opportunities through academic transfer career and technical education workforce development and adult education.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',link:"https://youtu.be/DrVLnA7QW_s",videoId:'DrVLnA7QW_s'
            }],
          },
          {
            employername:'Alabama State University',
            employersite:"https://www.alasu.edu/",
            multilink:false,
            employerLogo:"asu-logo.jpg",
            employerp1:"Today, Alabama State University, located in Montgomery, Ala., is a widely respected, world-class institution of higher learning which welcomes students of all races. We offer nearly 50 undergraduate and graduate degree programs, from our historic teacher education program to our new, high-demand programs in health sciences, new Ph.D. in microbiology  and minor in international business.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
              videoType:'youtube',link:"https://youtu.be/WSZgISayw2E",videoId:'WSZgISayw2E'
            }],
          },
    ],
    hospitality_and_tourism:[
      /*{
        employername:'AO Tourism',
        employersite:"https://www.aotourism.com/",
        employerLogo:"auburn-tourism.jpg",
        employerp1:"Auburn-Opelika Tourism is a non-profit service organization driving economic impact and improving the quality of life in our community through tourism and travel. Auburn-Opelika is modern hospitality. Here, you'll find a boutique town that's budget-friendly, a backyard full of Alabama pines, trails, and waterways to explore, year-round gentle seasons, legendary restaurants, a hub for engineering, a vibrant and youthful community, and venues that are extensions of our forward-thinking mentality and rooted traditions.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/Zw8XmsMlEKc",videoId:'Zw8XmsMlEKc'
        },
        {
          videoType:'youtube',link:"https://youtu.be/P1sAIdF9mPw",videoId:'P1sAIdF9mPw'
        },
        {
          videoType:'youtube',link:"https://youtu.be/qdrOvWp-LlU",videoId:'qdrOvWp-LlU'
        },
        {
          videoType:'youtube',link:"https://youtu.be/5OJvOaO5ddk",videoId:'5OJvOaO5ddk'
        },
        {
          videoType:'youtube',link:"https://youtu.be/byV3vi8IE10",videoId:'byV3vi8IE10'
        },
        {
          videoType:'youtube',link:"https://youtu.be/s89-geLg-3I",videoId:'s89-geLg-3I'
        },
        {
          videoType:'youtube',link:"https://youtu.be/PF0gb3eny70",videoId:'PF0gb3eny70'
        },
        {
          videoType:'youtube',link:"https://youtu.be/u0d__zzILxg",videoId:'u0d__zzILxg'
        },
        {
          videoType:'youtube',link:"https://youtu.be/UURLW94R_Ic",videoId:'UURLW94R_Ic'
        }],
      },   */
    ],
    energy_and_utilities:[
      {
        employername:'Central Alabama Community College',
        employersite:"https://www.cacc.edu/about/mission-statement/",
        multilink:false,
        employerLogo:"central-community.jpg",
        employerp1:"Your world is shaped by who you are. Central Alabama Community College offers diverse programs and flexible scheduling that provides a world of opportunity for state-of-the-art education and training for a wide range of ages, backgrounds, and life goals, which prepare you for a rewarding future.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
        {
          videoType:'youtube',link:"https://youtu.be/xdcFbRB1alA",videoId:'xdcFbRB1alA'
        },
        {
          videoType:'youtube',link:"https://youtu.be/kcberrinvEY",videoId:'kcberrinvEY'
        },
      ],
      },    
      {
        employername:'Go Build',
        employersite:"https://gobuildalabama.com",
        employerLogo:"go-build-logo.jpg",
        multilink:false,
        employerp1:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades. Through this campaign, the Alabama Construction Recruitment Institute aims to provide more highly skilled tradesmen for construction businesses and enhanced economic development for Alabama and the nation.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[
        {
          videoType:'youtube',link:"https://youtu.be/_IZLJj25qDE",videoId:'_IZLJj25qDE'
        },
      ],
      },  
    ],
    education_and_training:[  
        {
            employername:'AIDT',
            employersite:"https://www.aidt.edu",
            employerLogo:"aidt.jpg",
            multilink:false,
            employerp1:"The Mission of AIDT is to provide quality workforce development for Alabama's new and expanding businesses, and to expand the opportunities of its citizens through the jobs these businesses create. AIDT provides a full range of customized technical training programs that are offered at no cost to employers and to the trainees.  Leadership training programs are also available.  In addition to training,  AIDT offers services including trainee recruitment and screening, safety assistance, industrial maintenance assessments and continuous improvement/process improvement assessments.",
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{              videoType:'vimeo',videoUrl:"https://vimeo.com/523941991/981b8bc934",videoId:''
          }

        
    ],
     },


    ],
    architecture_construction_and_utilities:[
      {
        employername:'Go Build Alabama',
        multilink:false,
        employersite:"https://gobuildalabama.com",
        employerLogo:"go-build.jpg",
        employerp1:"The Go Build campaign is designed to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades. Through this campaign, the Alabama Construction Recruitment Institute aims to provide more highly skilled tradesmen for construction businesses and enhanced economic development for Alabama and the nation.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/_IZLJj25qDE",videoId:'_IZLJj25qDE'
        }
      ],
      },
      {
        employername:'Trenholm State Community College',
        employersite:"https://www.trenholmstate.edu/",
        employerLogo:"trenholm-state.jpg",
        multilink:false,
        employerp1:"Trenholm State Community College is a multi-campus institution serving the River Region of Alabama with Associate's Degrees, as well as a wide range of certificate and workforce training options. Trenholm's advantages include small class sizes, hands-on learning, dedicated faculty, day and night classes, online options, affordable tuition, generous financial aid, a diverse community and vibrant student life.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/WnGRyNVoQKU",videoId:'WnGRyNVoQKU'
        },
        {
          videoType:'youtube',link:"https://youtu.be/NKysyVN2ajk",videoId:'NKysyVN2ajk'
        },
      ],
      }, 
      {
        employername:'Central Alabama Community College',
        employersite:"https://www.cacc.edu/admissions/programs/associate-in-occupational-technology/welding-associate/",
        multilink:false,
        employerLogo:"central-community.jpg",
        employerp1:"The Welding program at Central Alabama Community College provides students with the knowledge and skills in the heating and melting of metals. Students experience rigorous hands on training with cutting, forming, and the permanent jointing of metal products used in construction of bridges, buildings, and ships some of which are done in conjunction with computers and robots. Students often work on assembly lines, steel mills, railroad shops and highway departments.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/vyGdwGawEbI",videoId:'vyGdwGawEbI'
        },
      ],
      },
      {
        employername:'Wallace Community College – Selma',
        employersite:"https://www.wccs.edu/programs/technical-divisions",
        employerLogo:"wallace-college.jpg",
        multilink:false,
        employerp1:"The Technical Division of Wallace Community College Selma is a comprehensive division responsive to individual, community and state needs. Training is available for credit in High Wage High demand Career Fields, AIDT certified Job training program known as Ready to Work (RTW), Career Coach Services to Local High School Students and College Students regarding how to become Career and College Ready, and Comprehensive Adult Education Training for preparation for the GED exam.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/S7J9GO-2Iko",videoId:'S7J9GO-2Iko'
        },
      ],
      },  
      {
        employername:'Chattahoochee Valley Community College',
        employersite:"https://www.cv.edu/hvac/",
        employerLogo:"chatt-valley.jpg",
        multilink:false,
        employerp1:"The HVAC program at Chattahoochee Valley Community College emphasizes the fundamental principles for air conditioning and refrigeration. Instruction is provided in the theory and principles of refrigeration and heat transfer, HVAC/R system components, common, and specialty tools for HVAC/R, and application of the concepts of basic compression refrigeration. Upon completion, students should identify system components and understand their functions, identify and use common and specialty HVAC/R tools, and maintain components of a basic compression refrigeration system.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/aCAYuw7edto",videoId:'aCAYuw7edto'
        },
      ],
      },  
         
    ],
    
  }

 /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/
  


    var WAW_Programs_Info={ 
      rightImageSrc:"West-Alabama-wow-logo",
        agriculture:[
 
          
             
                {
                  employername:'Alabama Nursery & Landscape Association',
                  employersite:"https://www.alabamagreenindustryjobs.org",
                  multilink:false,
                  employerLogo:"alnla-logo.jpg",
                  employerp1:" The ALNLA is a group of industry leading businesses committed to the continual growth and improvement of the ornamental plant and landscape trades. Our membership includes nursery and greenhouse growers, retail garden centers, landscape maintenance and installation contractors, irrigators, arborists, and others who supply goods and services that support the green industry.",
                  employerp2:"",
                  employerp3:"",
                  images:[],
                  videos:[{
                          videoType:'youtube',link:"https://youtu.be/MBYHvraw-Mg",videoId:'MBYHvraw-Mg'
                        }]
                } ,
                {
                  employername:'Alabama Cooperative Extension System',
                  multilink:false,
                  employersite:"",
                  employerLogo:"extension-logo.jpg",
                  employerp1:"The Alabama Cooperative Extension System is the primary outreach and engagement organization for the land-grant mission of Alabama A&M University and Auburn University in cooperation with Tuskegee University. You will find an Extension Office in all 67 Alabama counties, supported by Regional Extension Agents across the state, and Specialists at both partner universities. Our core values differentiate Extension in today's education marketplace" ,
                  employerp2:"We value:Research-based programs, materials, and educational activities Positive relationships with clients, communities, partners, and stakeholdersRelevant programming that addresses current societal challenges and opportunities",
                  employerp3:"",
                  images:[],
                  videos:[{
                          videoType:'youtube',link:"",videoId:'uOlZp_nwxYg'
                        }]
                  }, 
                  {
                    employername:'Alabama Farmers Federation',
                    multilink:true,
                    employersite:"",
                    employerLogo:"alfa-logo.jpg",
                    employerp1:"The Alabama Farmers Federation is the state's largest farm organization with more than 350,000 member families. Created by farmers, led by farmers and dedicated to serving farmers, the Federation promotes the economic, social and educational interests of Alabamians. In 2021, the Federation is celebrating its centennial anniversary as well as 75 years of providing affordable protection and exceptional service through Alfa Insurance." ,
                    employerp2:"",
                    employerp3:"",
                    links:[
                      {title:"ALFA" ,url:"http://www.alfafarmers.org"},
                      {title:"Brochure",url:"https://alfafarmers.org/resources/brochures/"},
                  
                    ],
                    images:[],
                    videos:[{
                            videoType:'',link:"",videoId:''
                          },]
                    },   
                    {
                      employername:'Alabama Green Industry Training Center',
                      multilink:false,
                      employersite:"https://www.agitc.org/",
                      employerLogo:"algreen-logo.jpg",
                      employerp1:"The Alabama Green Industry Training Center, Inc. (AGITC) was founded in 2006 as a 501(c) 3 non-profit organization with the vision to help educate professionals, students, and homeowners to become better stewards of the environment."
                                  +"The Green Industry encompasses many sectors including agriculture, forestry, horticulture, and turfgrass along with various other environmentally related enterprises." ,
                      employerp2:"The AGITC offers training classes covering topics for the pest management sectors, the landscape, nursery, and greenhouse sectors, and related business applications.",
                      employerp3:"In addition, the AGITC provides an experienced venue for taking Alabama Department of Agriculture & Industries computerized exams, administers certification programs for the industry, and provides on-site training for many types of entities.",
                      links:[
                     
                      ],
                      images:[],
                      videos:[{
                              videoType:'',link:"",videoId:''
                            },]
                      },   
                      {
                        employername:'ForestryWorks',
                        multilink:false,
                        employersite:"https://www.forestryworks.com/",
                        employerLogo:"forestry-works-logo.jpg",
                        employerp1:"ForestryWorks is a collaborative effort by the Forest Workforce Training Institute and other partners within the forest industry to develop a pipeline of qualified workers for the logging and wood product manufacturing industries." ,
                        employerp2:"We educate students, parents, and teachers about careers and job opportunities in the forest industry, and provide interested students with resources to help them along their career path.  It is our mission to provide the next generation with the resources, skills and guidance needed to join the forest and wood products manufacturing workforce. By partnering with education and private business within the southeast’s forest industry, we cultivate careers, build communities and stimulate local economies.",
                        employerp3:"",
                        links:[
                       
                        ],
                        images:[],
                        videos:[{
                                videoType:'',link:"",videoId:''
                              },]
                        },  
      ],
      cosmetology:[{
        employername:'Shelton State Community College - Cosmetology',
        multilink:false,
        employersite:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/cosmetology/",
        employerLogo:"shelton-state-logo.jpg",
        employerp1:"Salon and spa management prepares students for careers in the professional care of hair, skin, and nails. In addition to the required year of training, students must complete all requirements and pass the Alabama Board of Cosmetology Examination. During the training period, the student will learn the technical skills with state-of-the-art equipment and tools." ,
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{
                videoType:'vimeo',videoUrl:"https://vimeo.com/252229826",videoId:''
              }]
        },
        {
          employername:'University Academy of Hair Design',
          multilink:false,
          employersite:"http://uahairdesign.com/",
          employerLogo:"university-hair-logo.jpg",
          employerp1:"Our purpose is simple. It’s all about you: We exist to help our students fulfill their dreams by seeking to understand them as individuals, delivering a strong technical platform, and providing creative freedom. We are continuously looking for ways to improve our program in order to keep up with current educational needs in the field you are entering." ,
          employerp2:"",
          employerp3:"",
          images:[],
          videos:[]
          }   ],
      construction:[
          {
            employername:'Shelton State Community College - Construction',
            multilink:true,
            employersite:"",
            employerLogo:"shelton-state-logo.jpg",
            employerp1:"One of the largest two-year colleges in the state and a Historically Black College/University (HBCU), Shelton State Community College is diverse in both people and possibilities. The College provides on-campus leadership opportunities, progressive partnerships with local business and industry, and innovative learning experiences. In short, it's education that works." ,
            employerp2:"",
            employerp3:"",
            images:[],
            links:[
              {title:"Air Conditioning & Refrigeration Technology" 
              ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/air-conditioning-refrigeration-technology/"},
              {title:"Electrical Technology"
              ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/electrical-technology/"},
              {title:"Engineering Graphics and Design Technology"
              ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/drafting/"},
              {title:"Welding Technology"
              ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/welding/"},
         
            ],
            videos:[{
                    videoType:'youtube',link:"",videoId:'x1C9bGm_I6U'
                  },
                  {
                    videoType:'youtube',link:"",videoId:'HvFs71SYcJk'
                  }]
            },   
            {
              employername:'Go Build Alabama',
              multilink:false,

              employersite:"http://www.gobuildalabama.com/",
              employerLogo:"go-build-logo.jpg",
              employerp1:"Alabama Construction Recruitment Institute created the the Go Build Alabama campaign to educate young people on the value of learning a trade, dispel their misconceptions about the construction industry and inspire them to consider a career in the skilled trades. Through this campaign, the Alabama Construction Recruitment Institute aims to help Alabama citizens connect with resources to start a construction career path, provide more highly skilled tradesmen for construction businesses, and enhanced economic development for Alabama and the nation." ,
              employerp2:"",
              employerp3:"",
              images:[],
              links:[
                        
              ],
              videos:[
                {
                  videoType:'youtube',videoUrl:"https://youtu.be/qRRv2FwYll8",videoId:'qRRv2FwYll8'
                } ]
              },
              {
                employername:'TUSCALOOSA PLUMBERS & PIPEFITTERS LOCAL 372',
                multilink:false,

                employersite:"https://www.ualocal372.org/",
                employerLogo:"pipefitters-logo.jpg",
                employerp1:"Plumbers & Steamfitters Local 372 was chartered August 4th, 1934. We currently represent over 300 union trained members with our geographical territory encompassing Bibb, Choctaw, Fayette, Green, Hale, Lamar, Marengo, Perry, Pickens, Sumner, Tuscaloosa counties.",
                employerp2:"",
                employerp3:"",
                images:[],
                links:[
                          
                ],
                videos:[
 ]
                },       
                {
                  employername:'Skilled Trades of West Alabama',
                  multilink:false,

                  employersite:"https://skilledtradesofwestalabama.com/",
                  employerLogo:"skilled-trades-logo.jpg",
                  employerp1:"Skilled Trades of West Alabama is changing lives. With a mission to build a highly skilled, qualified and prepared workforce for the construction industry in West Alabama, we offer opportunities for apprenticeships and training in the construction trades. With a high demand for and shortage of skilled, ready-to-work employees and tradesmen, opportunities exist now to build high-paying, successful careers.",
                  employerp2:"",
                  employerp3:"",
                  images:[],
                  videos:[{
                    },   
                  ],
                  },
            
      ],
      education:[
          {
            employername:'Bevill State Community College - Child Development ',
            multilink:false,
            employersite:"http://www.bscc.edu/",
            employerLogo:"bevill-state-logo.jpg",
            employerp1:"Start your career as an Educator with help from Bevill State. Do you enjoy working with children or mentoring others? The Child Development program prepares individuals to work in settings that care for and educate children. Participants are taught the essentials of designing curriculum, creating lesson plans, and introducing activities appropriate for children. Students learn observation and assessment techniques, positive discipline, communication, as well as, CPR, first aid, safety, and nutrition." ,
            employerp2:"Did you know? Many join the Child Development program with the desire to become a Head Start teacher, Pre-K or daycare director or owner, or becoming certified to aid in the public school system.",
            employerp3:"Future Forecast: Median pay is $28,570 up to $45,670 for Directors annually with 7% job growth expected through 2024. (Data from the Bureau of Labor Statistics, U.S. Dept. of Labor, Occupational Outlook Handbook, 2016-2017).",
            images:[],
            videos:[{
                    videoType:'youtube',link:"",videoId:'42xrCr3o09A'
                  }]
            },   
            {
              employername:'Shelton State Community College - Child Development',
              multilink:false,
              employersite:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/child-development/",
              employerLogo:"shelton-state-logo.jpg",
              employerp1:"Child Development is the study of the physical, socioemotional, and cognitive growth and development of the child from conception through adolescence. The purposes of this major are to provide a sound academic program in Child Development and prepare students to work with children and families in a variety of school and community settings." ,
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[{
                      videoType:'',link:"",videoId:''
                    }]
              },
              {
                employername:'The University of West Alabama - Division of Economic & Workforce Development',
                multilink:false,
                employersite:"http://www.uwaworks.com/",
                employerLogo:"uwa-logo.jpg",
                employerp1:"The Division of Economic and Workforce Development at UWA is committed to working to create a seamless workforce development process for the region that support and enhances economic development and workforce training through effective collaboration with employers and communities within West Alabama. Through its LINCS program the Division employs mobile training units to serve a 10-county region and bring training programs to communities, industry and schools." ,
                employerp2:"",
                employerp3:"",
                images:[],
                videos:[{
                        videoType:'',link:"",videoId:''
                      }],

                }  ,
                {
                  employername:'The University of Alabama College of Education',
                  multilink:false,
                  employersite:"https://education.ua.edu",
                  employerLogo:"university-alabama-logo.jpg",
                  employerp1:"The University of Alabama has long been concerned with programs for the preparation of public school personnel. In 1844, only 13 years after the University's establishment in 1831, a Normal Department was provided for those who wished to prepare for teaching careers. In 1872, the Normal Department become the Normal School. By 1899, the Trustees had replaced the Normal School with the School of Pedagogy and Psychology, which in 1902 became the School of Philosophy and Education. In 1908, the school was reorganized as the Department of Education; one year later further organizational changes resulted in the name being changed to the School of Education." ,
                  employerp2:"The present College of Education was established in 1928. Until 1924, the University's offerings in teacher education were almost entirely limited to undergraduate programs for elementary and secondary teachers. Graduate work in school administration was added in 1924; and all programs, undergraduate and graduate, were greatly expanded during 1927 and 1928. Doctoral programs were authorized in 1951. Currently, the College of Education is divided into six departments.",
                  employerp3:"",
                  images:[],
                  videos:[{
                          videoType:'youtube',videoUrl:"https://vimeo.com/536063123/97cd93af22",videoId:'cnqpQgV7qoM'
                        }]
                  },
                  {
                    employername:"Alabama Works and AIDT",                
                    multilink:false,
                    employersite:"https://alabamaworks.com/",
                    employerLogo:"aw-aidt-logo.jpg",
                    employerp1:"AIDT is a state agency established to build a healthy state economy by recruiting and training a skilled workforce to attract new industries to the state and to expand existing industries. As a division of the Alabama Department of Commerce, AIDT provides a full range of customized technical training programs that are offered at no cost to employers and to the trainees.",
                    employerp2:"",
                    employerp3:"",
                    images:[],
                    links:[
                      {title:"AlabamaWorks Website" 
                    ,url:"https://alabamaworks.com/"
                    },
                    {title:"AIDT Website" 
                    ,url:"https://www.aidt.edu/"
                    }
                    ],
                    videos:[
                      {
                        videoType:'youtube',videoUrl:"https://youtu.be/tIMP4mvMv1Q",videoId:'tIMP4mvMv1Q'
                      },
                        ]
                    },
             {
              employername:'Alabama Technology Network',
              multilink:true,
              employersite:"https://waw-image.s3.amazonaws.com/wawpdfs/ATN.pdf",
              employerLogo:"atn-logo.jpg",
              employerp1:"ATN provides extensive workforce training, technical assistance, & engineering services to incumbent workers across the state in areas of Continuous Improvement, Environmental Health & Safety, Food Safety, Industrial Maintenance & Technology, and Leadership & Management Development.",
              employerp2:"",
              employerp3:"",
              links:[
                {title:"Company Brochure" 
                ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/ATN.pdf"
                },
                {title:"ATN Website" 
                ,url:"http://www.atn.org"
                }
              ],
              images:[],
              videos:[{
                },   
              ],
              },
             {
              employername:'Wallace Community College Selma',
              multilink:false,
              employersite:"http://www.wccs.edu/",
              employerLogo:"wallace-logo.jpg",
              employerp1:"The College shares a vision of a learning-centered education that is responsive to the needs of our service area and is manifested by quality teaching, opportunities for learning, effective educational support services, and access to a quality education. Challenged by change and innovation, the College will move forward to create a future responsive to the diverse needs of students, community, and state.",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[{
                videoType:'youtube',link:"",videoId:'BXCtUpj7g1E'
              }   
              ],
              },

        ],
        transportation_distribution_and_logistics:[
          {
            employername:'',
            multilink:false,
            employersite:"",
            employerLogo:"",
            employerp1:"" ,
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
                    videoType:'',link:"",videoId:''
                  }]
            },   
        ],
        energy_and_utilities:[
          {
            employername:'The University of West Alabama',
            multilink:true,
            employersite:"",
         //   links:[
       //       {title:"Visit Site" ,url:"http://www.uwaworks.com/"},
         //     {title:"Learn More",url:"https://waw-image.s3.amazonaws.com/wawpdfs/WOW+BX+UWA+Energy+%2B+Utilities.pdf"},
          
        //    ],
            employerLogo:"uwa-logo.jpg",
            employerp1:"The Division of Economic and Workforce Development at UWA is committed to working to create a seamless workforce development process for the region that support and enhances economic development and workforce training through effective collaboration with employers and communities within West Alabama. Through its LINCS program the Division employs mobile training units to serve a 10-county region and bring training programs to communities, industry and schools." ,
            employerp2:"",
            employerp3:"",
            images:[],
            videos:[{
                    videoType:'',link:"",videoId:''
                  }],
          
                  links:[
                    {title:"Website" 
                  ,url:"http://www.uwaworks.com/"
                  },
                  {title:"Learn More" 
                  ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/WOW+BX+UWA+Energy+%2B+Utilities.pdf"
                  }
                  ],    
            },   
            
        ],
        engineering_and_environment:[
          {
            employername:'Shelton State Community College - Engineering + Environment',
            multilink:true,
            employersite:"",
            employerLogo:"shelton-engineer-logo.jpg",
            employerp1:"" ,
            employerp2:"",
            employerp3:"",
            images:[],
            links:[
              {title:"Engineering Graphics & Design Technology" ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/drafting/"},
              {title:"Academic Programs",url:"https://www.sheltonstate.edu/instruction-workforce-development/academic-programs/"},
          
            ],
            videos:[{
                    videoType:'',link:"",videoId:''
                  }]
            },
            {
              employername:'Shelton State Community College - Computer Science Technology',
              multilink:true,
              employersite:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/computer-science-technology/",
              employerLogo:"shelton-computer-logo.jpg",
              employerp1:"The Computer Science Technology program allows students to become expert problem solvers with the practical and theoretical systems that underlie computer systems. Students will have the opportunity to obtain various industry-recognized certifications within the program, including A+, Computer Programming, Cisco Networking, Comp TIA, and more. Computer Science Technology Program graduates will be prepared for numerous entry-level positions in computer programming, application and software development, computer systems analysis, LAN setup and maintenance, and database administration." ,
              employerp2:"",
              employerp3:"",
              images:[],
              links:[
                {title:"Computer Science Technology" ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/computer-science-technology/"},
                {title:"Academic Programs",url:"https://www.sheltonstate.edu/instruction-workforce-development/academic-programs/"},
            
              ],
              videos:[{
                      videoType:'youtube',link:"",videoId:'HvFs71SYcJk'
                    },
                    {
                      videoType:'youtube',link:"",videoId:'d0qpmnuXpt4'
                    },
                    {
                      videoType:'youtube',link:"",videoId:'Vkw1GWQuTi0'
                    }]
              } ,
              {
                employername:'AL Dept of Conservation and Natural Resources',
                multilink:true,
                employersite:"https://www.outdooralabama.com/",
                employerLogo:"wildlife-fish-logo.jpg",
                employerp1:"The State of Alabama's Dept of Conservation and Natural Resources Wildlife and Freshwater Fisheries' Aquatic Education Program." ,
                employerp2:"",
                employerp3:"",
                images:[],
                links:[
                  {title:"Brochure" ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/110935167_go_fish_alabama_flyer.pdf"},
                  {title:"Learn More" ,url:"https://www.outdooralabama.com/"},

                ],
                videos:[]
                } 
        ],
        healthcare:[
          {
            employername:'Bevill State Community College - Health Sciences',
            multilink:false,
            employersite:"http://www.bscc.edu/",
            employerLogo:"bevill-state-logo.jpg",
            employerp1:"Health Science is a career choice that offers numerous opportunities. Good paying jobs are readily available to graduates in almost every part of the country. But it is not just about getting a job. As a health care provider, you will make a difference in the lives of your clients and their families everyday. Various certificate programs are also available in many other short-term and concentrated fields of study."+
           "-Nursing"+
           "-Evening Nursing Program (PDF) (Jasper Campus)"+
           "-PN/Paramedic to RN Mobility"+
           "-Healthcare Transition (Military Medic) to PN Mobility"+
           "-Nursing Assistant"+
           "-Surgical Technology"+
           "-Phlebotomy"+
           "-Dental Assistant",
            employerp2:"",
            employerp3:"",
            images:[],
           
            videos:[
              {
                videoType:'youtube',link:"",videoId:'8sqBqnVDkoY'
              }
              ]
            },   
            {
              employername:'Tuscaloosa SAFE Center',
              multilink:false,
              employersite:"https://www.tuscaloosasafecenter.com/",
              employerLogo:"safe-center-logo.jpg",
              employerp1:"Tuscaloosa SAFE Center is a non-profit community-based, collaborative agency designed to meet the emotional, medical, and forensic needs of sexual assault survivors by providing quality medical care including assessment, treatment, forensic examination, and follow-up. These services are available to anyone 14 and older. We work with community resource advocates, law enforcement and other service providers to meet the needs of sexual assault survivors. When necessary, we provide expert testimony if the patient chooses to seek justice. Tuscaloosa SAFE Center provides specialty training for nurses to conduct these exams using forensic technology to collect and document evidence that can later be used by law enforcement personnel.",
              employerp2:"",
              employerp3:"",
              images:[],
              videos:[{
                },   
              ],
              },
            {
              employername:'Shelton State Community College - Healthcare',
              multilink:true,
              employersite:"https://www.sheltonstate.edu",
              employerLogo:"shelton-state-logo.jpg",
              employerp1:"Shelton State Community College Health Services programs include:  Health Information Technology Program; Respiratory Therapy Program; and the Nursing -the Associate Degree Nursing Program, Practical and Nursing Assistant Program. Program  preparation varies from two semester certificate curricula to longer program s of study. Programs are led by qualified instructors and have earned specialized accreditations. Students who complete the programs are prepared to sit for industry credentials and enter the health care workforce.",
              employerp2:"",
              employerp3:"",
              images:[],
              links:[
                {title:"Health Information Technology" 
                ,url:"https://www.sheltonstate.edu/instruction-workforce-development/health-services-programs/health-information-technology-hit/"},
                {title:"Respiratory Therapy"
                ,url:"https://www.sheltonstate.edu/instruction-workforce-development/health-services-programs/respiratory-therapy/"},
                {title:"Nursing Associate Degree Nursing" 
                ,url:"https://www.sheltonstate.edu/instruction-workforce-development/health-services-programs/associate-degree-nursing/"},
                {title:"Practical Nursing"
                ,url:"https://www.sheltonstate.edu/instruction-workforce-development/health-services-programs/nursing-program/"},
                {title:"Nursing Assistant/Home Health Aide"
                ,url:"https://www.sheltonstate.edu/instruction-workforce-development/health-services-programs/nursing-assistant-home-health-aide/"},
                {title:"Respiratory Therapy Program"
                ,url:"https://be-an-rt.org/what-is-respiratory-therapy/"},
              ],
              videos:[{
                videoType:'youtube',link:"",videoId:'hxiBYiA2058'
              },{
                videoType:'youtube',link:"",videoId:'mCkCm2_JBH0'
              },
              {
                videoType:'youtube',link:"",videoId:'b5NDiCAMhc8'
              }]
              },
              {
                employername:'Wallace Community College Selma',                
                multilink:false,
                employersite:"http://www.wccs.edu/",
                employerLogo:"wallace-logo.jpg",
                employerp1:"The Associate Degree Nursing (ADN) Program at Wallace Community College Selma is designed to prepare students to wccsit for the National Council Licensure Examination for Registered Nurses (NCLEX-RN) and to become professional nurses prepared to meet the health care needs of individuals of all ages at any point on the health-illness continuum.",
                employerp2:"",
                employerp3:"",
                images:[],
                links:[
                 
                ],
                videos:[{
                        videoType:'youtube',videoUrl:"https://vimeo.com/536063144/0b6195f1d8",videoId:'yCw00NvG0To'
                      }]
                },
                {
                  employername:'UWA WIOA Career Pathways for Youth Program',                
                  multilink:true,
                  employersite:"",
                  employerLogo:"uwa-logo.jpg",
                  employerp1:"The UWA WIOA Career Pathways for Youth Program is housed within the Division of Economic and Workforce Development (DEWD) at The University of West Alabama. The UWA WIOA Career Pathways for Youth: Certified Nursing Assistant (CNA) and Emergency Medical Technician (EMT) programs are designed to help young people between the ages of 18 and 24 develop career skills and provide them with the training and skills needed to enter the healthcare field. Participants also receive internet and transportation support, work attire, and medical tools.",
                  employerp2:"In addition to career readiness and healthcare training, all participants receive one-on-one career guidance, job search assistance, and other supportive services for at least 12 months following completion of the course.",
                  employerp3:"",
                  images:[],
                  links:[
                    {title:"View CNA Program" ,url:"https://www.uwa.edu/about/universitydepartments/divisionofoutreachservices/workforcedevelopment/careerpathwaysforyouth%3Acnaprogram"},
                    {title:"View EMT Program",url:"https://www.uwa.edu/about/universitydepartments/divisionofoutreachservices/workforcedevelopment/certifiednursingassistantprogram"},
                  ],
                  videos:[]
                  }
        ],
        hospitality_and_tourism:[  
          {
            employername:'Shelton State Community College - Hospitality + Tourism',                multilink:false,
            employersite:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/culinary-arts/",
            employerLogo:"shelton-state-logo.jpg",
            employerp1:"The Culinary Arts program provides students with the opportunity to acquire a strong theoretical knowledge base, critical competencies, practical skills, and professional demeanor and behavior necessary to perform successfully and creatively in the culinary arts and food service industry. Topics include food selection and preparation, menu planning, meal management, and restaurant and food service operation.",
            employerp2:"",
            employerp3:"",
            images:[],
            links:[
             
            ],
            videos:[{
                    videoType:'youtube',link:"",videoId:'-muATUduPv0'
                  },
                  {
                    videoType:'youtube',link:"",videoId:'JCMratcpgd8'
                  },
                  {
                    videoType:'youtube',link:"",videoId:'bL7Z9WG5KU8'
                  }
                ]
            }
        ],
        manufacturing_auto_logistics:[
          {
            employername:'Mercedes-Benz U.S. International, Inc. MECHATRONICS TRAINING PROGRAM',                
            multilink:false,
            employersite:"https://www.mbusi.com/employment/training/mechatronics-program",
            employerLogo:"mercedes-benz-logo.jpg",
            employerp1:"Mechatronics is a training program and partnership between MBUSI, Shelton State Community College, and the Alabama Industrial Development Training (AIDT)."
            +"Originally, Mechatronics came from the combination of MECHANICS and ELECTRONICS. The increasing complexity of technology has since warranted the inclusion of various additional functions such as Mechanical, Electrical, Computer, and Control Engineering."
            +"The Mechatronics program provides students with the cognitive and hands-on skills necessary to support the building of world class automobiles at Mercedes-Benz U.S. International in Vance, Alabama."
             ,
            employerp2:"After completing the Mechatronics Training & Curriculum at Shelton State, the top percentage of the graduating class enrolled in the program, will advance to MBUSI and earn a full time position in Production."

+           "Of the students that advance to MBUSI, a top percentage will go into the MB Industrial Mechatronics Maintenance Program at AIDT.",
            employerp3:"PARTNERSHIP & ASSISTANCE"
            +"The Mercedes-Benz Industrial Mechatronics program is a training partnership between Mercedes-Benz U.S. International, Inc. in Vance, Alabama, Shelton State Community College and Alabama Industrial Development Training, Alabama. (AIDT)"
            
            +"The whole Mercedes-Benz Industrial Mechatronics Program includes 7 terms of instruction at Shelton State Community College and an additional 18 months of training at Mercedes-Benz U.S. International at AIDT in Vance. Only a certain percentage of the students that advance to MBUSI will be selected for the 18 months of additional training. Upon satisfactory completion of the coursework at Shelton State, students will earn an Associates of Applied Science degree in Industrial Electronics and a short certificate in Industrial Maintenance. Coursework only offered during day classes. This Program does not include college preparatory work."
            
            +"ASSISTANCE FOR TUITION & FEES"
            +"MBUSI provides assistance for tuition and fees for all terms at Shelton State Community College. Eligibility for assistance for Term 2 through Term 7 is dependent upon overall GPA.",
            images:[],
            links:[
             
            ],
            videos:[
                ]
            },
            {
              employername:'Mercedes-Benz U.S. International, Inc.'
              +'TECH Advanced Automotive Technology Training Program'
              ,                
              multilink:false,
              employersite:"https://www.mbusi.com/employment/training/mercedes-tech-program",
              employerLogo:"mercedes-tech-logo.jpg",
              employerp1:"The Mercedes TECH Advanced Automotive Technology training program is primarily focused on providing instruction and training to better prepare students for a possible position as a high skilled repair technician. Our premium Mercedes-Benz SUVs are basically computers with wheels. To become a skilled technician today, requires knowledge of electrical concepts, first and foremost. Through this program, students learn everything about our amazing Mercedes-Benz SUVs and after earning the degree, they also earn a start to a Career with Mercedes-Benz U.S. International. Are you interested in technology? Are you interested in cars? Then, this program is where you need to be!",
              employerp2:"",
              employerp3:"",
              images:[],
              links:[
                {title:"Brochure" 
                ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/MBUSI+TECH+Program.pdf"
                }
                
              ],
              videos:[
                  ]
              },
              {
                employername:"Alabama Technology Network",                
                multilink:true,
                employersite:"http://www.atn.org/",
                employerLogo:"atn-logo.jpg",
                employerp1:"ATN provides extensive workforce training, technical assistance, & engineering services to incumbent workers across the state in areas of Continuous Improvement, Environmental Health & Safety, Food Safety, Industrial Maintenance & Technology, and Leadership & Management Development.",
                employerp2:"",
                employerp3:"",
                images:[],
                //brochure:"https://waw-image.s3.amazonaws.com/wawpdfs/ATN.pdf",
                links:[
                  {title:"Company Brochure" 
                  ,url:"https://waw-image.s3.amazonaws.com/wawpdfs/ATN.pdf"
                  },
                  {title:"ATN Website" 
                  ,url:"http://www.atn.org"
                  }
                ],
                videos:[
                    ]
                },
                {
                  employername:"Alabama Works and AIDT",                
                  multilink:true,
                  employersite:"https://alabamaworks.com/",
                  employerLogo:"aw-aidt-logo.jpg",
                  employerp1:"AIDT is a state agency established to build a healthy state economy by recruiting and training a skilled workforce to attract new industries to the state and to expand existing industries. As a division of the Alabama Department of Commerce, AIDT provides a full range of customized technical training programs that are offered at no cost to employers and to the trainees.",
                  employerp2:"",
                  employerp3:"",
                  images:[],
                  links:[
                    {title:"AlabamaWorks Website" 
                  ,url:"https://alabamaworks.com/"
                  },
                  {title:"AIDT Website" 
                  ,url:"https://www.aidt.edu/"
                  },
                  {title:"Company Brochure" 
                  ,url:" http://westalabamaworks.com/wp-content/uploads/AIDT.pdf"
                  }
                  ],
                  videos:[
                    {
                      videoType:'youtube',videoUrl:"https://youtu.be/tIMP4mvMv1Q",videoId:'tIMP4mvMv1Q'
                    },
                      ]
                  },
                  {
                    employername:"Sheldon State",                
                    multilink:true,
                    employersite:"https://www.sheltonstate.edu/",
                    employerLogo:"shelton-state-logo.jpg",
                    employerp1:"Modern Manufacturing prepares individuals to apply basic manufacturing principles and technical skills to the identification and resolution of production problems in the manufacturing of products.",
                    employerp2:" The program includes instruction in machine operations, production line operations, systems analysis, instrumentation, physical controls, automation, manufacturing planning, quality control, and informational infrastructure.",
                    employerp3:"",
                    images:[],
                    links:[
                      {title:"Automotive Services Technology" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/automotive-technology/"
                  },
                  {title:"Computerized Numerical Control" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/computerized-numerical-control/"
                  },
                  {title:"Electrical Technology" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/electrical-technology/"
                  },
                  {title:"Industrial Electronics Technology" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/industrial-electronics-technology/"
                  },
                  {title:"Machine Tool Technology" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/machine-tool-technology/"
                  },
                  {title:"Modern Manufacturing Technology" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/modern-manufacturing-technology/"
                  },
              
                    ],
                    videos:[
                      {
                        videoType:'youtube',link:"",videoId:'TRjLwk4VLVw'
                      },
                      {
                        videoType:'youtube',link:"",videoId:'Vkw1GWQuTi0'
                      },
                      {
                        videoType:'youtube',link:"",videoId:'1xUf1SdmHws'
                      },                      {
                        videoType:'youtube',link:"",videoId:'bwbRKIs2LMo'
                      },
 
                      {
                        videoType:'youtube',link:"",videoId:'d0qpmnuXpt4'
                      },

                      {
                        videoType:'youtube',link:"",videoId:'pCTmyHKsp_8'
                      },

                        ]
                    },
                    {
                      employername:"The University of West Alabama",                
                      multilink:false,
                      employersite:"http://www.uwaworks.com/",
                      employerLogo:"uwa-logo.jpg",
                      employerp1:"The Division of Economic and Workforce Development at UWA is committed to working to create a seamless workforce development process for the region that support and enhances economic development and workforce training through effective collaboration with employers and communities within West Alabama. Through its LINCS program the Division employs mobile training units to serve a 10-county region and bring training programs to communities, industry and schools.",
                      employerp2:"",
                      employerp3:"",
                      images:[],
                      links:[
                       
                      ],
                      videos:[
                          ]
                      },
                      {
                        employername:"Wallace Community College Selma -Welding Technology",                
                        multilink:false,
                        employersite:"http://www.wccs.edu/",
                        employerLogo:"wallace-welding-logo.jpg",
                        employerp1:"The Welding Technology (WDT) Program at Wallace Community College Selma provides students with the technical skills necessary to enter the construction, manufacturing, and repair industries.  WDT students attain hands-on experience in a variety of welding processes used by various industries in the fabrication and repair of steel components. Through practical exercises which are designed to be as realistic as possible and representative of actual world-of-work experiences, WDT students learn to use much different cutting and joining processes.",
                        employerp2:"",
                        employerp3:"",
                        images:[],
                        links:[
                         
                        ],
                        videos:[
                          {
                            videoType:'youtube',
                            videoUrl:"",
                            videoId:'UJWIY6PTCmY'
                          },

                            ]
                        },
                        {
                          employername:"Wallace Community College Selma -Electrical Technology",
                          multilink:false,
                          employersite:"http://www.wccs.edu/",
                          employerLogo:"wallace-tech-logo.jpg",
                          employerp1:"The Electrical Technology Program at Wallace Community College Selma prepares individuals to apply technical knowledge and skills to install, operate, maintain, and repair electric apparatus and systems such as residential, commercial, and industrial electric-power wiring; and DC and AC motors, controls, and electrical distribution panels. Includes instruction in the principles of electronics and electrical systems, wiring, power transmission, safety, industrial and household appliances, job estimation, electrical testing and inspection, and applicable codes and standards.",
                          employerp2:"",
                          employerp3:"",
                          images:[],
                          links:[
                           
                          ],
                          videos:[

                            {
                              videoType:'youtube',
                              videoUrl:"https://vimeo.com/536063138/72668f458c",
                              videoId:'ZfMS1AGXs7o'
                            },
                              ]
                          }

        
        ],
        public_safety:[
          {
            employername:"The University of West Alabama",

            multilink:false,
            employersite:"http://www.uwaworks.com/",
            employerLogo:"uwa-logo.jpg",
            employerp1:"The Division of Economic and Workforce Development at UWA is committed to working to create a seamless workforce development process for the region that support and enhances economic development and workforce training through effective collaboration with employers and communities within West Alabama. Through its LINCS program the Division employs mobile training units to serve a 10-county region and bring training programs to communities, industry and schools.",
            employerp2:"",
            employerp3:"",
            images:[],
            links:[
             
            ],
            videos:[
                ],
            },
            {
              employername:"PRIDE of Tuscaloosa",
  
              multilink:false,
              employersite:"https://www.prideoftuscaloosa.org/",
              employerLogo:"pride-logo.jpg",
              employerp1:"The Parents Resource Institute for Drug Education (P.R.I.D.E.) of Tuscaloosa is the only non-profit organization in our area dedicated to drug and alcohol abuse prevention and awareness.  P.R.I.D.E. has been a 501c3 since 1986, providing high quality, evidence-based prevention services in the Tuscaloosa area for over 30 years.  P.R.I.D.E. is a Certified Prevention Agency by the Alabama Department of Mental Health (ADMH) and is a certified provider by both ADMH and the Alabama Dept. of Public Health.  We focus our efforts on reaching school-aged children and young adults prior to the beginning of external influences which can lead to drug and alcohol abuse.",
              employerp2:"",
              employerp3:"",
              images:[],
              links:[
               
              ],
              videos:[
                  ],
              }
        
        ],
        transportation:[
          {
            employername:"Shelton State Community College - Transportation",
            

            multilink:true,
            employersite:"https://www.sheltonstate.edu",
            employerLogo:"shelton-state-logo.jpg",
            employerp1:"The Diesel Mechanics program trains students to remove, repair, install, and maintain diesel engines powering heavy trucks, buses, boats, diesel-powered equipment, and construction equipment, such as bulldozers, cranes, and diesel farm tractors. Principles of diesel engines are taught and reinforced in laboratory experiences using actual diesel equipment.  The Truck Driving is an eight-week program offered through Community Education. Upon successful completion of the program's course and passing the Department of Transportation (DOT) Road Test, the student will receive an operator's license from the Alabama Department of Public Safety and a certificate of completion from Shelton State Community College. The course is 288 contact hours and WIOA and VA approved.  Registration is continuous and on a first come first serve basis.",
            employerp2:"",
            employerp3:"",
            images:[],
            links:[
              {title:"Diesel Mechanics" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/technical-programs/diesel-mechanics/"
                  },
                  {title:"Truck Driving" 
                  ,url:"https://www.sheltonstate.edu/instruction-workforce-development/work-force-development/truck-driving-program/"
                  },
            ],
            videos:[

              {
                videoType:'youtube',
                link:"",
                videoId:'dGYG004aigE'
              },
                ]
            },
            {
              employername:"The University of West Alabama",
  
              multilink:false,
              employersite:"http://www.uwaworks.com/",
              employerLogo:"uwa-logo.jpg",
              employerp1:"The Division of Economic and Workforce Development at UWA is committed to working to create a seamless workforce development process for the region that support and enhances economic development and workforce training through effective collaboration with employers and communities within West Alabama. Through its LINCS program the Division employs mobile training units to serve a 10-county region and bring training programs to communities, industry and schools.",
              employerp2:"",
              employerp3:"",
              images:[],
              links:[
               
              ],
              videos:[
                  ]
              }
        
        ],
      }
  /*########################################################################################################*/
/*########################################################################################################*/
/*########################################################################################################*/


      var NAW_Programs_Info={ 
        agriculture_food_and_natural_resources:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
                     {
                     programName:"Mining/AMTC Program", 
                     description:"The Alabama Mining Academy was officially established by the Alabama Legislature in 1985. The Academy is the central agency for assistance to Alabama's miners and mine operators in mine safety and mine training. Its purposes are 1) to provide assistance to the mining industry in developing and implementing effective mine health and safety programs and 2) to improve and expand programs aimed at safer, more productive mining industry.",
                     link:"https://www.bscc.edu/programs/workforce-solutions/mining-technology",
                     videos:[
                            {
                             videoType:'youtube',
                             link:"",
                             videoUrl:"",
                             videoId:'bxeML688-ww'
                            },
                     ]
                     },
                     {
                      programName:"Welding", 
                      description:"Welding is the most common way of permanently joining metal parts, and welders learn the characteristics and properties, such as melting points of steel, aluminum, and other commonly used metals.",
                      link:"https://www.bscc.edu/programs/career-tech/welding-technology",
                      videos:[
                             {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                             },
                      ]
                      },

                    ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
  
              programs:[
                       {
                       programName:"Welding Technology", 
                       description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                       link:"https://calhoun.edu/technologies/welding/",
                       videos:[]
                       }
  
                      ],
  
            },  
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                        {
                          programName:"Welding Technology", 
                          description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Welding is designed to teach the principles of welding as well as the nature of metals and types of gases used in welding. The welding curriculum also includes skills in welding and fusing of various metal types using an array of techniques and methods.The program is designed to provide qualified individuals the opportunity to acquire the knowledge, attitudes, and skills to obtain a certificate verifying their competency in the basic requirements of Welding Technology.",
                          link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/welding",
                          videos:[
                                {
                                  videoType:'youtube',
                                  link:"",
                                  videoUrl:"",
                                  videoId:'TPmRYt9Qkx8 '
                                },
                          ]
                        }
                         
  
                      ],
            },
            {
              entityName:"Northeast Alabama Community College",
              entityLogo:"nacc-logo.jpg",

              programs:[
                      
                        {
                          programName:"Industrial Systems Technology", 
                          description:"Industrial Systems Technology encompasses a wide range of fields related to high-skill, high-technology, and high-wage jobs within industry.",
                          link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-systems-technology",
                          videos:[]
                        },                     
                        {
                          programName:"Business", 
                          description:"The Business Department offers an Associate in Science degree with focuses in Business, Business Education, and Economics, which is part of the College Transfer Program. The division also offers an Associate in Applied Science degree in Business Management and Supervision. We offer courses in Accounting, Economics, Statistics, Management, Finance, Retailing, Marketing, and Salesmanship.",
                          link:"https://www.nacc.edu/programs-of-study/business-and-computer-science-division/business-department",
                          videos:[]
                        },               
  
                      ],
                  ///////END
            },
            {
              entityName:"Northwest-Shoals Community College",
              entityLogo:"shoals-logo.jpg",

              programs:[
                        {
                        programName:"Electrical Technology", 
                        description:"Electrical students will be trained to design, stage, and install commercial and residential electrical wiring for power control systems from 'rough in' to 'finish.' The will also gain the skills to supply power to a wide range of systems and structures according to the National Electrical Code. Prior to completing their degree, students will be experienced at troubleshooting electrical circuits for safe and efficient operation.", 
                        link:"https://www.nwscc.edu/program-of-study/career-technical",
                        videos:[
                              {
                                videoType:'youtube',
                                link:"",
                                videoUrl:"",
                                videoId:'cpS_Hyo2tFU'
                              },
                        ]
                        },
                        {
                        programName:"Welding",
                        description:"Welding students are trained to use electricity to design, dismantle, and construct a wide range of projects. NCCER certified instructors prepare their students to use advanced welding techniques and equipment to join, cut, bend, and manipulate metal for industrial or artistic applications. Students have the ability to become certified by the American Welding Society. Students learn to work independently and in teams in this high-skill, high-wage field to better prepare for the workforce",
                        link:"https://www.nwscc.edu/program-of-study/career-technical",
                        videos:[
                                {
                                videoType:'youtube',
                                link:"",
                                videoUrl:"",
                                videoId:'cpS_Hyo2tFU'
                                },
                        ]
                        },
                          
                        
                        
                      ],
                  ///////END
            },              
            {
              entityName:"Snead Community College",
              entityLogo:"snead-logo.jpg",


              programs:[
                        {
                        programName:"Agricultural Business Economics",
                        description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                        link:"",
                        videos:[]
                        },
                        {
                          programName:"Agronomy and Soils",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Animal/Dairy Science",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Chemical Engineering: Natural Science",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Environmental Science",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Fisheries Science",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Meteorology",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Poultry Science",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Wildlife Science",
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Horticulture",
                          description:"Some of our classes transfer specifically into the College of Agriculture at Auburn University. Auburn’s Horticulture Department has a 100% job placement rate since 1979. Our program will prepare you to pass the state exams for chemical applicator license, landscape designer license, and nurseryman license.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        }, 
                        {
                          programName:"Landscape Gardening",
                          description:"Some of our classes transfer specifically into the College of Agriculture at Auburn University. Auburn’s Horticulture Department has a 100% job placement rate since 1979. Our program will prepare you to pass the state exams for chemical applicator license, landscape designer license, and nurseryman license.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Animal and Dairy Science",
                          description:"Some of our classes transfer specifically into the College of Agriculture at Auburn University. Auburn’s Horticulture Department has a 100% job placement rate since 1979. Our program will prepare you to pass the state exams for chemical applicator license, landscape designer license, and nurseryman license.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Introduction to Agriculture",
                          description:"Some of our classes transfer specifically into the College of Agriculture at Auburn University. Auburn’s Horticulture Department has a 100% job placement rate since 1979. Our program will prepare you to pass the state exams for chemical applicator license, landscape designer license, and nurseryman license.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Agricultural Economics",
                          description:"Some of our classes transfer specifically into the College of Agriculture at Auburn University. Auburn’s Horticulture Department has a 100% job placement rate since 1979. Our program will prepare you to pass the state exams for chemical applicator license, landscape designer license, and nurseryman license.",
                          link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                        },
                        {
                          programName:"Food Science",
                          description:"The Social Sciences and Human Services Division offers courses in a wide variety of fields including Child Development, Criminal Justice, Economics, History, Political Science, Psychology and Sociology. Courses meet prerequisite requirements for transfer to four year institutions. In addition to transfer credit, the Child Development program has stand-alone certificate and degree programs that help prepare students for the work force.",
                          link:"https://snead.edu/academics/social-sciences/",
                          videos:[]
                        },
                        {
                          programName:"Electrical Engineering",
                          description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                          link:"https://snead.edu/academics/technology/",
                          videos:[]
                        },
                      ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",


              programs:[
                        {
                        programName:"Agricultural Production/Sustainable Agriculture",

                        description:"Wallace State's Agricultural Production and Sustainable Agriculture programs are designed to prepare students for various jobs in local agriculture, business, and industry. ",
                        link:"https://www.wallacestate.edu/programs/technical-division/agricultural-production",
                        videos:[]
                        },
                        {
                        programName:"Small Farmer Training Program",
                        description:"Wallace State's new Small Farmer Training Program will prepare students to start and operate their own farms or serve as a farm manager of existing farms. Also for those interested in working in agriculture, it will offer a better understanding of farm operations to work in non-profits or farmer assistance and promotion organizations.",
                        link:"https://www.wallacestate.edu/programs/technical-division/farmer-training",
                        videos:[]
                        } ,
                        {
                          programName:"Welding",
                          description:"Welding, the most common way of permanently joining metal parts, is used to construct and repair parts of ships, automobiles, spacecraft and thousands of other manufactured products. Heat is applied to the pieces to be joined, melting and fusing them to form a permanent bond.",
                          link:"https://www.wallacestate.edu/programs/technical-division/welding",
                          videos:[]
                          } ,
                          {
                            programName:"Geospatial",
                            description:"The Wallace State Geospatial Technology programs prepare students for entry level positions within the in-demand field of Geographic Information Systems.  Individuals will create and update maps and charts for regional planning, education, emergency response, and other purposes using a variety of data.  Do you enjoy maps and geography?  Does being on the forefront of technology excite you? Do you have solid computer and communication skills?  Consider a career in GIS! ",
                            link:"https://www.wallacestate.edu/programs/technical-division/gis",
                            videos:[]
                            } ,
                            {
                              programName:"Poultry Science",
                              description:"Alabama’s most profitable agricultural industry is the focus of a new partnership between Auburn University and Wallace State Community College in Hanceville. The partnership is designed to open more career doors in a part of the state where much of the poultry industry is concentrated. Students in the program will complete their freshman and sophomore years at Wallace State and their junior and senior years at Auburn, completing a bachelor’s degree in poultry science.",
                              link:"https://www.wallacestate.edu/programs/technical-division/poultry-science",
                              videos:[]
                              } ,
                          
                      ],
            },
                                ////entities end 
           ],
                  ///////END

          ////world end
        },
        architecture_and_construction:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
                     {
                     programName:"Advanced Engineering Design Technology",
                     description:"Drafters prepare detailed drawings based on rough sketch specifications and calculations made by scientists, engineers, architects, and designers. Various occupations drafting graduates are qualified to seek are detailer, machine drafter, designer, architectural drafter, structural drafter, survey technician and drafter, electrical drafter, engineering drawing checker, drafting department supervisor, and CAD drafter, operator, or technician.",
                     link:"https://www.bscc.edu/programs/career-tech/advanced-engineering-design-technology-edt",
                     videos:[
                            {
                             videoType:'youtube',
                             link:"",
                             videoUrl:"",
                             videoId:'8ZtH1kN-blQ'
                            },
                     ]
                     },
                     {
                      programName:"Air Conditioning/Refrigeration Technology",
                      description:"Heating and air-conditioning systems control the temperature, humidity, and the total air quality in residential, commercial, industrial, and other buildings. By providing a climate controlled environment, refrigeration systems make it possible to store and transport food, medicine, and other perishable items. Heating, Air-conditioning, and Refrigeration Technicians install, maintain, and repair such systems. Because heating, ventilation, air-conditioning, and refrigeration systems often are referred to as HVACR systems, these workers also may be called HVACR technicians.",
                      link:"https://catalog.bscc.edu/air-conditioning-refrigeration-technology-acr",
                      videos:[
                             {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'bhAUGqmyajI'
                             },
                      ]
                      },
                      {
                        programName:"Electrical Systems Technology",
                        description:"The Electrical Systems Technology program prepares individuals with the knowledge and skills for entry into the field as technicians with specialized skills. The program includes options for electrical technology, industrial plant technician, automated manufacturing, manufacturing engineering, and industrial electronics.",
                        link:"https://www.bscc.edu/programs/career-tech/automated-manufacturing-technology",
                        videos:[
                               {
                                videoType:'youtube',
                                link:"",
                                videoUrl:"",
                                videoId:'cpS_Hyo2tFU'
                               },
                        ]
                        },
                        {
                          programName:"Welding",
                          description:"Welding is the most common way of permanently joining metal parts, and welders learn the characteristics and properties, such as melting points of steel, aluminum, and other commonly used metals.",
                          link:"https://www.bscc.edu/programs/career-tech/welding-technology",
                          videos:[
                                 {
                                  videoType:'youtube',
                                  link:"",
                                  videoUrl:"",
                                  videoId:'TPmRYt9Qkx8'
                                 },
                          ]
                          },
                          {
                            programName:"Manufactured Home Installation Training",
                            description:"The Manufactued Home Installation Training program is taught by subject matter experts, utilizing a combination of structured classroom instruction and live work opportunities, in order to prepare participants for work with Certified Manufactured Home Installers and/or to take the Alabama Manufactured Housing Commision Certified Installer exam.",
                            link:"https://www.bscc.edu/programs/workforce-solutions/manufactured-home-installation-training",
                            videos:[
                                   {
                                    videoType:'youtube',
                                    link:"",
                                    videoUrl:"",
                                    videoId:'TPmRYt9Qkx8'
                                   },
                            ]
                            },
                            {
                              programName:"HVAC Fast Track",
                              description:"HVAC Fast Track is offered through a parternship between Bevill State and Alabama Power. Training takes place at the state-of-the-art HVAC Training Center. Participants complete an intensive 11-week training program and graduate ready to take their HVAC Contractor's Exam and their EPA Section 608 Refrigerant Handling Certification. HVAC Technicians service, repair, install, and sometimes sell heating, central air conditioning, HVAC, and refrigeration systems, HVAC Technicians may work in on residential, commercial, or industrial systems. They may be self-employed, working alone; own their own HVAC business employing others; work for another HVAC business; or work for a business or organization in which the technician only works on their HVAC components (i.e., a university, school system, hospital, airport, or large manufacturer).",
                              link:"https://www.bscc.edu/hvac-fast-track",
                              videos:[
                                     {
                                      videoType:'youtube',
                                      link:"",
                                      videoUrl:"",
                                      videoId:'TPmRYt9Qkx8'
                                     },
                              ]
                              },
                    ],
            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
  
              programs:[
                       {
                        programName:"Air Conditioning/Refrigeration Technology",
                        description:"Heating and air-conditioning systems control the temperature, humidity, and the total air quality in residential, commercial, industrial, and other buildings. By providing a climate controlled environment, refrigeration systems make it possible to store and transport food, medicine, and other perishable items. Heating, Air-conditioning, and Refrigeration Technicians install, maintain, and repair such systems. Because heating, ventilation, air-conditioning, and refrigeration systems often are referred to as HVACR systems, these workers also may be called HVACR technicians.",
                        link:"https://catalog.bscc.edu/air-conditioning-refrigeration-technology-acr",
                        videos:[]
                       },
                       {
                        programName:"Design Drafting Technology",
                        description:"The Design Drafting Technology program prepares students for employment as entry-level drafters in a high technology society. The curriculum is designed to provide students with the skill set necessary to advance as experience is gained in their profession. Students will receive training in the fundamentals of CAD drafting with introductory courses in the fields of mechanical and architectural drafting.",
                        link:"https://calhoun.edu/technologies/design-drafting-technology/",
                        videos:[]
                       },
                       {
                        programName:"Welding Technology",
                        description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                        link:"https://calhoun.edu/technologies/welding/",
                        videos:[]
                       },
                        
                      ],
            },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Electrical Technology",
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Electrical Technology will prepare students in, Residential, Commercial and Industrial Wiring, Transformers, Fluid Power, National Electrical Code, Motor Controls, Programmable Logic Controls (PLCs), Ac/Dc Drives, Industrial Robotics, Technical Communications, and Solid State Devices. This will benefit those who are seeking apprenticeship and journeymen electrician certification, but also for training electricians currently employed in Advanced Manufacturing.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/electrical-technology",
                  videos:[]
                  },
                  {
                    programName:"Heating & Air Conditioning Technology",
                    description:"The Heating & Air Conditioning program examines the principles of heating and cooling air in controlled situations. The knowledge and applications of electricity and refrigerants are taught through problem-solving techniques to enable students to diagnose malfunctions and service/repair residential, commercial and industrial systems. The Heating & Air Conditioning curriculum is designed to help students prepare for employment in the field of heating, ventilation, and air conditioning.",
                    link:"https://www.drakestate.edu/academic-programs/heating-air-conditioning-technology",
                    videos:[]
                    },
                    {
                      programName:"Welding Technology",
                      description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                      link:"https://calhoun.edu/technologies/welding/",
                      videos:[]
                      },
                      {
                        programName:"Engineering Design",
                        description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Engineering Design will prepare students for work in the mechanical, architectural or electronic drafting fields. Students start by changing or redrawing plans and advance to designing complete working drawings from preliminary sketches and specifications using Computer Aided Drafting Design (CAD) software systems. Additional training allows a student to calculate the strength, size and cost of materials.",
                        link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/engineering-design",
                        videos:[]
                        },
                        {
                          programName:"The Commercial Construction Program (CCP)",
                          description:"The Commercial Construction Program (CCP) will serve residents of Alabama who wish to obtain the basic NCEER certification and hands on experience to enter the workforce as a certified construction employee. This program provides training towards the completion of a nationally-recognized construction certification and by teaching workforce readiness skills that increase employability and job retention. Additionally, the program contributes to meeting the needs of the local commercial construction industry which has reported significant shortage of qualified applicants. No educational requirements are imposed on the students which means that the program eligibility extends to the economically disadvantaged individuals who failed to obtain a high-school diploma. Upon completion of the program, students will receive nationally-recognized professional certifications.",
                          link:"https://www.drakestate.edu/workforce-development",
                          videos:[]
                          },
                      ],
            },
            {
              entityName:"Northeast Alabama Community College",
              entityLogo:"nacc-logo.jpg",

              programs:[
                      {
                          programName:"Drafting & Design Technology",
                          description:"Computer-aided drafting and design (also known as CADD) plays an integral role in the construction and manufacturing industries. Drafters and CAD Technicians use computer software to create technical drawings & solid models that provide specifications for buildings, tools, complex machinery & product assemblies and are typically employed in the engineering, architectural or manufacturing fields.",
                          link:"https://www.nacc.edu/programs-of-study/tech-programs/drafting-and-design-technology",
                          videos:[]
                      },
                      {
                        programName:"Engineering Technician",
                        description:"The Engineering Technician program at NACC prepares students to be the eyes, ears, hands and feet of engineers. The ENT program includes courses in electrical, programmable logic controls, mechanical, structural, materials and project management, as well as quality and productivity. By understanding the language of engineering and various industrial systems, the engineering technician can assist engineers in designing, testing, building, operating and maintaining systems in aerospace, energy, manufacturing, construction, and consulting.",
                        link:"https://www.nacc.edu/programs-of-study/tech-programs/engineering-technician",
                        videos:[]
                      },
                      {
                        programName:"Heating, Ventilating, Air Conditioning & Refridgeration",
                        description:"The Air Conditioning and Refrigeration Technology program teaches the theories and principles of the operation of heating, ventilation, air conditioning, and refrigeration (HVACR) equipment.  A variety of related laboratory projects allow students to put into practice the knowledge and skills gained, preparing them for skilled service technician positions.",
                        link:"https://www.nacc.edu/programs-of-study/tech-programs/hvacr",
                        videos:[]
                      },
                      {
                        programName:"Industrial Systems Technology",
                        description:"Industrial Systems Technology encompasses a wide range of fields related to high-skill, high-technology, and high-wage jobs within industry.",
                        link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-systems-technology",
                        videos:[]
                      },
                      {
                        programName:"Carpentry",
                        description:"The Carpentry program at NACC provides career opportunities for those who like to work with their hands and make things happen! The college offers two short-term certificates as well as two non-credit options to meet the needs of anyone interested in residential or commercial carpentry. In these classes, you will learn both the theory and hands-on skills you need to be successful.",
                        link:"https://www.nacc.edu/programs-of-study/skills-training",
                        videos:[]
                        },
                      ],
                  ///////END
            },
            {
              entityName:"Northwest-Shoals Community College",
              entityLogo:"shoals-logo.jpg",

              programs:[
                          {
                            programName:"Electrical Technology",
                            description:"Electrical students will be trained to design, stage, and install commercial and residential electrical wiring for power control systems from 'rough in' to 'finish.' The will also gain the skills to supply power to a wide range of systems and structures according to the National Electrical Code. Prior to completing their degree, students will be experienced at troubleshooting electrical circuits for safe and efficient operation.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Welding",
                            description:"Welding students are trained to use electricity to design, dismantle, and construct a wide range of projects. NCCER certified instructors prepare their students to use advanced welding techniques and equipment to join, cut, bend, and manipulate metal for industrial or artistic applications. Students have the ability to become certified by the American Welding Society. Students learn to work independently and in teams in this high-skill, high-wage field to better prepare for the workforce",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Air Conditioning/Refrigeration Technology",
                            description:"In Air Conditioning/Refrigeration Technology, students will train to design, fabricate, and install heating, ventilation, air-conditioning, and refrigeration systems. They will gain the knowledge to utilize advanced tools and diagnostic equipment to develop effective troubleshooting skills. Before completing the program, students will be able to assess and repair residential and commercial systems. Students will train under an NCCER and CFC certified instructor. Students will have an opportunity to become State certified following completion of their AOT degree.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Applied Engineering & Machining",
                            description:"In Applied Engineering and Machining (Machine Shop), you will gain the skills to invent, design, create, and build the high-tech​ precision parts and tools used worldwide in everyday products. Students learn to use advanced computer-controlled technology to manufacture precise steel, aluminum, and plastic components. Machine Shop graduates enter the workforce proficient in programming and controlling industrial CNC machines to produce products from engineering blueprints and specifications.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Carpentry/Cabinetmaking",
                            description:"If you have a desire to build a residential home from the ground up, the Carpentry and Cabinetmaking program is for you. Students will also have the opportunity to design and construct high-end custom cabinets. Before completion of the program, students will fine tune their skills to operate a wide-range of professional power tools for commercial and residential clients while learning from a National Center for Construction Education and Research (NCCER) certified instructor.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Design Engineering Technology",
                            description:"Design Engineering students learn to visualize and construct dynamic 3D models for architectural and mechanical designs and create, design, and generate a wide range of full-color prototypes, prints, and 3D computer-aided drawings. Before graduating, students will become adept at using the same advanced equipment and software as professional architects, engineers, and designers.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Power5 HVAC Apprenticeship Program",
                            description:"Northwest-Shoals Community College (NW-SCC) is excited to launch their first Department of Labor (DOL) Registered Apprenticeship program in Heating, Ventilation, and Air Conditioning (HVAC). The Power5 HVAC (Heating, Ventilation, and Air Conditioning) Apprenticeship Program at NW-SCC combines an Associate of Applied Science Degree (AAS) in Air Conditioning and Refrigeration Technology and two years of paid industry experience.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical/power-5-hvac",
                            videos:[]
                          },
                                  ],
            },
            {
              entityName:"Snead Community College",
              entityLogo:"snead-logo.jpg",

              programs:[
              {
                      programName:"Business",
                      description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                      link:"https://snead.edu/academics/business/",
                      videos:[]
                    },
                    {
                      programName:"Electrical Engineering",
                      description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                      link:"https://snead.edu/academics/technology/",
                      videos:[]
                    },
            ////entities end
  ],
///////END
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",

              programs:[
              {
                      programName:"Building Construction",
                      description:"Construction Management is one of the fastest growing majors at colleges and universities, according to the Education Advisory Board. This is due in part to a skills gap in the construction industry, prompting a need for skilled construction professionals.",
                      link:"https://www.wallacestate.edu/programs/technical-division/buildingconstruction",
                      videos:[]
                    },
                    {
                      programName:"Heating, Ventilation, Air Conditioning & Refrigeration",
                      description:"HVAC/R systems are diverse and consist of many different components. However, they all depend on the same mechanical, electrical and other scientific principles that allow these systems to perform the jobs that we depend on.",
                      link:"https://www.wallacestate.edu/programs/technical-division/heating-and-air-conditioning",
                      videos:[]
                    },
                    {
                      programName:"Welding",
                      description:"Welding, the most common way of permanently joining metal parts, is used to construct and repair parts of ships, automobiles, spacecraft and thousands of other manufactured products. Heat is applied to the pieces to be joined, melting and fusing them to form a permanent bond.",
                      link:"https://www.wallacestate.edu/programs/technical-division/welding",
                      videos:[]
                    },
                    {
                      programName:"Engineering ",
                      description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                      link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology",
                      videos:[]
                    },
            ////entities end
            ],
            ///////END
            },
          ],
          ////world end
        },
        education_and_training:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
                     {
                     programName:"Child Development",
                     description:"Do you enjoy working with children or mentoring others? The Child Development program prepares individuals to work in settings that care for and educate children.",
                     link:"https://www.bscc.edu/programs/career-tech/child-development",
                     videos:[
                            {
                             videoType:'youtube',
                             link:"",
                             videoUrl:"",
                             videoId:'jlzsRpW2Kag'
                            },
                     ]
                     },
                    ],
            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
  
              programs:[
                       {
                       programName:"Social Sciences",
                       description:"This program is designed primarily for students who plan to seek employment in preschool or school-age programs. All students are required to complete the General Education Core Requirements and the Child Development Common Core courses.",
                       link:"https://catalog.calhoun.edu/child-development/child-development-aas",
                       videos:[]
                       },
                      ],
              },
            {
                      entityName:"Northeast Alabama Community College",
                      entityLogo:"nacc-logo.jpg",

                      programs:[
                              {
                                  programName:"Child Development",
                                  description:"The Child Development program at NACC will prepare you for a rewarding career in childcare. The need for childcare is ever increasing and qualified personnel are needed to fill the many positions available.",
                                  link:"https://www.nacc.edu/programs-of-study/english-and-fine-arts-division/child-development",
                                  videos:[]
                              },
                              ],
                          ///////END
            },
            {
                              entityName:"Northwest-Shoals Community College",
                              entityLogo:"shoals-logo.jpg",

                              programs:[
                                          {
                                            programName:"Child Development",
                                            description:"Students have the opportunity to train in a National Association for Education of Young Children (NAEYC) Certified center and experience the joy of creating fun and amazing activities for children who are full of life and love learning. Child Development students gain experience planning activities that enhance a child's intellectual, social, emotional, and physical development.",
                                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                                            videos:[]
                                          },
                                         ],
            },
             {
                              entityName:"Snead Community College",
                              entityLogo:"snead-logo.jpg",

                              programs:[
                                          {
                                            programName:"Child Development",
                                            description:"The Social Sciences and Human Services Division offers courses in a wide variety of fields including Child Development, Criminal Justice, Economics, History, Political Science, Psychology and Sociology. Courses meet prerequisite requirements for transfer to four year institutions. In addition to transfer credit, the Child Development program has stand-alone certificate and degree programs that help prepare students for the work force.",
                                            link:"https://snead.edu/academics/social-sciences/",
                                            videos:[]
                                          },
                                          {
                                            programName:"Early Childhood Development",
                                            description:"The Social Sciences and Human Services Division offers courses in a wide variety of fields including Child Development, Criminal Justice, Economics, History, Political Science, Psychology and Sociology. Courses meet prerequisite requirements for transfer to four year institutions. In addition to transfer credit, the Child Development program has stand-alone certificate and degree programs that help prepare students for the work force.",
                                            link:"https://snead.edu/academics/social-sciences/",
                                            videos:[]
                                          },
                                          {
                                            programName:"Elementary Education",
                                            description:"The Social Sciences and Human Services Division offers courses in a wide variety of fields including Child Development, Criminal Justice, Economics, History, Political Science, Psychology and Sociology. Courses meet prerequisite requirements for transfer to four year institutions. In addition to transfer credit, the Child Development program has stand-alone certificate and degree programs that help prepare students for the work force.",
                                            link:"https://snead.edu/academics/social-sciences/",
                                            videos:[]
                                          },
                                         ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",
  
              programs:[
                       {
                       programName:"Child Development",
                       description:"The Child Development program at Wallace State Community College works to instill in its students the skills and education they will need to play a vital role in a young person’s life. ",
                       link:"https://www.wallacestate.edu/programs/health-division/child-development",
                       videos:[]
                       },
                      ],
              },////entities end
           ],///////END
        },
        energy_and_utilities:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
                     {
                     programName:"Electrical Systems Technology",
                     description:"The Electrical Systems Technology program prepares individuals with the knowledge and skills for entry into the field as technicians with specialized skills. The program includes options for electrical technology, industrial plant technician, automated manufacturing, manufacturing engineering, and industrial electronics.",
                     link:"https://www.bscc.edu/programs/career-tech/automated-manufacturing-technology",
                     videos:[
                            {
                             videoType:'youtube',
                             link:"",
                             videoUrl:"",
                             videoId:'cpS_Hyo2tFU'
                            },
                     ]
                     },
                     {
                      programName:"Welding",
                      description:"Welding is the most common way of permanently joining metal parts, and welders learn the characteristics and properties, such as melting points of steel, aluminum, and other commonly used metals.",
                      link:"https://www.bscc.edu/programs/career-tech/welding-technology",
                      videos:[
                             {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                             },
                      ]
                      },
                      {
                        programName:"Computer Science",
                        description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                        link:"https://www.bscc.edu/programs/career-tech/computer-science",
                        videos:[
                               {
                                videoType:'youtube',
                                link:"",
                                videoUrl:"",
                                videoId:'-E7q3M9qAh0'
                               },
                        ]
                        },
                    ],
            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
  
              programs:[
                       {
                       programName:"Welding Technology",
                       description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                       link:"https://calhoun.edu/technologies/welding/",
                       videos:[
                              {
                               videoType:'youtube',
                               link:"",
                               videoUrl:"",
                               videoId:'EUmsMpATcJc'
                              },
                       ]
                       },
                       {
                        programName:"Design Drafting Technology",
                        description:"The Design Drafting Technology program prepares students for employment as entry-level drafters in a high technology society. The curriculum is designed to provide students with the skill set necessary to advance as experience is gained in their profession. Students will receive training in the fundamentals of CAD drafting with introductory courses in the fields of mechanical and architectural drafting.",
                        link:"https://calhoun.edu/technologies/design-drafting-technology/",
                        videos:[]
                        },
                        {
                          programName:"Lady Lineworker",
                          description:"",
                          link:"https://calhoun.edu/workforce-solutions/lady-lineworker/",
                          videos:[]
                          },
                          {
                            programName:"Pre-Apprentice Lineworker School",
                            description:"",
                            link:"https://calhoun.edu/workforce-solutions/pre-apprentice-lineworker-program/",
                            videos:[]
                            },
                      ],
              },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Computer Information System",
                  description:"The Computer Information Systems Technology program is an Associate Degree and Certificate awarding program. Emphasis is placed on hands on training in the areas of microcomputer operating systems, applications, programming languages and networking equipment. After completing a degree in the Computer Information Systems Technology program, a student will have the skills to fulfill entry-level positions in computer support and operations, networking, and software development fields in the industry.",
                  link:"https://www.drakestate.edu/academic-programs/computer-information-systems-technology",
                  videos:[]
                  },
                  {
                    programName:"Welding Technology",
                    description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Welding is designed to teach the principles of welding as well as the nature of metals and types of gases used in welding. The welding curriculum also includes skills in welding and fusing of various metal types using an array of techniques and methods.The program is designed to provide qualified individuals the opportunity to acquire the knowledge, attitudes, and skills to obtain a certificate verifying their competency in the basic requirements of Welding Technology.",
                    link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/welding",
                    videos:[]
                    },
                    {
                      programName:"Engineering Design",
                      description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Engineering Design will prepare students for work in the mechanical, architectural or electronic drafting fields. Students start by changing or redrawing plans and advance to designing complete working drawings from preliminary sketches and specifications using Computer Aided Drafting Design (CAD) software systems. Additional training allows a student to calculate the strength, size and cost of materials.",
                      link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/engineering-design",
                      videos:[]
                      },
                      {
                        programName:"Electrical Technology",
                        description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Electrical Technology will prepare students in, Residential, Commercial and Industrial Wiring, Transformers, Fluid Power, National Electrical Code, Motor Controls, Programmable Logic Controls (PLCs), Ac/Dc Drives, Industrial Robotics, Technical Communications, and Solid State Devices. This will benefit those who are seeking apprenticeship and journeymen electrician certification, but also for training electricians currently employed in Advanced Manufacturing.",
                        link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/electrical-technology",
                        videos:[]
                        },
                      ],
             },
                    {
                      entityName:"Northeast Alabama Community College",
                      entityLogo:"nacc-logo.jpg",

                      programs:[
                              {
                                  programName:"Drafting & Design Technology",
                                  description:"Computer-aided drafting and design (also known as CADD) plays an integral role in the construction and manufacturing industries. Drafters and CAD Technicians use computer software to create technical drawings & solid models that provide specifications for buildings, tools, complex machinery & product assemblies and are typically employed in the engineering, architectural or manufacturing fields.",
                                  link:"https://www.nacc.edu/programs-of-study/tech-programs/drafting-and-design-technology",
                                  videos:[]
                              },
                              {
                                programName:"Engineering Technician",
                                description:"The Engineering Technician program at NACC prepares students to be the eyes, ears, hands and feet of engineers. The ENT program includes courses in electrical, programmable logic controls, mechanical, structural, materials and project management, as well as quality and productivity. By understanding the language of engineering and various industrial systems, the engineering technician can assist engineers in designing, testing, building, operating and maintaining systems in aerospace, energy, manufacturing, construction, and consulting.",
                                link:"https://www.nacc.edu/programs-of-study/tech-programs/engineering-technician",
                                videos:[]
                              },
                              {
                                programName:"Industrial Electronics/Mechatronnics",
                                description:"Companies depend on complex electronic equipment for a variety of functions. From industrial controls that automatically monitor and direct production processes on the factory floor to radar and missile control systems that provide for the national defense, electronics devices are increasingly used to boost productivity and optimize results. These complex pieces of equipment are installed, maintained, and repaired by electronics technicians.",
                                link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-electronics",
                                videos:[]
                              },
                              {
                                programName:"Industrial Systems Technology",
                                description:"Industrial Systems Technology encompasses a wide range of fields related to high-skill, high-technology, and high-wage jobs within industry.",
                                link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-systems-technology",
                                videos:[]
                              },
                              ],
                          ///////END
                    },
            {
              entityName:"Northwest-Shoals Community College",
              entityLogo:"shoals-logo.jpg",

              programs:[
                          {
                            programName:"Welding",
                            description:"Welding students are trained to use electricity to design, dismantle, and construct a wide range of projects. NCCER certified instructors prepare their students to use advanced welding techniques and equipment to join, cut, bend, and manipulate metal for industrial or artistic applications. Students have the ability to become certified by the American Welding Society. Students learn to work independently and in teams in this high-skill, high-wage field to better prepare for the workforce",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Design Engineering Technology",
                            description:"Design Engineering students learn to visualize and construct dynamic 3D models for architectural and mechanical designs and create, design, and generate a wide range of full-color prototypes, prints, and 3D computer-aided drawings. Before graduating, students will become adept at using the same advanced equipment and software as professional architects, engineers, and designers.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Industrial Systems Technology",
                            description:"Industrial Systems Technology students train to control robots and other automated systems through the use of programmable logic controllers (PLCs). Students also have the opportunity to use advanced injection molding equipment and learn techniques to create components used worldwide. Once students complete the program, they have the skills necessary to install, maintain, and repair a wide range of mechanical drive components as well as advanced electrical and hydraulic manufacturing systems.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                    ],
                                ////entities end
            },
            {
              entityName:"Snead Community College",
              entityLogo:"snead-logo.jpg",

              programs:[
                          {
                            programName:"Civil Engineering",
                            description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                            link:"https://snead.edu/academics/math/",
                            videos:[]
                          },
                          {
                            programName:"Mechanical Engineering",
                            description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                            link:"https://snead.edu/academics/math/",
                            videos:[]
                          },
                          {
                            programName:"Electrical Engineering",
                            description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                            link:"https://snead.edu/academics/technology/",
                            videos:[]
                          },
                        ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",

              programs:[
                          {
                            programName:"Geospatial ",
                            description:"The Wallace State Geospatial Technology programs prepare students for entry level positions within the in-demand field of Geographic Information Systems.  Individuals will create and update maps and charts for regional planning, education, emergency response, and other purposes using a variety of data.  Do you enjoy maps and geography?  Does being on the forefront of technology excite you? Do you have solid computer and communication skills?  Consider a career in GIS! ",
                            link:"https://www.wallacestate.edu/programs/technical-division/gis",
                            videos:[]
                          },
                          {
                            programName:"Welding",
                            description:"Welding, the most common way of permanently joining metal parts, is used to construct and repair parts of ships, automobiles, spacecraft and thousands of other manufactured products. Heat is applied to the pieces to be joined, melting and fusing them to form a permanent bond.",
                            link:"https://www.wallacestate.edu/programs/technical-division/welding",
                            videos:[]
                          },
                          {
                            programName:"Engineering",
                            description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                            link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology",
                            videos:[]
                          },
                        ],
            },
          ]                                     
        },
        healthcare:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
              {
                programName:"Office Administration & Technology", 
                description:"The Business Office Management and Technology program at Bevill State Community College offers students a broad foundation of knowledge, experience, and technical skills that can be applied in many types of fields. Students in the program have found specialized positions in sales, marketing, accounting, medical settings, law firms and banks. Because the core skills students develop are portable and applicable in numerous settings, employment opportunities are always available.",
                link:"https://www.bscc.edu/programs/career-tech/office-administration-and-technology-oat",
                videos:[]
              },
              {
                programName:"Health Sciences", 
                description:"Health Science is a career choice that offers numerous opportunities. Good paying jobs are readily available to graduates in almost every part of the country. But it is not just about getting a job. As a health care provider, you will make a difference in the lives of your clients and their families everyday. Various certificate programs are also available in many other short-term and concentrated fields of study.",
                link:"https://www.bscc.edu/programs/health-sciences",
                videos:[
                          {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'PHeeWtcW8G4'
                            },
                        ]
              },
            ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
              programs:[
              {
                  programName:"Business & CIS",
                  description:"The Business Administration program provides a solid foundation for students preparing to enter any business-related field. The curriculum develops logical thinking and decision-making capabilities as well as career-specific skills.",
                  link:"https://calhoun.edu/business-cis/business/",
                  videos:[]
                 },
                 {
                  programName:"Health Sciences",
                  description:"Healthcare is one of the largest economic sectors in the US, employing one out of every eight American workers. Education in any health care fields is demanding, but if you are looking for a meaningful career with good employment opportunities in a field where you can make a difference in people’s lives, a health science program might be the right choice for you.",
                  link:"https://calhoun.edu/health-sciences/",
                  videos:[]
                 },
                 {
                  programName:"Contact Tracing",
                  description:"Contact Tracing for COVID-19 is a 16-hour instructor led online training course that uses an activity-based approach to build and enhance the knowledge base, skills and discipline necessary to effectively conduct contact tracing. This course requires a prerequisite self-paced online course (see prerequisite information below) for the purpose of providing a nationally recognized consistent foundation of knowledge related to COVID-19 and contact tracing.",
                  link:"https://calhoun.edu/workforce-solutions/contact-tracing/",
                  videos:[]
                 },
                 {
                  programName:"Healthcare Training",
                  description:"Our short-term, career track Healthcare Instructors will give brief overviews of their programs. You will be able to ask the instructors your specific questions about the program, curriculum, or the industry in general. This will be your only opportunity to meet with the Instructors before the classes start.",
                  link:"https://calhoun.edu/workforce-solutions/healthcare-programs/",
                  videos:[]
                 },
                 {
                  programName:"Medical Assisting",
                  description:"The Medical Assisting program at NACC prepares students for exciting careers in a variety of health care facilities. Graduates of the program are trained to perform both administrative and clinical duties needed for employment in a clinic or physician's office. Medical Assisting is one of the fastest growing occupations in the nation, according to the U.S. Department of Labor.",
                  link:"https://calhoun.edu/workforce-solutions/healthcare-programs/clinical-medical-assistant-program/",
                  videos:[]
                 },
                 {
                  programName:"Nursing",
                  description:"The mission of the nursing program of J. F. Drake State Community and Technical College (Drake State) is to prepare graduates to practice safe, competent, patient-centered care in an increasingly complex and rapidly changing health care system.",
                  link:"https://calhoun.edu/health-sciences/nursing/",
                  videos:[]
                 },
                 {
                  programName:"Emergency Medical Services",
                  description:"The Emergency Medical Services program at NACC stands ready to prepare you for an exciting career in emergency medical services. There are three levels of emergency medical services providers in Alabama. EMTs are licensed to provide basic life support and to assist the sick and injured. Advanced EMTs provide limited Advanced Life Support (ALS) skills. Paramedics are licensed to provide all aspects of advanced life support, including cardiac monitoring, defibrillation, cardioversion, emergency pacing, administration of medications, and intubation. Paramedics are in high demand locally and across the U.S.",
                  link:"https://calhoun.edu/health-sciences/ems/",
                  videos:[]
                 },
            ]
       },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Medical Assisting", 
                  description:"The Medical Assisting Program is structured to prepare students to provide patient care in physician's offices, minor emergency centers, long-term care facilities, and other types of free-standing medical clinics.",
                  link:"https://www.drakestate.edu/academic-programs/health-sciences-technologies-programs/medical-assisting-technology",
                  videos:[]
                },
                {
                  programName:"Nursing", 
                  description:"The mission of the nursing program of J. F. Drake State Community and Technical College (Drake State) is to prepare graduates to practice safe, competent, patient-centered care in an increasingly complex and rapidly changing health care system.",
                  link:"https://www.drakestate.edu/academic-programs/health-sciences-technologies-programs/nursing",
                  videos:[]
                },
              ],
            },
            {
                 entityName:"Northeast Alabama Community College",
                 entityLogo:"nacc-logo.jpg",

                 programs:[
                  {
                    programName:"Nursing", 
                    description:"",
                    link:"https://www.nacc.edu/programs-of-study/health-science-divisionnursing",
                    videos:[]
                  },
                  {
                    programName:"Emergency Medical Services", 
                    description:"The Emergency Medical Services program at NACC stands ready to prepare you for an exciting career in emergency medical services. There are three levels of emergency medical services providers in Alabama. EMTs are licensed to provide basic life support and to assist the sick and injured. Advanced EMTs provide limited Advanced Life Support (ALS) skills. Paramedics are licensed to provide all aspects of advanced life support, including cardiac monitoring, defibrillation, cardioversion, emergency pacing, administration of medications, and intubation. Paramedics are in high demand locally and across the U.S.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/ems",
                    videos:[]
                  },
                  {
                    programName:"Medical Assistant", 
                    description:"The Medical Assisting program at NACC prepares students for exciting careers in a variety of health care facilities. Graduates of the program are trained to perform both administrative and clinical duties needed for employment in a clinic or physician's office. Medical Assisting is one of the fastest growing occupations in the nation, according to the U.S. Department of Labor.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/medical-assistant",
                    videos:[]
                  },
                 ],
                          ///////END
            },
            {
               entityName:"Northwest-Shoals Community College",
               entityLogo:"shoals-logo.jpg",

               programs:[
                {
                  programName:"Diagnostic Imaging-Radiography", 
                  description:"The Diagnostic Imaging - Radiography program at Northwest-Shoals Community College (NW-SCC) prepares students to create images through the use of x-rays that captures images used by physicians to diagnose a variety of conditions. When students complete the program, they can work as a radiologic technologist at hospitals or clinics.",
                  link:"https://www.nwscc.edu/program-of-study/health-studies/diagnostic-imaging-radiography",
                  videos:[]
                },
                {
                  programName:"Diagnostic Medical-Sonography", 
                  description:"The Diagnostic Medical Sonography (DMS) program prepares students to use medical ultrasound equipment that captures images. Medical Sonography is commonly associated with obstetrics and the use of ultrasound imaging during pregnancy, but is also used in the diagnosis and treatment of medical conditions. When students complete the program, they can work as a diagnostic medical sonographers at hospitals or clinics.",
                  link:"https://www.nwscc.edu/program-of-study/health-studies/diagnostic-imaging",
                  videos:[]
                },
                {
                  programName:"Emergency Medical Services", 
                  description:"The Division of Health Studies offers the Emergency Medical Services (EMS) program. The EMS program is designed to prepare qualified applicants in basic and advanced emergency care in clinical and in field environments. Graduates qualify for employment with fire and rescue departments, ambulance services, and industries and emergency departments within medical facilities.",
                  link:"https://www.nwscc.edu/program-of-study/health-studies/diagnostic-imaging",
                  degree:"Associate in Applied Science Degree (AAS)",
                  videos:[]
                },
                {
                  programName:"Medical Assisting Technology", 
                  description:"The Medical Assisting Technology (MAT) degree is designed to prepare students to assist the physician in providing patient care in physician's offices, minor emergency centers, long-term care facilities, and other types of freestanding medical clinics. Medical assistants are also prepared to assume administrative roles in physician's offices, including dealing with billing protocols, coding mechanisms and office transcription.",
                  link:"https://www.nwscc.edu/program-of-study/health-studies/medical-assisting-technology",
                  degree:"Associate in Applied Science Degree (AAS)",
                  videos:[]
                },
                {
                  programName:"Registered Nursing", 
                  description:"Are you interested in entering a rapidly growing career field that also rewards you through your service to others? If so, Nursing may be for you!",
                  link:"https://www.nwscc.edu/program-of-study/health-studies/nursing",
                  videos:[]
                },
                {
                  programName:"Practical Nursing", 
                  description:"Are you interested in entering a rapidly growing career field that also rewards you through your service to others? If so, Nursing may be for you!",
                  link:"https://www.nwscc.edu/program-of-study/health-studies/nursing",
                  videos:[]
                },
                {
                  programName:"Certified Nursing Assistant", 
                  description:"Are you interested in entering a rapidly growing career field that also rewards you through your service to others? If so, Nursing may be for you!",
                  link:"https://www.nwscc.edu/program-of-study/health-studies/nursing",
                  videos:[]
                },
               ]
            },                                 
            {
            entityName:"Snead Community College",
            entityLogo:"snead-logo.jpg",

            programs:[
                      {
                        programName:"Nursing (RN)", 
                        description:"Health Science programs provide the knowledge and skills needed for students seeking a career as a Registered Nurse or a Nursing Assistant. Completion of the RN program requirements provide the student with the credentials needed to test for licensure as a Registered Nurse.  Completion of the Nursing Assistant/ Home Health Aide program requirements provide the student with the credentials needed to test for Nursing Assistant certification.",
                        link:"https://snead.edu/academics/health_sciences/",
                        videos:[]
                      }, 
                      {
                        programName:"Office Administration", 
                        description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                        link:"https://snead.edu/academics/business/",
                        videos:[]
                      },
                      {
                         programName:"Biology", 
                         description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                         videos:[]
                      }, 
                      {
                         programName:"Biomedical Services", 
                         description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                         videos:[]
                      }, 
                      {
                         programName:"Chemistry", 
                         description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                         videos:[]
                      }, 
                      {
                        programName:"Environmental Science", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      }, 
                      {
                          programName:"Health Infomatics", 
                          description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                          videos:[]
                      },
                      {
                        programName:"Health Science", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      },
                      {
                        programName:"Health Services Administration", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      },
                      {
                        programName:"Nuclear Medicine Technology", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      },
                      {
                        programName:"Pre-Nursing", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      },
                      {
                        programName:"Radiologic Sciences", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      },
                      {
                        programName:"Rehabilitation Services", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      },
                      {
                        programName:"Cardio Science", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      },
                      {
                        programName:"Respiratory Therapy", 
                        description:"The Natural Science Division offers an extensive selection of courses in astronomy, biology, chemistry, geography, health, meteorology, nutrition, physical education, physical science, and physics. Courses meet prerequisite requirements for transfer to four year institutions as well as other health care curriculum.",
                         link:"https://snead.edu/academics/natural_science/",
                        videos:[]
                      }, 
           
                        ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",
              programs:[
              {
                  programName:"Dental Assisting",
                  description:"A dental assistant helps with the direct care of patients under the supervision of a dentist.",
                  link:"https://www.wallacestate.edu/programs/health-division/dental-assisting/",
                  videos:[]
                 },
                 {
                  programName:"Dental Hygiene",
                  description:"As a practicing member of the dental health team, the dental hygienist acts as an educator and motivator in maintenance of oral health and prevention of dental disease.",
                  link:"https://www.wallacestate.edu/programs/health-division/dental-hygiene/",
                  videos:[]
                 },
                 {
                  programName:"Diagnostic Imaging",
                  description:"The Diagnostic Imaging program is designed to provide qualified radiologic technologists for diagnostic imaging departments of hospitals and clinics. Radiologic technologists create images using x-rays that pass through the body.",
                  link:"https://www.wallacestate.edu/programs/health-division/diagnostic-imaging/",
                  videos:[]
                 },
                 {
                  programName:"Diagnostic Medical Sonography",
                  description:"Sonography, or ultrasonography, is the use of sound waves to generate an image for the assessment and diagnosis of various medical conditions.",
                  link:"https://www.wallacestate.edu/programs/health-division/diagnostic-medical-sonography/",
                  videos:[]
                 },
                 {
                  programName:"Emergency Medical Services",
                  description:"People’s lives often depend on the quick reaction and competent care of emergency medical technicians (EMTs) and paramedics—EMTs with additional advanced training to perform more difficult pre-hospital medical procedures. EMTs and paramedics provide vital medical attention as they care for and transport the sick or injured to a medical facility.",
                  link:"https://www.wallacestate.edu/programs/health-division/emergency-medical-services/",
                  videos:[]
                 },
                 {
                  programName:"Health Information Technology",
                  description:"The Health Information Technology Program prepares students to become health information professionals who play a vital role in making our healthcare system work. They perform the data collection and analysis that doctors, nurses, and other healthcare professionals need to do their jobs well and are a key part of quality patient care.",
                  link:"https://www.wallacestate.edu/programs/health-division/hit",
                  videos:[]
                 },
                 {
                  programName:"Medical Assistant",
                  description:"The Medical Assistant curriculum covers administrative duties including scheduling and receiving patients, preparing and maintaining medical records, performing secretarial skills, handling telephone calls and writing correspondence, serving as a liaison between the physician and other individuals, and managing practice finances. Clinical duties include preparing the patient for examination, taking patient histories and vital signs, performing first-aid and CPR, assisting the physician with examinations and treatments, performing routine laboratory procedures and diagnostic tests, preparing and administering medications as directed by the physician, and performing electrocardiograms and basic radiography.",
                  link:"https://www.wallacestate.edu/programs/health-division/medical-assistant/",
                  videos:[]
                 },
                 {
                  programName:"Medical Laboratory Technician",
                  description:"Clinical laboratory testing plays a crucial role in the detection, diagnosis, and treatment of disease.",
                  link:"https://www.wallacestate.edu/programs/health-division/clinical-laboratory-technician",
                  videos:[]
                 },
                 {
                  programName:"Occupational Therapy",
                  description:"Occupational therapy assistants work under the direction of occupational therapists to provide rehabilitative services to persons with mental, physical, emotional, or developmental impairments.",
                  link:"https://www.wallacestate.edu/programs/health-division/occupational-therapy-assistant",
                  videos:[]
                 },
                 {
                  programName:"Pharmacy Technology",
                  description:"The Pharmacy Technology curriculum prepares students to function as healthcare professionals in both retail and institutional pharmacies.",
                  link:"https://www.wallacestate.edu/programs/health-division/pharmacy-technology",
                  videos:[]
                 },
                 {
                  programName:"Physical Therapist Assistant",
                  description:"The Physical Therapist Assistant (PTA) is a skilled technical health care worker who assists the physical therapist in providing services that help improve mobility, relieve pain, and prevent or limit permanent physical disabilities of patients suffering from injuries or disease.",
                  link:"https://www.wallacestate.edu/programs/health-division/physical-therapist-assistant",
                  videos:[]
                 },
                 {
                  programName:"Polysomnographic Technologist",
                  description:"Polysomnography is a study of sleep cycles and behavior, usually done overnight in a sleep center. This study involves observing a person at sleep while continuously charting brain waves, muscle activity, breathing, eye movements, and heart rhythms.",
                  link:"https://www.wallacestate.edu/programs/health-division/polysomnographic-technologist",
                  videos:[]
                 },
                 {
                  programName:"Respiratory Therapy",
                  description:"A respiratory therapist is responsible for administering, under physician’s prescription, many types of breathing therapeutics, and utilizing specialized breathing, aerosol and humidification equipment.",
                  link:"https://www.wallacestate.edu/programs/health-division/polysomnographic-technologist",
                  videos:[]
                 },
                 {
                  programName:"Sports Medicine",
                  description:"Athletic trainers help prevent and treat injuries for people of all ages. Their clients include everyone from professional athletes to industrial workers.",
                  link:"https://www.wallacestate.edu/programs/academic-division/sports-medicine",
                  videos:[]
                 },
                 {
                  programName:"Therapeutic Massage",
                  description:"Therapeutic massage is an ancient healing art, recognized as an important modality in the holistic treatment of the body.  As a growing profession, therapeutic massage provides ongoing wellness and stress reduction for healthy individuals and enhances the healing of individuals with neuromuscular dysfunction.",
                  link:"https://www.wallacestate.edu/programs/health-division/massage-therapy",
                  videos:[]
                 },
                ]
       },
          ],
          ////world end
        },                                
        hospitality_and_tourism:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
              {
                programName:"Salon and Spa Management", 
                description:"The Salon and Spa Management program prepares cosmetologists, hairstylists, and other personal grooming specialists to manage beauty parlors, shops, and full-service or specialized salons and prepares for licensure as professional salon owners and operators. Includes instruction in cosmetic services marketing and retailing; advertising and promotion; salon management; the cosmetic and salon supply industries; hiring, supervision, and labor relations; applicable business and professional laws and regulations; professional standards and image; and customer service.",
                link:"https://catalog.bscc.edu/salon-and-spa-management-sal",
                videos:[
                           {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'-E7q3M9qAh0'
                            },
                        ]
              },
            ],

            },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Culinary Arts/Hospitality Management", 
                  description:"The Culinary Arts/Hospitality Management Program was developed to serve the emerging needs of the food service and hospitality establishments of the college's service area. This program was designed to provide degrees and certificates with core courses in nutrition, food purchasing, food preparation, cooking, meal presentation and meal service along with knowledge and skills in customer service, restaurant operations and hospitality management.",
                  link:"https://www.drakestate.edu/academic-programs/culinary-artshospitality-management",
                  videos:[]
                },
                {
                  programName:"Salon & Spa Management", 
                  description:"",
                  link:"https://www.drakestate.edu/academic-programs/salon-and-spa-management-programs",
                  videos:[]
                },
              ],
            },
            {
                 entityName:"Northeast Alabama Community College",
                 entityLogo:"nacc-logo.jpg",

                 programs:[
                   {
                      programName:"Salon & Spa Management", 
                      description:"The Salon & Spa Management program at NACC provides opportunities for licensure and entrepreneurial training in cosmetology, esthetics and therapeutic massage. We prepare our students for careers in the salon and spa industry as licensed cosmetologist, estheticians and massage therapist.",
                      link:"https://www.nacc.edu/programs-of-study/tech-programs/salon-and-spa-management",
                      videos:[]
                    },
                  ],
                          ///////END
            },
            {
               entityName:"Northwest-Shoals Community College",
               entityLogo:"shoals-logo.jpg",

               programs:[
                {
                  programName:"Salon & Spa Management", 
                  description:"Students learn to operate a full-service interactive salon and spa environment. Salon and Spa students express individual creativity by providing artistic hair, nail, and skincare services. To prepare for the workforce, students utilize the latest technology, trends, and name-brand products. Students are trained by State Board certified instructors who prepare them to take the State Board Exam following completion of their AAS degree.",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },

               ]
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",
              programs:[
              {
                  programName:"Therapeutic Massage",
                  description:"Therapeutic massage is an ancient healing art, recognized as an important modality in the holistic treatment of the body.  As a growing profession, therapeutic massage provides ongoing wellness and stress reduction for healthy individuals and enhances the healing of individuals with neuromuscular dysfunction.",
                  link:"https://www.wallacestate.edu/programs/health-division/massage-therapy/",
                  videos:[]
                 },
                 {
                  programName:"Culinary Arts",
                  description:"The Culinary Arts program at Wallace State provides the ingredients needed to build an exciting career in restaurants, food service, and the greater hospitality industry.",
                  link:"htt/ps://www.wallacestate.edu/programs/technical-division/culinary-arts/",
                  videos:[]
                 },
                ]
       },                                 
            ////entities end 
           ],
          ////world end
        },   
        modern_manufacturing:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
              {
                programName:"Machine Tool Technology", 
                description:"The world of machinists is quite different than what many might expect. With the advent of the computer numeric control machining, the greasy and noisy machines of the past have been all but replaced by more modern computer-driven machinery.",
                link:"https://www.bscc.edu/programs/career-tech/machine-tool-technology",
                videos:[]
              },
              {
                programName:"Welding", 
                description:"Welding is the most common way of permanently joining metal parts, and welders learn the characteristics and properties, such as melting points of steel, aluminum, and other commonly used metals.",
                link:"https://www.bscc.edu/programs/career-tech/welding-technology",
                videos:[
                           {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                            },
                        ]
              },
              {
                programName:"Manufactured Home Installation Training", 
                description:"The Manufactued Home Installation Training program is taught by subject matter experts, utilizing a combination of structured classroom instruction and live work opportunities, in order to prepare participants for work with Certified Manufactured Home Installers and/or to take the Alabama Manufactured Housing Commision Certified Installer exam.",
                link:"https://www.bscc.edu/programs/workforce-solutions/manufactured-home-installation-training",
                videos:[
                  {
                    videoType:'youtube',
                    link:"",
                    videoUrl:"",
                    videoId:'TPmRYt9Qkx8'
                  },
                ]
              },
              {
                programName:"MSSC", 
                description:"The Manufacturing Skill Standards Council (MSSC) is an industry-led, training, assessment and certification system focused on the core skills and knowledge needed by the nation's front-line production and material handling workers. The nationwide MSSC System, based upon industry defined and federally-endorsed standards, offers both entry-level and incumbent workers the opportunity to demonstrate that they have acquired the skills increasingly needed in the technology-intensive jobs of the 21st century.",
                link:"https://www.bscc.edu/programs/workforce-solutions/mssc",
                videos:[
                           {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                            },
                        ]
              },
          ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
              programs:[
              {
                  programName:"Industrial Maintainance",
                  description:"The Associate of Applied Science degree in Applied Technology with a major in Industrial Maintenance/Mechanical will prepare graduates for employment as entry level industrial mechanics and millwrights. Concepts covered in this program include pumps, motors, motor controls, mechanical drives, preventive/predictive maintenance concepts, hydraulics, pneumatics, prints and mechanical drawings, and related safety.",
                  link:"https://calhoun.edu/technologies/industrial-maintenance/",
                  videos:[]
                 },
                 {
                  programName:"Machine Tool Technology",
                  description:"Students will acquire specialized knowledge and skills in many areas including mathematics, print reading, physics, precision measuring instruments, cutting tools, design, prototype, and machine tools.  Graduates will have the ability to utilize their knowledge of the working properties of materials and their skill with machine tools to plan and carry out the operations needed to make machined products that meet precise specifications.",
                  link:"https://calhoun.edu/technologies/machine-tool-technology/",
                  videos:[]
                 },
                 {
                  programName:"Process Technology",
                  description:"The Process Technology program prepares individuals to monitor, operate, and maintain equipment used in the processing of raw material into marketable chemical/petrochemical products. This includes instruction in materials handling, extraction, distillation, evaporation, drying, absorption, heat transfer, instrumentation and reaction processes. It also includes chemical and fire safety, codes and standards, and general plant operations. The program emphasizes safe and efficient work practices, basic occupational skills and employ-ability skills. The content is organized into competency-based courses of instruction which specify occupational competencies which the student must successfully complete.",
                  link:"https://calhoun.edu/technologies/process-technology/",
                  videos:[]
                 },
                 {
                  programName:"EPIC-Excellence In Process Industrial Controls",
                  description:"The Excellence in Process Industrial Controls (EPIC) program at Calhoun Community College combines an Associate of Applied Science degree in Process Technology with two years of paid, related co-op work experience with a sponsor company.  Students accepted in this competitive program typically attend class at Calhoun Community College two days per week and work on-site with a local employer three days per week.",
                  link:"https://calhoun.edu/student-services/career-services/epic/",
                  videos:[]
                 },
                 {
                  programName:"Robotics/Mechatronics",
                  description:"Decades ago, robotics was science fiction, and any consideration of the types of jobs in robotics was a thing of the future. Fast forward to today and it is no longer science fiction, but rather science fact. Robotics is still somewhat a thing of the future, but it’s also very much a thing of the present with many job opportunities.  If you’re even remotely interested in this science, now is the time to get the training to enter this field.",
                  link:"https://calhoun.edu/technologies/robotics-mechatronics/",
                  videos:[]
                 },
                 {
                  programName:"Welding Technology",
                  description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                  link:"https://calhoun.edu/technologies/welding/",
                  videos:[]
                 },
                 {
                  programName:"Maintenance Boot Camps",
                  description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                  link:"https://calhoun.edu/technologies/welding/",
                  videos:[]
                 },
                 {
                  programName:"F.A.M.E.",
                  description:"The Alabama F.A.M.E. (Federation for Advanced Manufacturing Education) Advanced Manufacturing Technician Program (AMT) includes a two-year technical Associate degree that combines cutting-edge curriculum that supports advanced manufacturing technology, paid working experience, along with learning highly sought after business principles and best practices of a world class manufacturer.",
                  link:"https://calhoun.edu/student-services/career-services/fame-amt/",
                  videos:[]
                 },
                ]
            },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Engineering Design", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Engineering Design will prepare students for work in the mechanical, architectural or electronic drafting fields. Students start by changing or redrawing plans and advance to designing complete working drawings from preliminary sketches and specifications using Computer Aided Drafting Design (CAD) software systems. Additional training allows a student to calculate the strength, size and cost of materials.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/engineering-design",
                  degree:"Associate in Applied Science Degree (AAS)",
                  videos:[]
                }, 
                {
                  programName:"Electrical Technology", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Electrical Technology will prepare students in, Residential, Commercial and Industrial Wiring, Transformers, Fluid Power, National Electrical Code, Motor Controls, Programmable Logic Controls (PLCs), Ac/Dc Drives, Industrial Robotics, Technical Communications, and Solid State Devices. This will benefit those who are seeking apprenticeship and journeymen electrician certification, but also for training electricians currently employed in Advanced Manufacturing.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/electrical-technology",
                  videos:[]
                },
                {
                  programName:"Machine Tool Technology", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Machine Tool Technology will to prepare students for gainful employment in the area of precision machining. The course objectives include learning safe work habits, metallurgy, precision measurement as well as the set up and operation of machine tools that cut and shape metal. Lathes, milling machines, drill presses, saws, grinders and computer numerical control machinery are some of the equipment used in the machine tool technology curriculum.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/machine-tool-technology",
                  videos:[]
                },
                {
                  programName:"Welding Technology", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Welding is designed to teach the principles of welding as well as the nature of metals and types of gases used in welding. The welding curriculum also includes skills in welding and fusing of various metal types using an array of techniques and methods.The program is designed to provide qualified individuals the opportunity to acquire the knowledge, attitudes, and skills to obtain a certificate verifying their competency in the basic requirements of Welding Technology.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/welding",
                  videos:[
                    {
                      videoType:'youtube',
                      link:"",
                      videoUrl:"",
                      videoId:'s_Ux81GnrqY'
                    },
                  ]
                },
                {
                  programName:"Industrial Maintenance/Mechatronics", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Mechatronics is a curriculum designed to teach courses in, Industrial Wiring, Precision Measurements, Pneumatic and Hydraulics Systems, Preventive Maintenance, Industrial Automation Systems, Frequency and Mechanical Drives, and Industrial Robotics. The course delivers a workforce readiness platform for those who are seeking employment in Industrial Maintenance and Advanced Manufacturing, but also for Maintenance Technicians currently employed in the field.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/mechatronics-industrial-maintenance",
                  videos:[
                    {
                      videoType:'youtube',
                      link:"",
                      videoUrl:"",
                      videoId:'s_Ux81GnrqY'
                    },
                  ]
                },
              ],
            },
            {
                 entityName:"Northeast Alabama Community College",
                 entityLogo:"nacc-logo.jpg",

                 programs:[
                  {
                    programName:"Drafting & Design Technology", 
                    description:"Computer-aided drafting and design (also known as CADD) plays an integral role in the construction and manufacturing industries. Drafters and CAD Technicians use computer software to create technical drawings & solid models that provide specifications for buildings, tools, complex machinery & product assemblies and are typically employed in the engineering, architectural or manufacturing fields.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/drafting-and-design-technology",
                    videos:[]
                  },
                  {
                    programName:"Engineering Technician", 
                    description:"The Engineering Technician program at NACC prepares students to be the eyes, ears, hands and feet of engineers. The ENT program includes courses in electrical, programmable logic controls, mechanical, structural, materials and project management, as well as quality and productivity. By understanding the language of engineering and various industrial systems, the engineering technician can assist engineers in designing, testing, building, operating and maintaining systems in aerospace, energy, manufacturing, construction, and consulting.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/engineering-technician",
                    videos:[]
                  },
                  {
                    programName:"Industrial Electronics/Mechatronnics", 
                    description:"Companies depend on complex electronic equipment for a variety of functions. From industrial controls that automatically monitor and direct production processes on the factory floor to radar and missile control systems that provide for the national defense, electronics devices are increasingly used to boost productivity and optimize results. These complex pieces of equipment are installed, maintained, and repaired by electronics technicians.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-electronics",
                    videos:[]
                  },
                  {
                    programName:"Industrial Systems Technology", 
                    description:"Industrial Systems Technology encompasses a wide range of fields related to high-skill, high-technology, and high-wage jobs within industry.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-systems-technology",
                    videos:[]
                  },
                  {
                    programName:"Drafting & Design Technology", 
                    description:"Computer-aided drafting and design (also known as CADD) plays an integral role in the construction and manufacturing industries. Drafters and CAD Technicians use computer software to create technical drawings & solid models that provide specifications for buildings, tools, complex machinery & product assemblies and are typically employed in the engineering, architectural or manufacturing fields.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/drafting-and-design-technology",
                    videos:[]
                  },
                  {
                    programName:"Certified Production Technician", 
                    description:"Certified Production Technician (CPT): The MSSC System awards certificates to individuals who pass any of its four Production modules: Safety; Quality Practices & Measurement; Manufacturing Processes & Production; and Maintenance Awareness and a full Certified Production Technician (CPT) Certification to those who pass all four. The CPT Certification sets the quality standard for front-line workers in our nation's factories, distribution centers, warehouses, and transportation hubs.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/certified-production-technician",
                    videos:[]
                  },
                  
                 ],
                          ///////END
            },
            {
               entityName:"Northwest-Shoals Community College",
               entityLogo:"shoals-logo.jpg",

               programs:[
                {
                  programName:"Welding", 
                  description:"Welding students are trained to use electricity to design, dismantle, and construct a wide range of projects. NCCER certified instructors prepare their students to use advanced welding techniques and equipment to join, cut, bend, and manipulate metal for industrial or artistic applications. Students have the ability to become certified by the American Welding Society. Students learn to work independently and in teams in this high-skill, high-wage field to better prepare for the workforce",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Applied Engineering & Machining", 
                  description:"In Applied Engineering and Machining (Machine Shop), you will gain the skills to invent, design, create, and build the high-tech​ precision parts and tools used worldwide in everyday products. Students learn to use advanced computer-controlled technology to manufacture precise steel, aluminum, and plastic components. Machine Shop graduates enter the workforce proficient in programming and controlling industrial CNC machines to produce products from engineering blueprints and specifications.",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Federation for Advanced Manufacturing Education (FAME) Program", 
                  description:"The Alabama FAME (Federation for Advanced Manufacturing Education) Program at Northwest-Shoals Community College (NW-SCC) combines an Associate of Applied Science Degree (AAS) in Industrial Systems Technology and two years of paid industry experience. The program includes cutting-edge curriculum in Electricity, Robotics, Fluid Power, Mechanics, Welding, Machining, and Industrial Troubleshooting. Instruction on best-practices of world-class companies, including Safety Culture, Visual Workplace Organization, Lean Manufacturing, Problem Solving, and Machine Reliability is combined with paid on-the-job training and professional skills education",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Design Engineering Technology", 
                  description:"Design Engineering students learn to visualize and construct dynamic 3D models for architectural and mechanical designs and create, design, and generate a wide range of full-color prototypes, prints, and 3D computer-aided drawings. Before graduating, students will become adept at using the same advanced equipment and software as professional architects, engineers, and designers.",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Industrial Systems Technology", 
                  description:"Industrial Systems Technology students train to control robots and other automated systems through the use of programmable logic controllers (PLCs). Students also have the opportunity to use advanced injection molding equipment and learn techniques to create components used worldwide. Once students complete the program, they have the skills necessary to install, maintain, and repair a wide range of mechanical drive components as well as advanced electrical and hydraulic manufacturing systems.",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"MSSC", 
                  description:"The Manufacturing Skill Standards Council (MSSC) currently offers industry-led training certifications at Northwest-Shoals Community College.",
                  link:"https://www.nwscc.edu/workforce-training/mssc-training-certification",
                  videos:[]
                },
               ]
            },                                 
            {
            entityName:"Snead Community College",
            entityLogo:"snead-logo.jpg",

            
            programs:[
                      {
                        programName:"Aerospace Engineering", 
                        description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                        link:"https://snead.edu/academics/math/",
                        videos:[]
                      }, 
                      {
                        programName:"Civil Engineering", 
                        description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                        link:"https://snead.edu/academics/math/",
                        videos:[]
                      },
                      {
                         programName:"Materials Engineering", 
                         description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                         link:"https://snead.edu/academics/math/",
                         videos:[]
                      }, 
                      {
                         programName:"Mechanical Engineering", 
                         description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                         link:"https://snead.edu/academics/math/",
                         videos:[]
                      }, 
                      {
                         programName:"Industrial Systems Technology", 
                         description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                         link:"https://snead.edu/academics/technology/",
                         videos:[]
                      }, 
                      {
                        programName:"Electrical Engineering", 
                        description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                        link:"https://snead.edu/academics/technology/",
                        videos:[]
                      }, 
                      {
                          programName:"Industrial Engineering", 
                          description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                          link:"https://snead.edu/academics/technology/",
                          videos:[]
                      }, 
           
                     ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",
              programs:[
              {
                  programName:"Advanced Automotive Technology",
                  description:"Wallace State’s Advanced Automotive Technology programs boast a top-notch reputation with outstanding job placement rates, including a recent partnership with Mercedes-Benz U.S. International.  Through the Mercedes Tech Co-op Program, students can start a career at Mercedes during the summer, get tuition support from Mercedes while enrolled at Wallace State on the way to earning an Associate Degree in Automotive Service Technology!",
                  link:"https://www.wallacestate.edu/programs/technical-division/automotive-service-technology/",
                  videos:[]
                 },
                 {
                  programName:"Machine Tool Technology/CNC",
                  description:"Machinists use machine tools such as lathes, milling machines, and machining centers to produce precision metal parts. Although they may produce large quantities of one part, precision machinists often produce small batches or one-of-a-kind items.",
                  link:"https://www.wallacestate.edu/programs/technical-division/machine-tool-technology/",
                  videos:[]
                 },
                 {
                  programName:"Mechatronics, Electronics Technology, and Robotics",
                  description:"A new FAME program, in partnership with Toyota and other industries, allows Wallace State students to earn money while completing an associate degree in Industrial Maintenance.",
                  link:"https://www.wallacestate.edu/programs/technical-division/electronics-technology/",
                  videos:[]
                 },
                 {
                  programName:"Tool and Die Technology",
                  description:"Tool and Die Makers are among the most highly skilled workers in manufacturing and are knowledgeable in machining operations, mathematics, and blueprint reading. They must also be familiar with machining properties, such as hardness and heat tolerance, of a wide variety of metals, alloys, plastics, ceramics and other composite materials. Tool and Die Makers plan and execute the entire sequence of tool and die construction from design to final machined product.",
                  link:"https://www.wallacestate.edu/programs/technical-division/tool-and-die-technology/",
                  videos:[]
                 },
                 {
                  programName:"Welding",
                  description:"Welding, the most common way of permanently joining metal parts, is used to construct and repair parts of ships, automobiles, spacecraft and thousands of other manufactured products. Heat is applied to the pieces to be joined, melting and fusing them to form a permanent bond.",
                  link:"https://www.wallacestate.edu/programs/technical-division/welding/",
                  videos:[]
                 },
                 {
                  programName:"Engineering",
                  description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                  link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology/",
                  videos:[]
                 },
                 {
                  programName:"Engineering",
                  description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                  link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology/",
                  videos:[]
                 },
                ]
       },////entities end 
          ], ////world end
        },        
        modern_manufacturing_auto:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
              {
                programName:"Electrical Systems Technology", 
                description:"The Electrical Systems Technology program prepares individuals with the knowledge and skills for entry into the field as technicians with specialized skills. The program includes options for electrical technology, industrial plant technician, automated manufacturing, manufacturing engineering, and industrial electronics.",
                link:"https://www.bscc.edu/programs/career-tech/automated-manufacturing-technology",
                videos:[]
              },
              {
                programName:"Industrial Mechanical Maintenance Technology", 
                description:"Industrial Plant Technicians install and maintain manufacturing equipment. Technicians must be able to detect minor problems and correct them before they become larger problems. Industrial Plant Technicians use technical manuals, their understanding of the equipment, and careful observation to discover the cause of the problem.",
                link:"https://www.bscc.edu/programs/career-tech/industrial-mechanical-maintenance-technology",
                videos:[]
              },
              {
                programName:"Machine Tool Technology", 
                description:"The world of machinists is quite different than what many might expect. With the advent of the computer numeric control machining, the greasy and noisy machines of the past have been all but replaced by more modern computer-driven machinery.",
                link:"https://www.bscc.edu/programs/career-tech/machine-tool-technology",
                videos:[]
              },
              {
                programName:"Welding", 
                description:"Welding is the most common way of permanently joining metal parts, and welders learn the characteristics and properties, such as melting points of steel, aluminum, and other commonly used metals.",
                link:"https://www.bscc.edu/programs/career-tech/welding-technology",
                videos:[
                           {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                            },
                        ]
              },
              {
                programName:"MSSC", 
                description:"The Manufacturing Skill Standards Council (MSSC) is an industry-led, training, assessment and certification system focused on the core skills and knowledge needed by the nation's front-line production and material handling workers. The nationwide MSSC System, based upon industry defined and federally-endorsed standards, offers both entry-level and incumbent workers the opportunity to demonstrate that they have acquired the skills increasingly needed in the technology-intensive jobs of the 21st century.",
                link:"https://www.bscc.edu/programs/workforce-solutions/mssc",
                videos:[
                           {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                            },
                        ]
              },
          ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
              programs:[
              {
                  programName:"Automotive Technology",
                  description:"This program will teach you how to diagnose, repair and maintain the parts and systems of the modern automobile. Your training will include working on vehicles in modern service bays, using the most up-to-date diagnostic and repair tools and equipment available.",
                  link:"https://calhoun.edu/technologies/automotive-technology/",
                  videos:[]
                 },
                 {
                  programName:"Industrial Maintainance",
                  description:"The Associate of Applied Science degree in Applied Technology with a major in Industrial Maintenance/Mechanical will prepare graduates for employment as entry level industrial mechanics and millwrights. Concepts covered in this program include pumps, motors, motor controls, mechanical drives, preventive/predictive maintenance concepts, hydraulics, pneumatics, prints and mechanical drawings, and related safety.",
                  link:"https://calhoun.edu/technologies/industrial-maintenance/",
                  videos:[]
                 },
                 {
                  programName:"Robotics/Mechatronics",
                  description:"Decades ago, robotics was science fiction, and any consideration of the types of jobs in robotics was a thing of the future. Fast forward to today and it is no longer science fiction, but rather science fact. Robotics is still somewhat a thing of the future, but it’s also very much a thing of the present with many job opportunities.  If you’re even remotely interested in this science, now is the time to get the training to enter this field.",
                  link:"https://calhoun.edu/technologies/robotics-mechatronics/",
                  videos:[]
                 },
                 {
                  programName:"Welding Technology",
                  description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                  link:"https://calhoun.edu/technologies/welding/",
                  videos:[]
                 },
                 {
                  programName:"Maintenance Boot Camps",
                  description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                  link:"https://calhoun.edu/technologies/welding/",
                  videos:[]
                 },
                 {
                  programName:"F.A.M.E.",
                  description:"The Alabama F.A.M.E. (Federation for Advanced Manufacturing Education) Advanced Manufacturing Technician Program (AMT) includes a two-year technical Associate degree that combines cutting-edge curriculum that supports advanced manufacturing technology, paid working experience, along with learning highly sought after business principles and best practices of a world class manufacturer.",
                  link:"https://calhoun.edu/student-services/career-services/fame-amt/",
                  videos:[]
                 },
                ]
       },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Engineering Design", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Engineering Design will prepare students for work in the mechanical, architectural or electronic drafting fields. Students start by changing or redrawing plans and advance to designing complete working drawings from preliminary sketches and specifications using Computer Aided Drafting Design (CAD) software systems. Additional training allows a student to calculate the strength, size and cost of materials.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/engineering-design",
                  degree:"Associate in Applied Science Degree (AAS)",
                  videos:[]
                }, 
                {
                  programName:"Electrical Technology", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Electrical Technology will prepare students in, Residential, Commercial and Industrial Wiring, Transformers, Fluid Power, National Electrical Code, Motor Controls, Programmable Logic Controls (PLCs), Ac/Dc Drives, Industrial Robotics, Technical Communications, and Solid State Devices. This will benefit those who are seeking apprenticeship and journeymen electrician certification, but also for training electricians currently employed in Advanced Manufacturing.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/electrical-technology",
                  videos:[]
                },
                {
                  programName:"Machine Tool Technology", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Machine Tool Technology will to prepare students for gainful employment in the area of precision machining. The course objectives include learning safe work habits, metallurgy, precision measurement as well as the set up and operation of machine tools that cut and shape metal. Lathes, milling machines, drill presses, saws, grinders and computer numerical control machinery are some of the equipment used in the machine tool technology curriculum.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/machine-tool-technology",
                  videos:[]
                },
                {
                  programName:"Welding Technology", 
                  description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Welding is designed to teach the principles of welding as well as the nature of metals and types of gases used in welding. The welding curriculum also includes skills in welding and fusing of various metal types using an array of techniques and methods.The program is designed to provide qualified individuals the opportunity to acquire the knowledge, attitudes, and skills to obtain a certificate verifying their competency in the basic requirements of Welding Technology.",
                  link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/welding",
                  videos:[
                    {
                      videoType:'youtube',
                      link:"",
                      videoUrl:"",
                      videoId:'s_Ux81GnrqY'
                    },
                  ]
                },
              ],
            },
            {
                 entityName:"Northeast Alabama Community College",
                 entityLogo:"nacc-logo.jpg",

                 programs:[
                  {
                    programName:"Drafting & Design Technology", 
                    description:"Computer-aided drafting and design (also known as CADD) plays an integral role in the construction and manufacturing industries. Drafters and CAD Technicians use computer software to create technical drawings & solid models that provide specifications for buildings, tools, complex machinery & product assemblies and are typically employed in the engineering, architectural or manufacturing fields.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/drafting-and-design-technology",
                    videos:[]
                  },
                  {
                    programName:"Engineering Technician", 
                    description:"The Engineering Technician program at NACC prepares students to be the eyes, ears, hands and feet of engineers. The ENT program includes courses in electrical, programmable logic controls, mechanical, structural, materials and project management, as well as quality and productivity. By understanding the language of engineering and various industrial systems, the engineering technician can assist engineers in designing, testing, building, operating and maintaining systems in aerospace, energy, manufacturing, construction, and consulting.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/engineering-technician",
                    videos:[]
                  },
                  {
                    programName:"Industrial Electronics/Mechatronnics", 
                    description:"Companies depend on complex electronic equipment for a variety of functions. From industrial controls that automatically monitor and direct production processes on the factory floor to radar and missile control systems that provide for the national defense, electronics devices are increasingly used to boost productivity and optimize results. These complex pieces of equipment are installed, maintained, and repaired by electronics technicians.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-electronics",
                    videos:[]
                  },
                  {
                    programName:"Industrial Systems Technology", 
                    description:"Industrial Systems Technology encompasses a wide range of fields related to high-skill, high-technology, and high-wage jobs within industry.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-systems-technology",
                    videos:[]
                  },
                  {
                    programName:"Certified Production Technician", 
                    description:"Certified Production Technician (CPT): The MSSC System awards certificates to individuals who pass any of its four Production modules: Safety; Quality Practices & Measurement; Manufacturing Processes & Production; and Maintenance Awareness and a full Certified Production Technician (CPT) Certification to those who pass all four. The CPT Certification sets the quality standard for front-line workers in our nation's factories, distribution centers, warehouses, and transportation hubs.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/certified-production-technician",
                    videos:[]
                  },
                 ],
                          ///////END
            },
            {
               entityName:"Northwest-Shoals Community College",
               entityLogo:"shoals-logo.jpg",

               programs:[
                {
                  programName:"Welding", 
                  description:"Welding students are trained to use electricity to design, dismantle, and construct a wide range of projects. NCCER certified instructors prepare their students to use advanced welding techniques and equipment to join, cut, bend, and manipulate metal for industrial or artistic applications. Students have the ability to become certified by the American Welding Society. Students learn to work independently and in teams in this high-skill, high-wage field to better prepare for the workforce",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Applied Engineering & Machining", 
                  description:"In Applied Engineering and Machining (Machine Shop), you will gain the skills to invent, design, create, and build the high-tech​ precision parts and tools used worldwide in everyday products. Students learn to use advanced computer-controlled technology to manufacture precise steel, aluminum, and plastic components. Machine Shop graduates enter the workforce proficient in programming and controlling industrial CNC machines to produce products from engineering blueprints and specifications.",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Federation for Advanced Manufacturing Education (FAME) Program", 
                  description:"The Alabama FAME (Federation for Advanced Manufacturing Education) Program at Northwest-Shoals Community College (NW-SCC) combines an Associate of Applied Science Degree (AAS) in Industrial Systems Technology and two years of paid industry experience. The program includes cutting-edge curriculum in Electricity, Robotics, Fluid Power, Mechanics, Welding, Machining, and Industrial Troubleshooting. Instruction on best-practices of world-class companies, including Safety Culture, Visual Workplace Organization, Lean Manufacturing, Problem Solving, and Machine Reliability is combined with paid on-the-job training and professional skills education",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Design Engineering Technology", 
                  description:"Design Engineering students learn to visualize and construct dynamic 3D models for architectural and mechanical designs and create, design, and generate a wide range of full-color prototypes, prints, and 3D computer-aided drawings. Before graduating, students will become adept at using the same advanced equipment and software as professional architects, engineers, and designers.",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"Industrial Systems Technology", 
                  description:"Industrial Systems Technology students train to control robots and other automated systems through the use of programmable logic controllers (PLCs). Students also have the opportunity to use advanced injection molding equipment and learn techniques to create components used worldwide. Once students complete the program, they have the skills necessary to install, maintain, and repair a wide range of mechanical drive components as well as advanced electrical and hydraulic manufacturing systems.",
                  link:"https://www.nwscc.edu/program-of-study/career-technical",
                  videos:[]
                },
                {
                  programName:"MSSC", 
                  description:"The Manufacturing Skill Standards Council (MSSC) currently offers industry-led training certifications at Northwest-Shoals Community College.",
                  link:"https://www.nwscc.edu/workforce-training/mssc-training-certification",
                  videos:[]
                },
               ]
            },                                 
            {
            entityName:"Snead Community College",
            entityLogo:"snead-logo.jpg",

            programs:[
                      {
                        programName:"Aerospace Engineering", 
                        description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                        link:"https://snead.edu/academics/math/",
                        videos:[]
                      }, 
                      {
                        programName:"Civil Engineering", 
                        description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                        link:"https://snead.edu/academics/math/",
                        videos:[]
                      },
                      {
                         programName:"Materials Engineering", 
                         description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                         link:"https://snead.edu/academics/math/",
                         videos:[]
                      }, 
                      {
                         programName:"Mechanical Engineering", 
                         description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                         link:"https://snead.edu/academics/math/",
                         videos:[]
                      }, 
                      {
                         programName:"Industrial Systems Technology", 
                         description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                         link:"https://snead.edu/academics/technology/",
                         videos:[]
                      }, 
                      {
                        programName:"Electrical Engineering", 
                        description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                        link:"https://snead.edu/academics/technology/",
                        videos:[]
                      }, 
                      {
                          programName:"Industrial Engineering", 
                          description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                          link:"https://snead.edu/academics/technology/",
                          videos:[]
                      }, 
           
                     ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",
              programs:[
              {
                  programName:"Advanced Automotive Technology",
                  description:"Wallace State’s Advanced Automotive Technology programs boast a top-notch reputation with outstanding job placement rates, including a recent partnership with Mercedes-Benz U.S. International.  Through the Mercedes Tech Co-op Program, students can start a career at Mercedes during the summer, get tuition support from Mercedes while enrolled at Wallace State on the way to earning an Associate Degree in Automotive Service Technology!",
                  link:"https://www.wallacestate.edu/programs/technical-division/automotive-service-technology",
                  videos:[]
                 },
                 {
                  programName:"Machine Tool Technology/CNC",
                  description:"Machinists use machine tools such as lathes, milling machines, and machining centers to produce precision metal parts. Although they may produce large quantities of one part, precision machinists often produce small batches or one-of-a-kind items.",
                  link:"https://www.wallacestate.edu/programs/technical-division/machine-tool-technology",
                  videos:[]
                 },
                 {
                  programName:"Mechatronics, Electronics Technology, and Robotics",
                  description:"A new FAME program, in partnership with Toyota and other industries, allows Wallace State students to earn money while completing an associate degree in Industrial Maintenance.",
                  link:"https://www.wallacestate.edu/programs/technical-division/electronics-technology",
                  videos:[]
                 },
                 {
                  programName:"Tool and Die Technology",
                  description:"Tool and Die Makers are among the most highly skilled workers in manufacturing and are knowledgeable in machining operations, mathematics, and blueprint reading. They must also be familiar with machining properties, such as hardness and heat tolerance, of a wide variety of metals, alloys, plastics, ceramics and other composite materials. Tool and Die Makers plan and execute the entire sequence of tool and die construction from design to final machined product.",
                  link:"https://www.wallacestate.edu/programs/technical-division/tool-and-die-technology",
                  videos:[]
                 },
                 {
                  programName:"Welding",
                  description:"Welding, the most common way of permanently joining metal parts, is used to construct and repair parts of ships, automobiles, spacecraft and thousands of other manufactured products. Heat is applied to the pieces to be joined, melting and fusing them to form a permanent bond.",
                  link:"https://www.wallacestate.edu/programs/technical-division/welding",
                  videos:[]
                 },
                 {
                  programName:"Engineering",
                  description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                  link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology",
                  videos:[]
                 },
                 {
                  programName:"Engineering",
                  description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                  link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology",
                  videos:[]
                 },
                ]
       },////entities end 
          ], ////world end
        },
        law_public_safety_and_corrections:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
              {
                programName:"Office Administration & Technology", 
                description:"The Business Office Management and Technology program at Bevill State Community College offers students a broad foundation of knowledge, experience, and technical skills that can be applied in many types of fields. Students in the program have found specialized positions in sales, marketing, accounting, medical settings, law firms and banks. Because the core skills students develop are portable and applicable in numerous settings, employment opportunities are always available.",
                link:"https://www.bscc.edu/programs/career-tech/office-administration-and-technology-oat",
                videos:[
                           {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'-E7q3M9qAh0'
                            },
                        ]
              },
            ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
              programs:[
              {
                  programName:"Business & CIS",
                  description:"The Business Administration program provides a solid foundation for students preparing to enter any business-related field. The curriculum develops logical thinking and decision-making capabilities as well as career-specific skills.",
                  link:"https://calhoun.edu/business-cis/business/",
                  videos:[]
                },
                {
                  programName:"Criminal Justice",
                  description:"The Criminal Justice curriculum at Calhoun attempts to provide students with a well-rounded educational experience. Some courses, for instance, may be very theoretical, while others offer opportunities for hands-on application. Before completing their degrees, many students choose to do an internship with one of over 25 participating agencies. This gives the student a glimpse of the criminal justice system in action.",
                  link:"https://calhoun.edu/humanities-social-sciences/social-sciences/criminal-justice/",
                  videos:[]
                },
              ]
            },

            {
                 entityName:"Northeast Alabama Community College",
                 entityLogo:"nacc-logo.jpg",

                 programs:[
                  {
                    programName:"Office Administration", 
                    description:"The Office Administration Department offers an Associate in Applied Science degree in Office Administration, Office Administration Medical Office Option, and Office Administration Paralegal Option.",
                    link:"https://www.nacc.edu/programs-of-study/business-and-computer-science-division/office-administration-department",
                    videos:[]
                  },
                  {
                    programName:"Criminal Justice", 
                    description:"The Criminal Justice Program is consistent with and promotes the established mission of Northeast Alabama Community College. The mission of the Program, like that of the college, is to provide accessible quality educational opportunities, promote economic growth, and enhance the quality of life for the people of Alabama.  The Criminal Justice Program is driven to engender intellectual engagement with the students through instruction, research, application and the promotion of public service so that it correlates with the aforementioned mission statement.",
                    link:"https://www.nacc.edu/programs-of-study/social-sciences-speech-and-foreign-languages-division/criminal-justice",
                    videos:[]
                  },
                  {
                    programName:"Emergency Medical Services", 
                    description:"The Emergency Medical Services program at NACC stands ready to prepare you for an exciting career in emergency medical services. There are three levels of emergency medical services providers in Alabama. EMTs are licensed to provide basic life support and to assist the sick and injured. Advanced EMTs provide limited Advanced Life Support (ALS) skills. Paramedics are licensed to provide all aspects of advanced life support, including cardiac monitoring, defibrillation, cardioversion, emergency pacing, administration of medications, and intubation. Paramedics are in high demand locally and across the U.S.",
                    link:"https://www.nacc.edu/programs-of-study/tech-programs/ems",
                    videos:[]
                  },
                 ],
            },
                                
            {
            entityName:"Snead Community College",
            entityLogo:"snead-logo.jpg",

            programs:[
                      {
                        programName:"Behavioral Science", 
                        description:"The Social Sciences and Human Services Division offers courses in a wide variety of fields including Child Development, Criminal Justice, Economics, History, Political Science, Psychology and Sociology. Courses meet prerequisite requirements for transfer to four year institutions. In addition to transfer credit, the Child Development program has stand-alone certificate and degree programs that help prepare students for the work force.",
                        link:"https://snead.edu/academics/social-sciences/",
                        videos:[]
                      },
                      {
                        programName:"Criminal Justice", 
                        description:"The Social Sciences and Human Services Division offers courses in a wide variety of fields including Child Development, Criminal Justice, Economics, History, Political Science, Psychology and Sociology. Courses meet prerequisite requirements for transfer to four year institutions. In addition to transfer credit, the Child Development program has stand-alone certificate and degree programs that help prepare students for the work force.",
                        link:"https://snead.edu/academics/social-sciences/",
                        videos:[]
                      },
                      {
                        programName:"Political Science", 
                        description:"The Social Sciences and Human Services Division offers courses in a wide variety of fields including Child Development, Criminal Justice, Economics, History, Political Science, Psychology and Sociology. Courses meet prerequisite requirements for transfer to four year institutions. In addition to transfer credit, the Child Development program has stand-alone certificate and degree programs that help prepare students for the work force.",
                        link:"https://snead.edu/academics/social-sciences/",
                        videos:[]
                      },
           
                    ],
              },
              {
                entityName:"Wallace State Community College",
                entityLogo:"wallace-logo.jpg",
                programs:[
                {
                    programName:"Criminal Justice/Forensic Investigation/Law Enforcement",
                    description:"Police officers and detectives maintain law and order, collect evidence and information, and conduct investigations and surveillance. Forensic science technicians investigate crimes by collecting and analyzing physical evidence.",
                    link:"https://www.wallacestate.edu/programs/academic-division/criminal-justicecriminalisticslaw-enforcement/",
                    videos:[]
                   },
                   {
                    programName:"Paralegal",
                    description:"Paralegals—also called legal assistants—are assuming a growing range of tasks in the nation’s legal offices.",
                    link:"https://www.wallacestate.edu/programs/academic-division/paralegal/",
                    videos:[]
                   },
                  ]
         },////entities end 
           ], ////world end
        },          
        transportation_distribution_and_logistics:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
                     {
                     programName:"Vehicle Technology & Repair", 
                     description:"The Vehicle Technology and Repair Program (VTR) will prepare technicians for employment in new vehicle dealerships, independent repair establishments, auto body repair shops, fleet service centers, and rental organizations. Students will be educated to work on current model vehicles in this comprehensive, National Automotive Technicians Education Foundation (NATEF) certified, Automotive Service Excellence (ASE) based training program. Through classroom and lab activities, students will gain the knowledge and experience necessary to obtain an Associate in Applied Science degree and to pursue employment in vehicle repair industries.",
                     link:"https://www.bscc.edu/programs/career-tech/vehicle-technology-repair",
                     videos:[
                            {
                             videoType:'youtube',
                             link:"",
                             videoUrl:"",
                             videoId:'TPmRYt9Qkx8'
                            },
                     ]
                     },
                     {
                      programName:"Commerical Drivers' License (CDL)", 
                      description:"A commercial drivers' license (CDL) is a driver's license required for commercial operation of large, heavy or placarded hazardous material vehicles in the Untied States. Driving specialized vehicles such as buses, tank trucks, or carrying hazardous materials requires that you obtain the proper endorsements.",
                      link:"https://www.bscc.edu/programs/workforce-solutions/hamilton-truck-driver-training",
                      videos:[
                             {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                             },
                      ]
                      },
                      {
                        programName:"Mercedes Tech Program", 
                        description:"The Mercedes Technician program is focused on providing students with the fundamental skills associated with Mercedes-Benz vehicle technology. Not only do we provide the automotive basics, but we also focus on providing students with leadership skills for our production environment.",
                        link:"https://www.bscc.edu/programs/career-tech/mercedes-tech-program",
                        videos:[
                               {
                                videoType:'youtube',
                                link:"",
                                videoUrl:"",
                                videoId:'TPmRYt9Qkx8'
                               },
                        ]
                        },
                        {
                          programName:"Welding", 
                          description:"Welding is the most common way of permanently joining metal parts, and welders learn the characteristics and properties, such as melting points of steel, aluminum, and other commonly used metals.",
                          link:"https://www.bscc.edu/programs/career-tech/welding-technology",
                          videos:[
                                 {
                                  videoType:'youtube',
                                  link:"",
                                  videoUrl:"",
                                  videoId:'TPmRYt9Qkx8'
                                 },
                          ]
                          }
                    ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
              programs:[
              {
                  programName:"Automotive Technology",
                  description:"This program will teach you how to diagnose, repair and maintain the parts and systems of the modern automobile. Your training will include working on vehicles in modern service bays, using the most up-to-date diagnostic and repair tools and equipment available.",
                  link:"https://calhoun.edu/technologies/automotive-technology/",
                  videos:[]
                 },
                 {
                  programName:"Welding Technology",
                  description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                  link:"https://calhoun.edu/technologies/welding/",
                  videos:[]
                 },
                 {
                  programName:"Commerical Drivers' License (CDL)",
                  description:"Now is the perfect time to consider truck driving as a career! If you have always wanted to be a truck driver, or you are looking to transition to a new career after being in the workforce for a period of time, Calhoun Community College can provide the training and testing you need. The CDL Training class provides driver license testing information and training for drivers who wish to have a commercial driver’s license (CDL – Class A or B) and endorsements. This course will also provide information on federal requirements for the state’s standards for the licensing of commercial drivers.",
                  link:"https://calhoun.edu/workforce-solutions/cdl-logistics-training/",
                  videos:[]
                 },
                ]
            },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Automotive Technology", 
                  description:"The Automotive Service Technology Program is designed to provide the necessary educational background and practical training to repair today's automobiles and light trucks. Automotive service technicians inspect, maintain, and repair automobiles and light trucks that run on gasoline, electricity or alternative fuels such as ethanol. Automotive service technicians' and mechanics' responsibilities have evolved from simple mechanical repairs to high-level technology-related work. The increasing sophistication of automobiles requires workers who can use computerized shop equipment and work with electronic components while maintaining their skills with traditional hand tools.",
                  link:"https://www.drakestate.edu/academic-programs/automotive-service-technology",
                  videos:[ ]
                  },
                  {
                    programName:"Welding Technology", 
                    description:"The Associate in Applied Science degree in Advanced Manufacturing with a major in Welding is designed to teach the principles of welding as well as the nature of metals and types of gases used in welding. The welding curriculum also includes skills in welding and fusing of various metal types using an array of techniques and methods.The program is designed to provide qualified individuals the opportunity to acquire the knowledge, attitudes, and skills to obtain a certificate verifying their competency in the basic requirements of Welding Technology.",
                    link:"https://www.drakestate.edu/academic-programs/advanced-manufacturing-programs/welding",
                    videos:[ ]
                    }
  
                  ],
            },
            {
            entityName:"Northeast Alabama Community College",
            entityLogo:"nacc-logo.jpg",

              programs:[
                            
                            {
                                programName:"Industrial Systems Technology", 
                                description:"Industrial Systems Technology encompasses a wide range of fields related to high-skill, high-technology, and high-wage jobs within industry.",
                                link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-systems-technology",
                                videos:[]
                            },    
                            {
                              programName:"CDL - Truck Driving", 
                              description:"The CDL Truck Driving Program at Northeast Alabama Community College will prepare you to safely and effectively operate a tractor trailer and pass the commercial driver's license (CDL) exam. The 160-hour program runs for four weeks.",
                              link:"http://nacc.edu/cdl",
                              degree:"Associate in Applied Science Degree (AAS)",
                              videos:[]
                            },
              ]
            },
            {
              entityName:"Northwest-Shoals Community College",
              entityLogo:"shoals-logo.jpg",

              programs:[
                          {
                            programName:"Welding", 
                            description:"Welding students are trained to use electricity to design, dismantle, and construct a wide range of projects. NCCER certified instructors prepare their students to use advanced welding techniques and equipment to join, cut, bend, and manipulate metal for industrial or artistic applications. Students have the ability to become certified by the American Welding Society. Students learn to work independently and in teams in this high-skill, high-wage field to better prepare for the workforce",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },
                          {
                            programName:"Automotive Service Technology", 
                            description:"The Automotive Service Technology program offers students the skills to work on today's cars and trucks in a rapidly changing industry using a full systems approach. Students will learn to diagnose, repair, and fine tune a wide range of vehicles alongside an ASE Master Technician. Before graduating, students should have the knowledge needed to maintain foreign, domestic, and alternative fuel vehicles.",
                            link:"https://www.nwscc.edu/program-of-study/career-technical",
                            videos:[]
                          },                                 
                          ]
            }, 
            {
                        entityName:"Snead Community College",
                        entityLogo:"snead-logo.jpg",

                        programs:[
                          {
                            programName:"Business", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          }  
                                ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",
              programs:[
              {
                  programName:"Collision Repair",
                  description:"Auto body technicians, also often called collision repair technicians, straighten bent bodies, remove dents, and replace crumpled parts that cannot be fixed. They repair all types of vehicles, and although some work on large trucks, buses, or tractor-trailers, most work on cars and small trucks.",
                  link:"https://www.wallacestate.edu/programs/technical-division/collision-repair",
                  videos:[]
                 },
                 {
                  programName:"Diesel Technology",
                  description:"The diesel engine is the workhorse powering the nation’s trucks and buses because it delivers more power, is more efficient, and is more durable than its gasoline-burning counterpart. Diesel-powered engines also are becoming more prevalent in light vehicles, including passenger vehicles, pickups, and other work trucks.",
                  link:"https://www.wallacestate.edu/programs/technical-division/diesel-mechanics",
                  videos:[]
                 },
                 {
                  programName:"Welding",
                  description:"Welding, the most common way of permanently joining metal parts, is used to construct and repair parts of ships, automobiles, spacecraft and thousands of other manufactured products. Heat is applied to the pieces to be joined, melting and fusing them to form a permanent bond.",
                  link:"https://www.wallacestate.edu/programs/technical-division/welding",
                  videos:[]
                 },
                ]
       },
                                                ////entities end 
                                      ],
                                  ///////END
        },     
        aerospace:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
                     {
                     programName:"Electrical Systems Technology", 
                     description:"The Electrical Systems Technology program prepares individuals with the knowledge and skills for entry into the field as technicians with specialized skills. The program includes options for electrical technology, industrial plant technician, automated manufacturing, manufacturing engineering, and industrial electronics.",
                     link:"https://www.bscc.edu/programs/career-tech/automated-manufacturing-technology",
                     videos:[
                            {
                             videoType:'youtube',
                             link:"",
                             videoUrl:"",
                             videoId:'cpS_Hyo2tFU'
                            },
                     ]
                     },
                     {
                      programName:"Industrial Mechanical Maintenance Technology", 
                      description:"Industrial Plant Technicians install and maintain manufacturing equipment. Technicians must be able to detect minor problems and correct them before they become larger problems. Industrial Plant Technicians use technical manuals, their understanding of the equipment, and careful observation to discover the cause of the problem.",
                      link:"https://www.bscc.edu/programs/career-tech/industrial-mechanical-maintenance-technology",
                      videos:[
                             {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'cpS_Hyo2tFU'
                             },
                      ]
                     },
                     {
                      programName:"Machine Tool Technology", 
                      description:"The world of machinists is quite different than what many might expect. With the advent of the computer numeric control machining, the greasy and noisy machines of the past have been all but replaced by more modern computer-driven machinery.",
                      link:"https://www.bscc.edu/programs/career-tech/machine-tool-technology",
                      videos:[
                              {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'yHGXonF48FM'
                              },
                      ]
                     },
                     {
                          programName:"Welding", 
                          description:"Welding is the most common way of permanently joining metal parts, and welders learn the characteristics and properties, such as melting points of steel, aluminum, and other commonly used metals.",
                          link:"https://www.bscc.edu/programs/career-tech/welding-technology",
                          videos:[
                                 {
                                  videoType:'youtube',
                                  link:"",
                                  videoUrl:"",
                                  videoId:'TPmRYt9Qkx8'
                                 },
                          ]
                     },
                     {
                      programName:"MSSC", 
                      description:"The Manufacturing Skill Standards Council (MSSC) is an industry-led, training, assessment and certification system focused on the core skills and knowledge needed by the nation's front-line production and material handling workers. The nationwide MSSC System, based upon industry defined and federally-endorsed standards, offers both entry-level and incumbent workers the opportunity to demonstrate that they have acquired the skills increasingly needed in the technology-intensive jobs of the 21st century.",
                      link:"https://www.bscc.edu/programs/workforce-solutions/mssc",
                      videos:[
                              {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'TPmRYt9Qkx8'
                              },
                      ]
                     },
                     {
                      programName:"Computer Science", 
                      description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                      link:"https://www.bscc.edu/programs/career-tech/computer-science",
                      videos:[
                              {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'bhAUGqmyajI'
                              },
                      ]
                     },
                    ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
  
              programs:[
                       {
                       programName:"Aerospace Technology", 
                       description:"The Associate of Applied Science degree in Aerospace Technology offers a concentration in Structures and Assembly or Welding. It prepares graduates for employment in the aerospace and related industries through classroom and hands-on laboratory instruction in propulsion structures and assembly or specialized welding.",
                       link:"https://calhoun.edu/technologies/aerospace-technology/",
                       videos:[]
                       },
                       {
                        programName:"Welding Technology", 
                        description:"Welding is a career choice that is in high demand and is a skill that can take you all over the world. Welding is used in all types of manufacturing and more than 50% of all products manufactured in the U.S. require welding. Construction is also a huge market for welders. Buildings, bridges, and power grids would be impossible without welding.",
                        link:"https://calhoun.edu/technologies/welding/",
                        videos:[]
                        },
                        {
                          programName:"Design Drafting Technology", 
                          description:"The Design Drafting Technology program prepares students for employment as entry-level drafters in a high technology society. The curriculum is designed to provide students with the skill set necessary to advance as experience is gained in their profession. Students will receive training in the fundamentals of CAD drafting with introductory courses in the fields of mechanical and architectural drafting.",
                          link:"https://calhoun.edu/technologies/design-drafting-technology/",
                          videos:[]
                          },
                      ],
  
              },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Frontiers Research Program", 
                  description:"Drake State Community & Technical College is proud to have been selected by NASA's Marshall Space Flight Center to collaborate in the Moon-to-Mars Planetary Autonomous Construction Technology program. Drake State is the first community college and only historically Black community college to receive a cooperative agreement award from Marshall's CAN opportunity.",
                  link:"https://www.drakestate.edu/academic-programs/frontiers-research-program",
                  videos:[
                         {
                          videoType:'youtube',
                          link:"",
                          videoUrl:"",
                          videoId:'kveU-HYRZz8'
                         },
                  ]
                },  
              ],
            },
            {
              entityName:"Northeast Alabama Community College",
              entityLogo:"nacc-logo.jpg",

              programs:[
                          {
                            programName:"Drafting & Design Technology", 
                            description:"Computer-aided drafting and design (also known as CADD) plays an integral role in the construction and manufacturing industries. Drafters and CAD Technicians use computer software to create technical drawings & solid models that provide specifications for buildings, tools, complex machinery & product assemblies and are typically employed in the engineering, architectural or manufacturing fields.",
                            link:"https://www.nacc.edu/programs-of-study/tech-programs/drafting-and-design-technology",
                            videos:[]
                          },
                          {
                            programName:"Engineering Technician", 
                            description:"The Engineering Technician program at NACC prepares students to be the eyes, ears, hands and feet of engineers. The ENT program includes courses in electrical, programmable logic controls, mechanical, structural, materials and project management, as well as quality and productivity. By understanding the language of engineering and various industrial systems, the engineering technician can assist engineers in designing, testing, building, operating and maintaining systems in aerospace, energy, manufacturing, construction, and consulting.",
                            link:"https://www.nacc.edu/programs-of-study/tech-programs/engineering-technician",
                            videos:[]
                          },          
                          {
                            programName:"Industrial Electronics/Mechatronnics", 
                            description:"Companies depend on complex electronic equipment for a variety of functions. From industrial controls that automatically monitor and direct production processes on the factory floor to radar and missile control systems that provide for the national defense, electronics devices are increasingly used to boost productivity and optimize results. These complex pieces of equipment are installed, maintained, and repaired by electronics technicians.",
                            link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-electronics",
                            videos:[]
                          },
                          {
                            programName:"Industrial Systems Technology", 
                            description:"Industrial Systems Technology encompasses a wide range of fields related to high-skill, high-technology, and high-wage jobs within industry.",
                            link:"https://www.nacc.edu/programs-of-study/tech-programs/industrial-systems-technology",
                            videos:[]
                          },                  
                          {
                            programName:"Certified Production Technician", 
                            description:"Certified Production Technician (CPT): The MSSC System awards certificates to individuals who pass any of its four Production modules: Safety; Quality Practices & Measurement; Manufacturing Processes & Production; and Maintenance Awareness and a full Certified Production Technician (CPT) Certification to those who pass all four. The CPT Certification sets the quality standard for front-line workers in our nation's factories, distribution centers, warehouses, and transportation hubs.",
                            link:"https://www.nacc.edu/programs-of-study/tech-programs/certified-production-technician",
                            videos:[]
                          },
                ]
            },
            {
              entityName:"Northwest-Shoals Community College",
              entityLogo:"shoals-logo.jpg",

              programs:[
                      
                      {
                          programName:"Electrical Technology", 
                          description:"Electrical students will be trained to design, stage, and install commercial and residential electrical wiring for power control systems from 'rough in' to 'finish.' The will also gain the skills to supply power to a wide range of systems and structures according to the National Electrical Code. Prior to completing their degree, students will be experienced at troubleshooting electrical circuits for safe and efficient operation.",
                          link:"https://www.nwscc.edu/program-of-study/career-technical",
                          videos:[]
                      },    
                      {
                        programName:"Welding", 
                        description:"Welding students are trained to use electricity to design, dismantle, and construct a wide range of projects. NCCER certified instructors prepare their students to use advanced welding techniques and equipment to join, cut, bend, and manipulate metal for industrial or artistic applications. Students have the ability to become certified by the American Welding Society. Students learn to work independently and in teams in this high-skill, high-wage field to better prepare for the workforce",
                        link:"https://www.nwscc.edu/program-of-study/career-technical",
                        videos:[]
                      },
                      {
                        programName:"Applied Engineering & Machining", 
                        description:"In Applied Engineering and Machining (Machine Shop), you will gain the skills to invent, design, create, and build the high-tech​ precision parts and tools used worldwide in everyday products. Students learn to use advanced computer-controlled technology to manufacture precise steel, aluminum, and plastic components. Machine Shop graduates enter the workforce proficient in programming and controlling industrial CNC machines to produce products from engineering blueprints and specifications.",
                        link:"https://www.nwscc.edu/program-of-study/career-technical",
                        videos:[]
                      },
                      {
                        programName:"Aerospace & Aviation", 
                        description:"The Alabama FAME (Federation for Advanced Manufacturing Education) Program at Northwest-Shoals Community College (NW-SCC) combines an Associate of Applied Science Degree (AAS) in Industrial Systems Technology and two years of paid industry experience. The program includes cutting-edge curriculum in Electricity, Robotics, Fluid Power, Mechanics, Welding, Machining, and Industrial Troubleshooting. Instruction on best-practices of world-class companies, including Safety Culture, Visual Workplace Organization, Lean Manufacturing, Problem Solving, and Machine Reliability is combined with paid on-the-job training and professional skills education",
                        link:"https://www.nwscc.edu/program-of-study/career-technical",
                        videos:[]
                      },
                      {
                        programName:"Design Engineering Technology", 
                        description:"Design Engineering students learn to visualize and construct dynamic 3D models for architectural and mechanical designs and create, design, and generate a wide range of full-color prototypes, prints, and 3D computer-aided drawings. Before graduating, students will become adept at using the same advanced equipment and software as professional architects, engineers, and designers.",
                        link:"https://www.nwscc.edu/program-of-study/career-technical",
                        videos:[]
                      },
                      {
                        programName:"Industrial Systems Technology", 
                        description:"Industrial Systems Technology students train to control robots and other automated systems through the use of programmable logic controllers (PLCs). Students also have the opportunity to use advanced injection molding equipment and learn techniques to create components used worldwide. Once students complete the program, they have the skills necessary to install, maintain, and repair a wide range of mechanical drive components as well as advanced electrical and hydraulic manufacturing systems.",
                        link:"https://www.nwscc.edu/program-of-study/career-technical",
                        videos:[]
                      },
                      {
                        programName:"MSSC", 
                        description:"The Manufacturing Skill Standards Council (MSSC) currently offers industry-led training certifications at Northwest-Shoals Community College.",
                        link:"https://www.nwscc.edu/workforce-training/mssc-training-certification",
                        videos:[]
                      },
                    ],
                  ///////END
            },                                 
            {
              entityName:"Snead Community College",
              entityLogo:"snead-logo.jpg",

              programs:[
                          {
                            programName:"Airframe Technology", 
                            description:"The Airframe Technology program provides students with the knowledge and skills needed to take the Federal Aviation Administration (FAA) written, oral, and practical examinations required for certification as an aviation maintenance technician with an airframe endorsement.",
                            link:"",
                            videos:[]
                          }, 
                          {
                            programName:"Power Plant Technology", 
                            description:"The Power Plant Technology program certification gives aviation maintenance professionals the additional credential and provides expanded employment opportunities in the aviation industry, leading to higher earnings and greater career possibilities. The Aviation College at Snead State provides the industry-relevant training, so students can quickly earn this important FAA certification and begin a lifelong career in the high-demand, high-income aviation industry.",
                            link:"",
                            videos:[]
                          },
                          {
                            programName:"Aerospace Engineering", 
                            description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                            link:"",
                            videos:[]
                          },
                          {
                            programName:"Materials Engineering", 
                            description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                            link:"",
                            videos:[]
                          },
                          {
                            programName:"Mechanical Engineering", 
                            description:"The Math Department is dedicated to teaching, learning, and exploring mathematics and its applications. The department offers courses leading to an Associate of Science degree in Mathematics or leading to an Associate of Science degree in Engineering. Both of these programs provide the basic preparation leading to science-related careers. The Mathematics degree also provides basic preparation for those interested in a career of teaching mathematics.",
                            link:"",
                            videos:[]
                          },   
                        ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",

              programs:[
                          {
                            programName:"Aviation/Flight Technology", 
                            description:"Wallace State can help you begin an exciting and rewarding career as a commercial airplane or helicopter pilot. The Aviation/Flight Technology program provides training for professional or recreational pilots. Additional certificates and add-on ratings may also be obtained through the program.",
                            link:"https://www.wallacestate.edu/programs/technical-division/aviationflight-technology",
                            videos:[]
                          }, 
                          {
                            programName:"Welding", 
                            description:"Welding, the most common way of permanently joining metal parts, is used to construct and repair parts of ships, automobiles, spacecraft and thousands of other manufactured products. Heat is applied to the pieces to be joined, melting and fusing them to form a permanent bond.",
                            link:"https://www.wallacestate.edu/programs/technical-division/welding",
                            videos:[]
                          },
                          {
                            programName:"Geospatial", 
                            description:"The Wallace State Geospatial Technology programs prepare students for entry level positions within the in-demand field of Geographic Information Systems.  Individuals will create and update maps and charts for regional planning, education, emergency response, and other purposes using a variety of data.  Do you enjoy maps and geography?  Does being on the forefront of technology excite you? Do you have solid computer and communication skills?  Consider a career in GIS!",
                            link:"https://www.wallacestate.edu/programs/technical-division/gis",
                            videos:[]
                          },
                          {
                            programName:"Engineering ", 
                            description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                            link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology",
                            videos:[]
                          },
                          {
                            programName:"Computer Science", 
                            description:"The Computer Science Program is designed to prepare students for employment in industry or business, with emphasis on the small business computer environment. The program offers four options: Cyber Security/Computer Forensic Technology, Programming, Software Support (Help Desk), and Internet/Networking Technology.",
                            link:"https://www.wallacestate.edu/programs/academic-division/computer-science",
                            videos:[]
                          },
                             
                        ],
            },////entities end 
           ],///////END
        },////world end
        business_and_finance:{
          pathwayEntities:[
            {
              entityName:"Bevill State Community College",
              entityLogo:"bevill-logo.jpg",

              programs:[
                      {
                      programName:"Office Administration & Technology", 
                      description:"The Business Office Management and Technology program at Bevill State Community College offers students a broad foundation of knowledge, experience, and technical skills that can be applied in many types of fields. Students in the program have found specialized positions in sales, marketing, accounting, medical settings, law firms and banks. Because the core skills students develop are portable and applicable in numerous settings, employment opportunities are always available.",
                      link:"https://www.bscc.edu/programs/career-tech/office-administration-and-technology-oat",
                      videos:[
                              {
                              videoType:'youtube',
                              link:"",
                              videoUrl:"",
                              videoId:'-E7q3M9qAh0'
                              },
                      ]
                      },
                      ],
            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"bevill-logo.jpg",

              programs:[
                      {
                      programName:"Business & CIS", 
                      description:"The Business Administration program provides a solid foundation for students preparing to enter any business-related field. The curriculum develops logical thinking and decision-making capabilities as well as career-specific skills.",
                      link:"https://calhoun.edu/business-cis/business/",
                      videos:[],
                      }
                ],
            },

            {
              entityName:"Northeast Alabama Community College",
              entityLogo:"nacc-logo.jpg",

              programs:[
                      
                      {
                          programName:"Business", 
                          description:"The Business Department offers an Associate in Science degree with focuses in Business, Business Education, and Economics, which is part of the College Transfer Program. The division also offers an Associate in Applied Science degree in Business Management and Supervision. We offer courses in Accounting, Economics, Statistics, Management, Finance, Retailing, Marketing, and Salesmanship.",
                          link:"https://www.nacc.edu/programs-of-study/business-and-computer-science-division/business-department",
                          videos:[]
                      },    
                      {
                        programName:"Office Administration", 
                        description:"The Office Administration Department offers an Associate in Applied Science degree in Office Administration, Office Administration Medical Office Option, and Office Administration Paralegal Option.",
                        link:"https://www.nacc.edu/programs-of-study/business-and-computer-science-division/office-administration-department",
                        videos:[]
                      }
                      ],
                  ///////END
            },
            {
              entityName:"Northwest-Shoals Community College",
              entityLogo:"shoals-logo.jpg",

              programs:[
                          {
                            programName:"Accounting & Business", 
                            description:"The Accounting Technology Associate in Occupational Technology Degree and Career Certificate is designed for students that desire to seek immediate employment in the field of finance and accounting and is generally intended to ​meet the needs of students that do not intend to transfer to senior institutions of study. However, with the Associate in Occupational Technology (AOT) degree, many of the completed courses are transferable. A career certificate is also available. Accounting graduates might find a job as a Bookkeeper, Payroll Clerk, Accounts Payable Clerk, Staff Accountant, Tax Accountant and much more!",
                            link:"https://www.nwscc.edu/program-of-study/academics/business-computer-information-systems",
                            videos:[]
                          },
                          {
                            programName:"Business Office Management", 
                            description:"The Business Office Management program at Northwest-Shoals Community College (NW-SCC) prepares students for a career in a professional office ​environment. As the business office relies increasingly on technology, organizations need well-trained, capable individuals to ensure that daily tasks are handled efficiently and effectively. In the Business Office Management program, students will learn the technical and interpersonal skills that will make them key players in day-to-day operations.",
                            link:"https://www.nwscc.edu/program-of-study/academics/business-computer-information-systems",
                            videos:[]
                          },          
                          {
                            programName:"Business Administration", 
                            description:"If you have an interest to pursue Business Administration, you can pursue an Associate Degree (A.A. or A.S.) at NW-SCC. An Associate Degree is awarded to a student completing a planned university parallel program designed to meet the requirements of the first two years of a Bachelor of Arts Degree or a Bachelor of Science Degree.",
                            link:"https://www.nwscc.edu/program-of-study/academics/business-computer-information-systems",
                            videos:[]
                          },
                ]
            },                                
            {
                entityName:"Snead Community College",
                entityLogo:"snead-logo.jpg",

                programs:[
                          {
                            programName:"Applied Business", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          }, 
                          {
                            programName:"Office Administration", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          },
                          {
                            programName:"Agricultural Business Economics", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          },
                          {
                            programName:"Apparel and Textiles", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          },
                          {
                            programName:"Business", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          },
                          {
                            programName:"Business Education", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          },
                          {
                            programName:"Economics", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          },
                          {
                            programName:"Public Administration", 
                            description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                            link:"https://snead.edu/academics/business/",
                            videos:[]
                          },  
                        ],
            },
            {
              entityName:"Wallace State Community College",
              entityLogo:"wallace-logo.jpg",

              programs:[
                      {
                      programName:"Business Administration", 
                      description:"You need technical skills, superior leadership qualities, and a college degree to compete in today’s fast-paced, global business environment. The Business Department at WSCC is comprised of three program areas: Business Administration, Business Management, and Business Education. Within these areas, you may choose from a variety of programs or certificates designed to help you achieve your career goal.",
                      link:"https://www.wallacestate.edu/programs/academic-division/business",
                      videos:[]
                      },
                      ],
            },////entities end 
          ], ////world end
        },
        communications_media_and_marketing:{
          pathwayEntities:[  
                            {
                              entityName:"Calhoun Community College",
                              entityLogo:"calhoun-logo.jpg",

                              programs:[
                                      {
                                      programName:"Visual Communications", 
                                      description:"In recent years, the computer has become an important tool for the designer. Students in the Computer Graphics Program have access to a computer lab equipped with the latest industry standard hardware and software. Courses are designed to teach the creative application of these programs to projects that would be encountered in the workplace. Both individual and team approaches to problem solving are practiced.",
                                      link:"https://calhoun.edu/humanities-social-sciences/fine-arts/computer-graphics/",
                                      videos:[]
                                      },
                                      ],
                            },                              
                            {
                              entityName:"Snead Community College",
                              entityLogo:"snead-logo.jpg",


                              programs:[
                                {
                                  programName:"Communication Studies/Speech", 
                                  description:"The Humanities program introduces students to various religious traditions, the study of philosophy and ethics, the craft of public speaking and communication, and the field of mass communication.",
                                  link:"https://snead.edu/academics/english-humanities-language/",
                                  videos:[]
                                },
                                {
                                  programName:"Journalism", 
                                  description:"The Humanities program introduces students to various religious traditions, the study of philosophy and ethics, the craft of public speaking and communication, and the field of mass communication.",
                                  link:"https://snead.edu/academics/english-humanities-language/",
                                  videos:[]
                                },
                                {
                                  programName:"Public Relations", 
                                  description:"The Humanities program introduces students to various religious traditions, the study of philosophy and ethics, the craft of public speaking and communication, and the field of mass communication.",
                                  link:"https://snead.edu/academics/english-humanities-language/",
                                  videos:[]
                                },
                                {
                                  programName:"Art History", 
                                  description:"The Fine Arts Division at Snead State Community College provides accessible, inclusive educational and cultural opportunities for students and citizens of Alabama through quality instruction and innovative courses.  The College encourages the development of excellence in a broad range of artistic expressions.",
                                  link:"https://snead.edu/academics/fine_arts/",
                                  videos:[]
                                }, 
                                {
                                  programName:"Art Studio", 
                                  description:"The Fine Arts Division at Snead State Community College provides accessible, inclusive educational and cultural opportunities for students and citizens of Alabama through quality instruction and innovative courses.  The College encourages the development of excellence in a broad range of artistic expressions.",
                                  link:"https://snead.edu/academics/fine_arts/",
                                  videos:[]
                                },
                                {
                                  programName:"Music", 
                                  description:"The Fine Arts Division at Snead State Community College provides accessible, inclusive educational and cultural opportunities for students and citizens of Alabama through quality instruction and innovative courses.  The College encourages the development of excellence in a broad range of artistic expressions.",
                                  link:"https://snead.edu/academics/fine_arts/",
                                  videos:[]
                                },
                                {
                                  programName:"Applied Business", 
                                  description:"The Business Division at Snead State Community College is an integral part of the comprehensive instructional program. The course offerings provide students a variety of opportunities to satisfy their educational, career, and personal enrichment needs. Satisfactory completion of academic courses prepares students to transfer to senior institutions where they can further their educational attainment. Technical courses and programs of study prepare students for entry into the workplace. Courses are also available to individuals who desire personal and professional enrichment.",
                                  link:"https://snead.edu/academics/business/",
                                  videos:[]
                                },
                                 
                              ],
                            },
                            {
                              entityName:"Wallace State Community College",
                              entityLogo:"wallace-logo.jpg",

                              programs:[
                                      {
                                      programName:"Graphic Art & Design", 
                                      description:"Wallace State’s Graphic Art & Design program is designed for students who seek to prepare for careers in the creative economy as highly skilled graphic designers or commercial artists.",
                                      link:"https://www.wallacestate.edu/programs/academic-division/artvisual-communications",
                                      videos:[]
                                      },
                                      ],
                            },                              
                            
                            ////entities end 
                          ],
                          ///////END
         },
        cybersecurity_and_information_technology:{
          pathwayEntities:[
            {
            entityName:"Bevill State Community College",
            entityLogo:"bevill-logo.jpg",

            programs:[
                     {
                     programName:"Computer Science", 
                     description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                     link:"https://www.bscc.edu/programs/career-tech/computer-science",
                     videos:[
                            {
                             videoType:'youtube',
                             link:"",
                             videoUrl:"",
                             videoId:'-E7q3M9qAh0'
                            },
                     ]
                     }
                    ],

            },
            {
              entityName:"Calhoun Community College",
              entityLogo:"calhoun-logo.jpg",
  
              programs:[
                       {
                       programName:"Computer Science", 
                       description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                       link:"https://calhoun.edu/business-cis/computer-information-systems/",
                       videos:[]
                       },
                       {
                        programName:"Microsoft Certified Training Center", 
                        description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                        link:"https://calhoun.edu/business-cis/computer-information-systems/",
                        videos:[]
                        },
                        {
                         programName:"Information Technology Certifications (CompTIA A+, Network+, Security+, Linux+, Cisco Certified Network Assoicate (CCNA), Vmware VCP-DCV)", 
                         description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                         link:"https://calhoun.edu/business-cis/computer-information-systems/",
                         videos:[]
                         },
                         {
                          programName:"Center for Cybersecurity Education", 
                          description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                          link:"https://calhoun.edu/business-cis/center-for-cybersecurity-education/",
                          videos:[]
                          }
                      ],
  
            },
            {
              entityName:"Drake State Technical & Community College",
              entityLogo:"drake-logo.jpg",

              programs:[
                {
                  programName:"Computer Information Systems", 
                  description:"Graduates in computer science have a variety of vocational opportunities, including data entry specialists, computer support specialists, computer programmers, computer system analysts, network analysts, network administrators, computer and information systems managers, and engineers in computer software and hardware.",
                  link:"https://www.drakestate.edu/academic-programs/computer-information-systems-technology",
                  videos:[]
                },
                  
              ],
              },
              {
                entityName:"Northeast Alabama Community College",
                entityLogo:"nacc-logo.jpg",

                programs:[
                        
                        {
                            programName:"Computer Science", 
                            description:"The Computer Information Systems Department offers an Associate in Science degree, which is part of the College Transfer Program and an Associate in Applied Science degree.",
                            link:"https://www.nacc.edu/programs-of-study/business-and-computer-science-division/computer-science-department",
                            videos:[]
                        },    
                       
                        ],
                    ///////END
              },
                {
                  entityName:"Northwest-Shoals Community College",
                  entityLogo:"shoals-logo.jpg",

                  programs:[
                              {
                                programName:"Computer Information Systems Technology", 
                                description:"",
                                link:"https://www.nwscc.edu/program-of-study/academics/computer-information-systems",
                                videos:[]
                              },
                              {
                                programName:"Design Engineering Technology", 
                                description:"Design Engineering students learn to visualize and construct dynamic 3D models for architectural and mechanical designs and create, design, and generate a wide range of full-color prototypes, prints, and 3D computer-aided drawings. Before graduating, students will become adept at using the same advanced equipment and software as professional architects, engineers, and designers.",
                                link:"https://www.nwscc.edu/program-of-study/career-technical",
                                videos:[]
                              },          
                            ]
                },                                   
                {
                  entityName:"Snead Community College",
                  entityLogo:"snead-logo.jpg",

                  programs:[
                                {
                                  programName:"Computer Science Technology/Computer Science", 
                                  description:"The Technology Division offers Associates in Applied Science degrees and certificates in Computer Science Technology and Electronic Engineering Technology. Students learn practical skills enabling them to go directly to work in high-tech sectors of local business and industry after graduation",
                                  link:"https://snead.edu/academics/technology/",
                                  videos:[]
                                }            
                            ]
                },
                {
                  entityName:"Wallace State Community College",
                  entityLogo:"wallace-logo.jpg",

                  programs:[
                                {
                                  programName:"Computer Science", 
                                  description:"The Computer Science Program is designed to prepare students for employment in industry or business, with emphasis on the small business computer environment. The program offers four options: Cyber Security/Computer Forensic Technology, Programming, Software Support (Help Desk), and Internet/Networking Technology.",
                                  link:"https://www.wallacestate.edu/programs/academic-division/computer-science",
                                  videos:[]
                                },
                                {
                                  programName:"Geospatial ", 
                                  description:"The Wallace State Geospatial Technology programs prepare students for entry level positions within the in-demand field of Geographic Information Systems.  Individuals will create and update maps and charts for regional planning, education, emergency response, and other purposes using a variety of data.  Do you enjoy maps and geography?  Does being on the forefront of technology excite you? Do you have solid computer and communication skills?  Consider a career in GIS! ",
                                  link:"https://www.wallacestate.edu/programs/technical-division/gis",
                                  videos:[]
                                },
                                {
                                  programName:"Engineering", 
                                  description:"Engineering technicians prepare technical drawings and plans used by production and construction workers to build everything from manufactured products such as toys, toasters, industrial machinery, and spacecraft to structures such as houses, office buildings, and oil and gas pipelines. Engineering technicians’ drawings provide visual guidelines, show the technical details of the products and structures, and specify dimensions, materials, and procedures. Most engineering technicians use computer-aided design (CAD) systems to prepare drawings.",
                                  link:"https://www.wallacestate.edu/programs/technical-division/engineering-technology",
                                  videos:[]
                                }  

                            ]
                },
                                                ////entities end 
                                      ],
                                  ///////END
        },
          ////world end
      }     
       /*********************** */
  var NCTEJW_Programs_Info={ 
    rightImageSrc:"joco-works-logo",

    agriculture:[
      {
        employername:'North Carolina Community College System',
        employersite:"",
        multilink:false,
        employerLogo:"ncc-logo.jpg",
        employerp1:"The North Carolina Community College System is a statewide network of 58 public community colleges. The system enrolls over 500,000 students annually. It also provides the North Carolina Learning Object Repository as a central location to manage, collect, contribute, and share digital learning resources for use in traditional or distance learning environments.",
        employerp2:"",
        employerp3:"",

        

        links:[
   
        ],
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/13bvhiV6AM4",videoId:'13bvhiV6AM4'
        }]        }, 
     
  ],
    biotechnology:[
      {
        employername:'Johnston Community College',
        multilink:true,
        employersite:"",
        employerLogo:"jcc-logo.jpg",
        employerp1:"Biotechnology is best defined as the use of living systems and organisms to develop or make useful products.  Here at Johnston Community College, it is our goal to train tomorrow's workforce in the biotechnology field.  Within Johnston County, there are major biomanufacturing and pharmaceutical industries; including Grifols, and Novo Nordisk.",
        employerp2:"jobs in the biotechnology sector. We offer a variety of courses and an associate degree curriculum program to meet the growing needs of our diverse community. Our state-of-the-art facilities and highly skilled faculty have made our students feel as though they are working in the biotechnology industry while in the classroom. We have been featured in magazines, television specials, online articles, and newspapers across the state. We have national and international visitors here every year to meet with us about the training we provide.",
        employerp3:"",
        links:[
          {title:"Biotechnology Camp " 
              ,url:"https://www.johnstoncc.edu/summercamp/camp/biotech.aspx"
              },
              {title:"Bioprocess Tech AAS" 
              ,url:"https://www.johnstoncc.edu/programs/industrial/bioprocess-tech/degree.aspx"
              },
              {title:"BioWork" 
              ,url:"https://www.johnstoncc.edu/programs/industrial/bioprocess-tech/biowork.aspx"
              },
              {title:"ECU" 
              ,url:"https://www.johnstoncc.edu/programs/industrial/bioprocess-tech/ecuOption.aspx"
              },
              {title:"GRANT" 
              ,url:"https://www.johnstoncc.edu/news/nsf-grant.aspx"
              },
        ],
        images:[],
        videos:[{
          videoType:'youtube',link:"",videoId:'ECAHTgy6tRw'
        },
        {
          videoType:'youtube',link:"",videoId:'g1RtkO4bQwk'
        },
        {
          videoType:'youtube',link:"",videoId:'ECAHTgy6tRw'
        }],
      },
      {
        employername:'Career & College Promise',
        multilink:true,
        employersite:"",
        employerLogo:"jcc-promise-logo.jpg",
        employerp1:"Career & College Promise (CCP) allows eligible high school students to enroll in college classes at Johnston Community College (JCC). CCP offers high school students options to pursue educational and career goals using a rigorous yet supportive structure.",
        employerp2:"",
        employerp3:"",
        links:[
          {title:"Learn More" 
              ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/CCP flier_JOCOWORKS2021.pdf"
              },
              {title:"Learn More" 
              ,url:"https://www.johnstoncc.edu/programs/career-and-college-promise/"
              },
              {title:"CCP Freshman/Sophomore Eligibility" 
              ,url:"https://www.johnstoncc.edu/programs/career-and-college-promise/9th10thGrade.aspx"
              },

        ],
        images:[],
        videos:[{
          videoType:'youtube',link:"",videoId:'ECAHTgy6tRw'
        },
        {
          videoType:'youtube',link:"",videoId:'g1RtkO4bQwk'
        }],
      },
      {
        employername:'North Carolina Biotechnology Center',
        multilink:true,
        employersite:"https://www.cacc.edu/admissions/programs/associate-in-applied-science/",
        employerLogo:"ncbc-logo.jpg",
        employerp1:"Life sciences are transforming the world. Vaccines prevent disease. Gene therapies and precision health data cure them. Advances in agriculture feed the planet. Here in North Carolina, NCBiotech catalyzes the many different transformations that are improving the quality of life around the world. We connect companies with university researchers and introduce entrepreneurs to potential funders. Transformation even happens on an individual level as job seekers find their dream opportunity in the life sciences field.",
        employerp2:"North Carolina is leading the push toward better healthcare, faster diagnostics and more sustainable food production all the while, creating jobs right here in our state. This economic transformation is why the General Assembly has funded us for three decades, and continues its commitment today.",
        employerp3:"",
        links:[
          {title:"DISCUSS" 
              ,url:"https://www.ncbiotech.org/transforming-life-sciences/sectors-attention/north-carolina-biopharmaceutical-manufacturing"
              },
              {title:"Biopharma Jobs" 
              ,url:"https://biojobshub.wraltechwire.com/"
              },
             
        ],
        images:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/kcberrinvEY",videoId:'8Mwlhg8tpD0'
        }],
      },
      {
        employername:'North Carolina State University',
        multilink:false,
        employersite:"https://www.btec.ncsu.edu/about/tour.php",
        employerLogo:"ncsu-logo.jpg",
        employerp1:"NC State University began as a land-grant institution grounded in agriculture and engineering. Today, we’re a leading public research university that excels across disciplines. NC State is a powerhouse in science, technology, engineering and math. We lead in agriculture, education, textiles, business and natural resources.",
        employerp2:"NC State is a powerhouse in science, technology, engineering and math. We lead in agriculture, education, textiles, business and natural resources. We’re at the forefront of teaching and research in design, the humanities and the social sciences. And we’re home to one of the planet’s best colleges of veterinary medicine.",
        employerp3:"Our more than 36,000 undergraduate and graduate students learn by doing. They pursue research and start new companies. They forge connections with top employers and serve local and global communities. And they enjoy an outstanding return on investment.",
        links:[
       
             
        ],
        images:[],
        videos:[],
      },
      {
        employername:'NCCC BioNetwork',
        multilink:false,
        employersite:"https://www.ncbionetwork.org/educational-resources/videos/bioprocessing-join-us-edge",
        employerLogo:"nccc-bio-logo.jpg",
        employerp1:"BioNetwork provides high-quality economic and workforce development for the biotechnology and life science industries across North Carolina through education, training, and laboratory resources. BioNetwork also supports the future biotechnology and life science workforce through teacher training and outreach.",
        links:[
          {title:"DISCUSS" 
              ,url:"https://www.ncbiotech.org/transforming-life-sciences/sectors-attention/north-carolina-biopharmaceutical-manufacturing"
              },
              {title:"how many" 
              ,url:"https://biojobshub.wraltechwire.com/"
              },
             
        ],
        images:[],
        videos:[],
      },
      {
        employername:'ApprenticeshipNC',
        multilink:false,
        employersite:"https://www.apprenticeshipnc.com/apprentices/information-k-12-educators",
        employerLogo:"nc-apprentice-logo.jpg",
        employerp1:"Apprenticeship can play a valuable role in preparing students for future careers in a competitive economy. Students can begin preparing for a career pathway that includes apprenticeship as early as middle school. The trajectory can be conceptualized in three phases: Career Awareness, Career Exploration and Career Preparation.",
        links:[
          {title:"DISCUSS" 
              ,url:"https://www.ncbiotech.org/transforming-life-sciences/sectors-attention/north-carolina-biopharmaceutical-manufacturing"
              },
           ],
        images:[],
        videos:[],
      },
      {
        employername:'East Carolina University',
        multilink:false,
        employersite:"http://catalog.ecu.edu/preview_program.php?catoid=7&poid=1634&returnto=443",
        employerLogo:"east-carolina-logo.jpg",
        employerp1:"Learn about the industrial technology program that is accredited by the Association of Technology, Management, and Applied Engineering.",
        links:[
          {title:"DISCUSS" 
              ,url:"https://www.ncbiotech.org/transforming-life-sciences/sectors-attention/north-carolina-biopharmaceutical-manufacturing"
              },
           ],
        images:[],
        videos:[],
      },
      {
        employername:'NC Central University',
        multilink:false,
        employersite:"https://www.nccu.edu/research/brite",
        employerLogo:"nc-central-logo.jpg",
        employerp1:"The Biomanufacturing Research Institute and Technology Enterprise, or BRITE, meets the changing workforce needs of the North Carolina life science industries and clinical research sectors in support of job creation and economic growth. ",
        links:[
          {title:"DISCUSS" 
              ,url:"https://www.ncbiotech.org/transforming-life-sciences/sectors-attention/north-carolina-biopharmaceutical-manufacturing"
              },
           ],
        images:[],
        videos:[],
      },
  ],
    construction:[
      /*{
        employername:'MGM Chamber',
        employersite:"https://www.montgomerychamber.com/",
        employerLogo:"mont-chamber.jpg",
        employerp1:"The Montgomery Area Chamber of Commerce catalyzes business and community leadership to improve the economic prosperity & quality of place of Montgomery and the river region. The Chamber is a fully-integrated economic development organization, dedicated to both job creation and job preservation. As a member-based business organization, we work hard to grow markets for our members, grow the River Region’s economy, and increase the quality of life for all.",
        employerp2:"",
        employerp3:"",
        images:[],
        videos:[{              videoType:'youtube',videoUrl:"https://youtu.be/4tknKfIy3fg",videoId:'4tknKfIy3fg'
      }
  
    
  ],
  },*/
      {
        employername:'Johnston Community College',
        employersite:"https://www.trenholmstate.edu/programs/technical/engineering-technologiesbr-automotive-advanced-manufacturing/",
        multilink:true,
        employerLogo:"jcc-logo.jpg",
        employerp1:"Johnston Community College has awarded associate degrees, certificates, and diplomas since 1969 to students pursuing college transfer, occupational, technical, and continuing education opportunities.",
        employerp2:"A member of the North Carolina Community College System, JCC serves more than 13,000 students annually in its credit and non-credit programs.",
        employerp3:"",
        images:[],
        links:[
              {title:"Learn More" 
              ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/Expo_JoCoWorks2021.pdf"
              },
              {title:"HVAC Program" 
          ,url:"https://www.johnstoncc.edu/programs/construction/airconditioning-heating-refrigeration/index.aspx"
          },
          {title:"Applied Engineering" 
          ,url:"https://www.johnstoncc.edu/programs/engineering-technologies/applied-engineering/index.aspx"
          },
             
        ],
        videos:[
          {
            videoType:'youtube',link:"https://youtu.be/t55KFfekYi4",videoId:'t55KFfekYi4'
          },
      ]
      },
      
 
  ],
   healthcare:[
    {
      employername:'Johnston Community College',
      employersite:"https://www.trenholmstate.edu/programs/technical/engineering-technologiesbr-automotive-advanced-manufacturing/",
      multilink:true,
      employerLogo:"jcc-logo.jpg",
      employerp1:"Johnston Community College has awarded associate degrees, certificates, and diplomas since 1969 to students pursuing college transfer, occupational, technical, and continuing education opportunities.",
      employerp2:"JCC in Smithfield, North Carolina, is located 30 miles east of Raleigh near the junction of I-95 and US 70. The 175-acre main campus boasts state-of-the-art facilities, picturesque pines, colorful azaleas and scenic ponds and offers some 35 occupational programs of study as well as numerous continuing education courses.",
      employerp3:"A member of the North Carolina Community College System, JCC serves more than 13,000 students annually in its credit and non-credit programs.",
      images:[],
      links:[
       
            {title:"Nursing Degree" 
            ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/JCC+Associate+Degree+Nursing+Flyer.pdf"
            },
            {title:"Medical Assisting" 
            ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/Medical+Assisting+Flyer.pdf"
            },
            {title:"Visit Website" 
            ,url:"https://www.johnstoncc.edu/"
            },
            {title:"Health Sciences" 
            ,url:"https://www.johnstoncc.edu/programs/health-sciences/"
            },
            {title:"Learn More" 
            ,url:"https://www.johnstoncc.edu/programs/health-sciences/medical-assisting/index.aspx"
            },


      ],
      videos:[{
        videoType:'youtube',link:"https://youtu.be/NKysyVN2ajk",videoId:'zTGLFpBC7sg'
      },
      {
        videoType:'vimeo',videoUrl:"https://vimeo.com/449841115",videoId:'449841115'
      },

    ]
    },

    {
      employername:'JOCO Works',
      employersite:"https://www.jocoworks.net/health-care.html",
      multilink:false,
      employerLogo:"joco-works-logo.jpg",
      employerp1:"JOCO WORKS, presented by Novo Nordisk, is an industry-led collaborative supported by education, business, civic, and government partners created to meet the Johnston County, North Carolina (JOCO) workforce needs of the future. The collaborative will culminate in a curriculum and virtual experience-based career exposition specifically for 8th grade students.",
      employerp2:"",
      employerp3:"",
      images:[],
      links:[
        
      ],
      videos:[]
    },
    ],
    manufacturing:[
      {
        employername:'Johnston Community College',
        employersite:"https://www.trenholmstate.edu/programs/technical/engineering-technologiesbr-automotive-advanced-manufacturing/",
        multilink:true,
        employerLogo:"jcc-logo.jpg",
        employerp1:"Johnston Community College has awarded associate degrees, certificates, and diplomas since 1969 to students pursuing college transfer, occupational, technical, and continuing education opportunities.",
        employerp2:"JCC in Smithfield, North Carolina, is located 30 miles east of Raleigh near the junction of I-95 and US 70. The 175-acre main campus boasts state-of-the-art facilities, picturesque pines, colorful azaleas and scenic ponds and offers some 35 occupational programs of study as well as numerous continuing education courses.",
        employerp3:"A member of the North Carolina Community College System, JCC serves more than 13,000 students annually in its credit and non-credit programs.",
        images:[],
        links:[
          {title:"Stem Camp" 
          ,url:"https://www.johnstoncc.edu/programs/arts-sciences/associate-engineering/camp/index.aspx"
          },
          {title:"Career & College Promise" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/CCP+flier_JOCOWORKS2021.pdf"
          },
          {title:"Welding" 
          ,url:"https://www.johnstoncc.edu/programs/industrial/welding/index.aspx"
          },
          {title:"HVAC" 
          ,url:"https://www.johnstoncc.edu/programs/construction/airconditioning-heating-refrigeration/index.aspx"
          },
        ],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/NKysyVN2ajk",videoId:'zTGLFpBC7sg'
        },
        {
          videoType:'youtube',link:"https://youtu.be/72RqeC4Z7e8",videoId:'txMhbstA3v4'
        },
        {
          videoType:'youtube',link:"https://youtu.be/WnGRyNVoQKU",videoId:'szC8uIDsktY'
        },
        {
          videoType:'youtube',link:"https://youtu.be/mczHOx7A8Hw",videoId:'mczHOx7A8Hw'
        },
        {
          videoType:'youtube',link:"https://youtu.be/Fi9hjcsB1tg",videoId:'Fi9hjcsB1tg'
        },
      ]
      },
      {
        employername:'JOCO Works',
        employersite:"https://www.jocoworks.net/manufacturing.html",
        multilink:false,
        employerLogo:"joco-works-logo.jpg",
        employerp1:"JOCO WORKS, presented by Novo Nordisk, is an industry-led collaborative supported by education, business, civic, and government partners created to meet the Johnston County, North Carolina (JOCO) workforce needs of the future. The collaborative will culminate in a curriculum and virtual experience-based career exposition specifically for 8th grade students.",
        employerp2:"",
        employerp3:"",
        images:[],
        links:[
      
             
        ],
        videos:[]
      },
    ],
    public_works_and_safety:[
      {
        employername:'Johnston Community College',
        employersite:"https://www.trenholmstate.edu/programs/technical/engineering-technologiesbr-automotive-advanced-manufacturing/",
        multilink:true,
        employerLogo:"jcc-logo.jpg",
        employerp1:"Johnston Community College has awarded associate degrees, certificates, and diplomas since 1969 to students pursuing college transfer, occupational, technical, and continuing education opportunities.",
        employerp2:"A member of the North Carolina Community College System, JCC serves more than 13,000 students annually in its credit and non-credit programs.",
        employerp3:"",
        images:[],
        links:[
          {title:"Learn More" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/CCP+flier_JOCOWORKS2021.pdf"
          },             
        ],
        videos:[]
      },
      {
        employername:'Johnston County Economic Development',
        employersite:"https://www.growwithjoco.com/",
        multilink:false,
        employerLogo:"johnston-eco-logo.jpg",
        employerp1:"Johnston County is a dynamic and diverse place. Depending on where you look, you’ll find small, close knit communities near fast-growing subdivisions. The only constant across this county is that change is happening. And thanks to many recent business expansions and relocations, most people will tell you that the change is more than OK.",
        employerp2:"",
        employerp3:"",
        images:[],
        links:[
          
        ],
        videos:[      ]
      },
    ],
    energy_and_technology:[
      {
        employername:'Johnston Community College',
        employersite:"https://www.trenholmstate.edu/programs/technical/engineering-technologiesbr-automotive-advanced-manufacturing/",
        multilink:true,
        employerLogo:"jcc-logo.jpg",
        employerp1:"Johnston Community College has awarded associate degrees, certificates, and diplomas since 1969 to students pursuing college transfer, occupational, technical, and continuing education opportunities.",
        employerp2:"A member of the North Carolina Community College System, JCC serves more than 13,000 students annually in its credit and non-credit programs.",
        employerp3:"",
        images:[],
        links:[
          /*{title:"Info Sessions" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/InfoSessions_JoCoWorks2021.pdf"
          },
          {title:"CCP EXPO" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/Expo_JoCoWorks2021.pdf"
          },*/
          {title:"Stem Camp" 
          ,url:"https://www.johnstoncc.edu/programs/arts-sciences/associate-engineering/camp/index.aspx"
          },
          {title:"Career & College Promise Program" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/CCP+flier_JOCOWORKS2021.pdf"
          },
              {title:"Learn More",
            url:"https://www.johnstoncc.edu/"}
             
        ],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/NKysyVN2ajk",videoId:'XKbGkMRyuX4'
        },
        {
          videoType:'youtube',link:"https://youtu.be/jdKACvDGJpM",videoId:'jdKACvDGJpM'
        },

      ]
      },
      {
        employername:'JOCO Works',
        employersite:"https://www.jocoworks.net/energy--technology.html",
        multilink:false,
        employerLogo:"joco-works-logo.jpg",
        employerp1:"JOCO WORKS, presented by Novo Nordisk, is an industry-led collaborative supported by education, business, civic, and government partners created to meet the Johnston County, North Carolina (JOCO) workforce needs of the future. The collaborative will culminate in a curriculum and virtual experience-based career exposition specifically for 8th grade students.",
        employerp2:"JOCO WORKS will present real-world simulations and information about available career options. Students will have the opportunity to explore exciting career paths. Each virtual exhibit will be presented by top employers in our region. Each career path will include sample occupations, general education requirements, and average salaries within the industry. By attending JOCO WORKS, students will learn how to begin building a meaningful career at the beginning of high school.",
        employerp3:"",
        images:[],
        links:[
        
             
        ],
        videos:[]
      },
      {
        employername:'Get Into Energy',
        employersite:"",
        multilink:true,
        employerLogo:"get-energy-logo.jpg",
        employerp1:"JOCO WORKS, presented by Novo Nordisk, is an industry-led collaborative supported by education, business, civic, and government partners created to meet the Johnston County, North Carolina (JOCO) workforce needs of the future. The collaborative will culminate in a curriculum and virtual experience-based career exposition specifically for 8th grade students.",
        employerp2:"JOCO WORKS will present real-world simulations and information about available career options. Students will have the opportunity to explore exciting career paths. Each virtual exhibit will be presented by top employers in our region. Each career path will include sample occupations, general education requirements, and average salaries within the industry. By attending JOCO WORKS, students will learn how to begin building a meaningful career at the beginning of high school.",
        employerp3:"",
        images:[],
        links:[
          {title:"Electrical Engineer Pathway" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/Energy_Career_Pathways_Engineers-Electrical.pdf"
          },
          {title:"Nuclear Energy Pathway" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/Energy_Career_Pathways_Engineers-Nuclear.pdf"
          },
          {title:"Mechanical Engineer Pathway" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/Energy_Career_Pathways_Engineers-Mechanical.pdf"
          },
          {title:"Environmental Engineer Pathway" 
          ,url:"https://nctejw.s3.us-east-2.amazonaws.com/nctejwpdfs/Energy_Career_Pathways_Engineers-Environmental.pdf"
          },
        {title:"Get Into Energy",
      url:"https://getintoenergy.com"},
      {title:"Why a stem career",
      url:"https://stem.getintoenergy.com/why-a-stem-career"} 
       ,     {title:"Stem Skills",
      url:"https://stem.getintoenergy.com/why-a-stem-skills"},
       
      
             
        ],
        videos:[]
      },
    ],
    transportation_and_logistics:[  

      {
        employername:'JOCO Works',
        employersite:"https://www.jocoworks.net/transportation--logistics.html",
        multilink:false,
        employerLogo:"joco-works-logo.jpg",
        employerp1:"JOCO WORKS, presented by Novo Nordisk, is an industry-led collaborative supported by education, business, civic, and government partners created to meet the Johnston County, North Carolina (JOCO) workforce needs of the future. The collaborative will culminate in a curriculum and virtual experience-based career exposition specifically for 8th grade students.",
        employerp2:"JOCO WORKS will present real-world simulations and information about available career options. Students will have the opportunity to explore exciting career paths. Each virtual exhibit will be presented by top employers in our region. Each career path will include sample occupations, general education requirements, and average salaries within the industry. By attending JOCO WORKS, students will learn how to begin building a meaningful career at the beginning of high school.",
        employerp3:"",
        images:[],
        links:[{title:"Donde" 
        ,url:"https://www.ncbiotech.org/transforming-life-sciences/sectors-attention/north-carolina-biopharmaceutical-manufacturing"
        },
        {title:"pdfs" 
        ,url:"https://biojobshub.wraltechwire.com/"
        },
        {title:"titles?",
      url:"https://getintoenergy.com"},
      {title:"titles?",
      url:"https://stem.getintoenergy.com/why-a-stem-career"}   ,     {title:"titles?",
      url:"https://stem.getintoenergy.com/why-a-stem-skills"},
       
      
             
        ],
        videos:[]
      },
      {
        employername:'Johnston Community College',
        employersite:"https://www.johnstoncc.edu/programs/transportation-systems/index.aspx",
        multilink:false,
        employerLogo:"jcc-logo.jpg",
        employerp1:"Johnston Community College has awarded associate degrees, certificates, and diplomas since 1969 to students pursuing college transfer, occupational, technical, and continuing education opportunities.",
        employerp2:"JCC in Smithfield, North Carolina, is located 30 miles east of Raleigh near the junction of I-95 and US 70. The 175-acre main campus boasts state-of-the-art facilities, picturesque pines, colorful azaleas and scenic ponds and offers some 35 occupational programs of study as well as numerous continuing education courses.",
        employerp3:"A member of the North Carolina Community College System, JCC serves more than 13,000 students annually in its credit and non-credit programs.",
        images:[],
        links:[],
        videos:[{
          videoType:'youtube',link:"https://youtu.be/CS0-Ctssw24",videoId:'CS0-Ctssw24'
        },]
      },
    ],
   hospitality_tourism_and_retail:[
    {
      employername:'Visit Johnston County',
      employersite:"https://www.jocoworks.net/transportation--logistics.html",
      multilink:false,
      employerLogo:"johnston-county-logo.jpg",
      employerp1:"JOCO WORKS, presented by Novo Nordisk, is an industry-led collaborative supported by education, business, civic, and government partners created to meet the Johnston County, North Carolina (JOCO) workforce needs of the future. The collaborative will culminate in a curriculum and virtual experience-based career exposition specifically for 8th grade students.",
      employerp2:"JOCO WORKS will present real-world simulations and information about available career options. Students will have the opportunity to explore exciting career paths. Each virtual exhibit will be presented by top employers in our region. Each career path will include sample occupations, general education requirements, and average salaries within the industry. By attending JOCO WORKS, students will learn how to begin building a meaningful career at the beginning of high school.",
      employerp3:"",
      images:[],
      links:[{title:"why has thou" 
      ,url:"https://www.ncbiotech.org/transforming-life-sciences/sectors-attention/north-carolina-biopharmaceutical-manufacturing"
      },
      {title:"forsaken me cass" 
      ,url:"https://biojobshub.wraltechwire.com/"
      },
      {title:"way long",
    url:"https://getintoenergy.com"},
    {title:"titles?",
    url:"https://stem.getintoenergy.com/why-a-stem-career"}   ,     {title:"titles?",
    url:"https://stem.getintoenergy.com/why-a-stem-skills"},
     
    
           
      ],
      videos:[{
        videoType:'youtube',link:"https://youtu.be/NKysyVN2ajk",videoId:'SR1tSe94_aY'
      },
      {
        videoType:'youtube',link:"https://youtu.be/72RqeC4Z7e8",videoId:'QS2AZtAiso8'
      },
      {
        videoType:'youtube',link:"https://youtu.be/NKHNeq9Sd_c",videoId:'NKHNeq9Sd_c'
      },]
    },
    {
      employername:'Johnston Community College',
      employersite:"",
      multilink:true,
      employerLogo:"jcc-logo.jpg",
      employerp1:"Johnston Community College has awarded associate degrees, certificates, and diplomas since 1969 to students pursuing college transfer, occupational, technical, and continuing education opportunities.",
      employerp2:"JCC in Smithfield, North Carolina, is located 30 miles east of Raleigh near the junction of I-95 and US 70. The 175-acre main campus boasts state-of-the-art facilities, picturesque pines, colorful azaleas and scenic ponds and offers some 35 occupational programs of study as well as numerous continuing education courses.",
      employerp3:"A member of the North Carolina Community College System, JCC serves more than 13,000 students annually in its credit and non-credit programs.",
      images:[],
      links:[{title:"Culinary" 
      ,url:"https://www.johnstoncc.edu/continuing-education/culinaryarts/index.aspx"
      },
      {title:"Chef in Training Camp" 
      ,url:"https://www.johnstoncc.edu/summercamp/camp/cheftraining.aspx"
      },],
      videos:[]
    },
    ],
    
  }
  
  